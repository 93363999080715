import { useSelector } from "react-redux";
import VehiclesFilters from "components/VehiclesFilters";
import utilConstants from "utils/constants";
import utilHelpers from "utils/helpers";

const DefaultVehiclesFilters = ({
  vehicleRepository,
  vehicleService,
  userRepository,
}) => {
  const isLoading = useSelector((state) =>
    vehicleRepository.getLoadingState(state)
  );
  const vehiclesZipCode = useSelector((state) =>
    vehicleRepository.getZipCode(state)
  );
  const userZipCode = useSelector((state) => userRepository.getZipCode(state));
  const role = useSelector((state) => userRepository.getRole(state));
  const stats = useSelector((state) => vehicleRepository.getStats(state));

  const defaultSearchText = useSelector((state) =>
    vehicleRepository.getSearchText(state)
  );
  const defaultSorting = useSelector((state) =>
    vehicleRepository.getSorting(state)
  );
  const defaultFilters = useSelector((state) =>
    vehicleRepository.getFilters(state)
  );
  const defaultZipCode = userZipCode ? userZipCode : vehiclesZipCode;

  const isExtendedFiltering = utilConstants.roles[role].isExtendedFiltering;

  const handleResetFilters = (isRegularSources, currentSourcesKeys) => {
    vehicleRepository.resetVehicles();
    vehicleRepository.updateSearchText("");
    vehicleRepository.updateZipCode("");
    vehicleRepository.unselectAllVehicles();
    vehicleRepository.updateSorting(
      utilConstants.sortingVehiclesOptions[0].value
    );
    vehicleRepository.updateFilters({
      year: {
        from: utilConstants.filtersVehiclesYearOptions[
          utilConstants.filtersVehiclesYearOptions.length - 1
        ].value,
        to: utilConstants.filtersVehiclesYearOptions[0].value,
      },
      mileage: { from: "", to: "" },
      price: { from: "", to: "" },
      isRegularSources,
      distanceRange: utilConstants.distanceFilter.defaultDistance,
      sources: currentSourcesKeys,
      transmissionType: utilConstants.vehicleTransmissionOptions[0].value,
      fuelType: utilConstants.vehicleFuelOptions[0].value,
      progressState: utilConstants.vehicleProgressStateOptions[0].value,
      colors: [],
      requiredValues: [],
      includeValues: [],
    });
    vehicleRepository.updateLastFilterApplyingTimestamp(
      utilHelpers.getDateInTimestamp()
    );
    vehicleService.getVehiclesPage().catch(() => {});
  };

  const handleApplyFilters = (searchText, sorting, zipCode, filters) => {
    vehicleRepository.resetVehicles();
    vehicleRepository.updateLastFilterApplyingTimestamp(
      utilHelpers.getDateInTimestamp()
    );
    vehicleRepository.updateLoadingState(true);
    vehicleRepository.updateZipCode(zipCode);
    vehicleRepository.unselectAllVehicles();

    vehicleRepository.updateSearchText(searchText);
    vehicleRepository.updateSorting(sorting);
    vehicleRepository.updateFilters(filters);

    return vehicleService
      .getVehiclesPage()
      .catch(() => Promise.reject())
      .finally(() => {
        vehicleRepository.updateLoadingState(false);
      });
  };

  return (
    <VehiclesFilters
      isLoading={isLoading}
      defaultZipCode={defaultZipCode}
      defaultSearchText={defaultSearchText}
      defaultSorting={defaultSorting}
      defaultFilters={defaultFilters}
      stats={stats}
      isExtendingFiltering={isExtendedFiltering}
      onResetFilters={handleResetFilters}
      onApplyFilters={handleApplyFilters}
    />
  );
};

export default DefaultVehiclesFilters;
