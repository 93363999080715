import AddToWatchListIcon from "components/icons/AddToWatchlist";
import s from "./index.module.scss";

const AddToWatchlistButton = ({
  className = "",
  onClick = {},
  backgroundColorClassName = "",
  contentColorClassName = "",
}) => {
  return (
    <div
      className={
        s["add-to-watch-list-button"] + (className ? " " + className : "")
      }
      onClick={onClick}
    >
      <AddToWatchListIcon
        backgroundClassName={backgroundColorClassName}
        contentColorClassName={contentColorClassName}
      />
    </div>
  );
};

export default AddToWatchlistButton;
