const prettyString = (string = "", spliter = "", chunkSize = 3) => {
  string = `${string}`;
  if (string.length <= chunkSize) {
    return string;
  }
  let result = "";
  for (let i = string.length; i >= chunkSize * -1 + 1; i -= chunkSize) {
    const chunk = string.substring(
      i <= 0 ? 0 : i,
      i === string.length ? i : i + chunkSize
    );
    result = `${spliter}${chunk}${result}`;
  }
  return result.substring(1, result.length - 1);
};

const prettyDate = (value = null) => {
  if (!value) {
    return "";
  }

  const utcDate = new Date(value);
  const timezoneOffset = new Date().getTimezoneOffset();
  utcDate.setMinutes(utcDate.getMinutes() - timezoneOffset);
  return utcDate.toLocaleDateString("en-CA");
};

const prettyTime = (value = null) => {
  const options = { hour: "numeric", minute: "numeric" };
  if (!value) {
    return "";
  }

  const utcDate = new Date(value);
  const timezoneOffset = new Date().getTimezoneOffset();
  utcDate.setMinutes(utcDate.getMinutes() - timezoneOffset);
  return utcDate.toLocaleTimeString("en-CA", options);
};

const prettyDateTimeJsx = (value = null) => (
  <>
    {prettyDate(value)}&nbsp;&nbsp;{prettyTime(value)}
  </>
);

const prettyZip = (value = "") => {
  return value
    .replace(/[^A-Za-z0-9]$/, "")
    .substring(0, 6)
    .toUpperCase();
};

const removeLastDirectory = (value = "") => {
  return `/${value
    .split("/")
    .filter((el) => el)
    .slice(0, -1)
    .join("/")}`;
};

const util = {
  prettyString,
  prettyDate,
  prettyTime,
  prettyDateTimeJsx,
  prettyZip,
  removeLastDirectory,
};

export default util;
