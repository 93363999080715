import { createSlice } from "@reduxjs/toolkit";
import utilConstants from "utils/constants";
import utilHelpers from "utils/helpers";
import savedSearchStateStorageGateway from "gateways/storage/savedSearchStateStorage";

const isGridView = savedSearchStateStorageGateway.getIsGridView();

const getDefaultSavedSearchId = () => "";

const getDefaultTitle = () => "";

const getDefaultLastAccessedVehicleId = () => "";

const getDefaultLastFilterApplyingTimestamp = () =>
  utilHelpers.getDateInTimestamp();

const getDefaultZipCode = () => "";

const getDefaultDistanceRange = () =>
  utilConstants.distanceFilter.defaultDistance;

const getDefaultSortingField = () =>
  utilConstants.sortingVehiclesOptions[0].value.field;

const getDefaultSortingOrder = () =>
  utilConstants.sortingVehiclesOptions[0].value.order;

const getDefaultTransmissionType = () =>
  utilConstants.vehicleTransmissionOptions[0].value;

const getDefaultFuelType = () => utilConstants.vehicleFuelOptions[0].value;

const getDefaultProgressState = () =>
  utilConstants.vehicleProgressStateOptions[0].value;

const getDefaultYearFrom = () =>
  utilConstants.filtersVehiclesYearOptions[
    utilConstants.filtersVehiclesYearOptions.length - 1
  ].value;

const getDefaultYearTo = () =>
  utilConstants.filtersVehiclesYearOptions[0].value;

const getDefaultMileageFrom = () => "";

const getDefaultMileageTo = () => "";

const getDefaultPriceFrom = () => "";

const getDefaultPriceTo = () => "";

const getDefaultIsRegularSources = () => true;

const getDefaultSources = () => Object.keys(utilConstants.sources);

const getDefaultColors = () => [];

const getDefaultRequiredValues = () => [];

const getDefaultIncludeValues = () => [];

const getDefaultSearchText = () => "";

export const savedSearchSlice = createSlice({
  name: "savedsearch",
  initialState: {
    savedSearchId: getDefaultSavedSearchId(),
    title: getDefaultTitle(),
    page: 0, // To prevent VehiclePager automatically loading first page of results
    pageSize: 60,
    pageCount: 1,
    isAutoConvosEnabled:false,
    isLoading: true,
    isGridView: isGridView !== null ? JSON.parse(isGridView) : true,
    vehicles: [],
    lastAccessedVehicleId: getDefaultLastAccessedVehicleId(),
    lastFilterApplyingTimestamp: getDefaultLastFilterApplyingTimestamp(),
    defaultSorting: {
      field: getDefaultSortingField(),
      order: getDefaultSortingOrder(),
    },
    sorting: {
      field: getDefaultSortingField(),
      order: getDefaultSortingOrder(),
    },
    defaultZipCode: getDefaultZipCode(),
    zipCode: getDefaultZipCode(),
    defaultFilters: {
      distanceRange: getDefaultDistanceRange(),
      transmissionType: getDefaultTransmissionType(),
      fuelType: getDefaultFuelType(),
      progressState: getDefaultProgressState(),
      year: {
        from: getDefaultYearFrom(),
        to: getDefaultYearTo(),
      },
      mileage: {
        from: getDefaultMileageFrom(),
        to: getDefaultMileageTo(),
      },
      price: {
        from: getDefaultPriceFrom(),
        to: getDefaultPriceTo(),
      },
      isRegularSources: getDefaultIsRegularSources(),
      sources: getDefaultSources(),
      colors: getDefaultColors(),
      requiredValues: getDefaultRequiredValues(),
      includeValues: getDefaultIncludeValues(),
    },
    filters: {
      distanceRange: getDefaultDistanceRange(),
      transmissionType: getDefaultTransmissionType(),
      fuelType: getDefaultFuelType(),
      progressState: getDefaultProgressState(),
      year: {
        from: getDefaultYearFrom(),
        to: getDefaultYearTo(),
      },
      mileage: {
        from: getDefaultMileageFrom(),
        to: getDefaultMileageTo(),
      },
      price: {
        from: getDefaultPriceFrom(),
        to: getDefaultPriceTo(),
      },
      isRegularSources: getDefaultIsRegularSources(),
      sources: getDefaultSources(),
      colors: getDefaultColors(),
      requiredValues: getDefaultRequiredValues(),
      includeValues: getDefaultIncludeValues(),
    },
    defaultSearchText: getDefaultSearchText(),
    searchText: getDefaultSearchText(),
    stats: [],
  },
  reducers: {
    setSavedSearchId: (state, action) => {
      state.savedSearchId = action.payload;
    },
    setTitle: (state, action) => {
      state.title = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setPageCount: (state, action) => {
      state.pageCount = action.payload;
    },
    setIsAutoConvosEnabled: (state, action) => {
      state.isAutoConvosEnabled = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setIsGridView: (state, action) => {
      state.isGridView = action.payload;
    },
    setVehicles: (state, action) => {
      state.vehicles = action.payload;
    },
    setVehicleFields: (state, action) => {
      state.vehicles = state.vehicles.map((vehicle) => {
        if (vehicle.id === action.payload.id) {
          return {
            ...vehicle,
            ...action.payload.updatedFields,
            work_in_progress: true,
          };
        }
        return vehicle;
      });
    },
    setVehicleWorkInProgress: (state, action) => {
      state.vehicles = state.vehicles.map((vehicle) => {
        if (vehicle.id === action.payload.id) {
          return {
            ...vehicle,
            work_in_progress: true,
          };
        }
        return vehicle;
      });
    },
    setLastAccessedVehicleId: (state, action) => {
      state.lastAccessedVehicleId = action.payload;
    },
    setLastFilterApplyingTimestamp: (state, action) => {
      state.lastFilterApplyingTimestamp = action.payload;
    },
    setDefaultSorting: (state, action) => {
      state.defaultSorting = action.payload;
    },
    setSorting: (state, action) => {
      state.sorting = action.payload;
    },
    setDefaultZipCode: (state, action) => {
      state.defaultZipCode = action.payload;
    },
    setZipCode: (state, action) => {
      state.zipCode = action.payload;
    },
    setDefaultFilters: (state, action) => {
      state.defaultFilters = action.payload;

      // Backwards compatibility for saved searches saved before Powersports
      // vehicles were added to the system
      if (action.payload.isRegularSources === undefined)
        state.defaultFilters.isRegularSources = getDefaultIsRegularSources();
    },
    setFilters: (state, action) => {
      state.filters = action.payload;

      // Backwards compatibility for saved searches saved before Powersports
      // vehicles were added to the system
      if (action.payload.isRegularSources === undefined)
        state.filters.isRegularSources = getDefaultIsRegularSources();
    },
    setDefaultSearchText: (state, action) => {
      state.defaultSearchText = action.payload;
    },
    setSearchText: (state, action) => {
      state.searchText = action.payload;
    },
    setStats: (state, action) => {
      state.stats = action.payload;
    },
    resetVehiclesAndPaginationState: (state) => {
      state.page = 1;
      state.pageSize = 60;
      state.pageCount = 1;
      state.isLoading = true;
      state.vehicles = [];
      state.lastAccessedVehicleId = getDefaultLastAccessedVehicleId();
      state.stats = [];
    },
    resetSavedSearchState: (state) => {
      state.savedSearchId = getDefaultSavedSearchId();
      state.title = getDefaultTitle();
      state.page = 0;
      state.pageSize = 60;
      state.pageCount = 1;
      state.isLoading = true;
      state.isGridView = true;
      state.vehicles = [];
      state.lastAccessedVehicleId = getDefaultLastAccessedVehicleId();
    },
  },
});

export const {
  setSavedSearchId,
  setTitle,
  setPage,
  setPageCount,
  setIsAutoConvosEnabled,
  setIsLoading,
  setIsGridView,
  setVehicles,
  setVehicleFields,
  setVehicleWorkInProgress,
  setLastAccessedVehicleId,
  setLastFilterApplyingTimestamp,
  setDefaultSorting,
  setSorting,
  setDefaultZipCode,
  setZipCode,
  setDefaultFilters,
  setFilters,
  setDefaultSearchText,
  setSearchText,
  setStats,
  resetVehiclesAndPaginationState,
  resetSavedSearchState,
} = savedSearchSlice.actions;

export default savedSearchSlice.reducer;
