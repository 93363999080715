import { useState, useEffect } from "react";
import Modal from "components/Modal";
import Input from "components/inputs/Input";
import FilledButton from "components/buttons/FilledButton";
import iconCrossWhite from "assets/icons/cross-white.svg";
import serviceWatchLists from "services/watch-lists";
import s from "./index.module.scss";

const RenameWatchListModal = ({
  isOpen = false,
  onClose = () => {},
  title = "",
  clientName = "",
  id = "",
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [localTitle, setLocalTitle] = useState(title);
  const [localClientName, setLocalClientName] = useState(clientName);

  const isAbleToRename =
    !isLoading && localTitle.length && localClientName.length;

  useEffect(() => {
    setLocalTitle(title);
    setLocalClientName(clientName);
  }, [title, clientName]);

  const handleClose = () => {
    if (isLoading) {
      return;
    }
    setLocalTitle(title);
    setLocalClientName(clientName);
    onClose();
  };

  const handleClickRename = () => {
    if (!isAbleToRename) {
      return;
    }
    setIsLoading(true);
    serviceWatchLists
      .renameWatchlists(id, localTitle, localClientName)
      .then(() => onClose(localTitle, localClientName))
      .finally(() => setIsLoading(false));
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <div className={s["rename-watch-list-modal"]}>
        <img
          className={s["rename-watch-list-modal__cross"]}
          src={iconCrossWhite}
          alt="Close"
          onClick={handleClose}
        />
        <p className={s["rename-watch-list-modal__title"]}>Rename watchlist</p>
        <Input
          className={s["rename-watch-list-modal__input"]}
          placeholder="Watchlist’s Name"
          value={localTitle}
          onChange={setLocalTitle}
        />
        <Input
          className={s["rename-watch-list-modal__input"]}
          placeholder="Customer’s Name"
          value={localClientName}
          onChange={setLocalClientName}
        />
        <FilledButton
          className={s["rename-watch-list-modal__button"]}
          disabled={!isAbleToRename}
          onClick={handleClickRename}
        >
          Rename watchlist
        </FilledButton>
      </div>
    </Modal>
  );
};

export default RenameWatchListModal;
