const getSearchAndFiltersState = () => {
  return {
    searchString: localStorage.getItem("searchString"),
    zipCode: localStorage.getItem("zipCode"),
    sorting: {
      field: localStorage.getItem("sortingField"),
      order: localStorage.getItem("sortingOrder"),
    },
    filters: {
      transmissionType: localStorage.getItem("transmissionType"),
      fuelType: localStorage.getItem("fuelType"),
      progressState: localStorage.getItem("progressState"),
      distanceRange: localStorage.getItem("distanceRange"),
      year: {
        from: localStorage.getItem("yearFrom"),
        to: localStorage.getItem("yearTo"),
      },
      mileage: {
        from: localStorage.getItem("mileageFrom"),
        to: localStorage.getItem("mileageTo"),
      },
      price: {
        from: localStorage.getItem("priceFrom"),
        to: localStorage.getItem("priceTo"),
      },
      isRegularSources: ["true", "false"].includes(
        localStorage.getItem("isRegularSources")
      )
        ? localStorage.getItem("isRegularSources") === "true"
        : undefined,
      localSources: localStorage.getItem("localSources"),
      localColors: localStorage.getItem("localColors"),
      localRequiredValues: localStorage.getItem("localRequiredValues"),
      localIncludeValues: localStorage.getItem("localIncludeValues"),
    },
  };
};

const getIsGridView = () => localStorage.getItem("isGridView");

const saveSearchAndFiltersState = (zipCode, searchText, filters, sorting) => {
  localStorage.setItem("searchString", searchText || "");
  localStorage.setItem("zipCode", zipCode || "");
  localStorage.setItem("transmissionType", filters.transmissionType);
  localStorage.setItem("fuelType", filters.fuelType);
  localStorage.setItem("progressState", filters.progressState);
  localStorage.setItem("distanceRange", filters.distanceRange);
  localStorage.setItem("yearFrom", filters.year.from);
  localStorage.setItem("yearTo", filters.year.to);
  localStorage.setItem("mileageFrom", filters.mileage.from);
  localStorage.setItem("mileageTo", filters.mileage.to);
  localStorage.setItem("priceFrom", filters.price.from);
  localStorage.setItem("priceTo", filters.price.to);
  localStorage.setItem("isRegularSources", `${filters.isRegularSources}`);
  localStorage.setItem("localSources", filters.sources);
  localStorage.setItem("localColors", filters.colors);
  localStorage.setItem("localRequiredValues", filters.requiredValues);
  localStorage.setItem("localIncludeValues", filters.includeValues);
  localStorage.setItem("sortingField", sorting.field);
  localStorage.setItem("sortingOrder", sorting.order);
};

const saveIsGridView = (value) => {
  localStorage.setItem("isGridView", value);
};

const removeSearchAndFilterState = () => {
  localStorage.removeItem("searchString");
  localStorage.removeItem("zipCode");
  localStorage.removeItem("transmissionType");
  localStorage.removeItem("fuelType");
  localStorage.removeItem("progressState");
  localStorage.removeItem("distanceRange");
  localStorage.removeItem("sortingField");
  localStorage.removeItem("sortingOrder");
  localStorage.removeItem("yearFrom");
  localStorage.removeItem("yearTo");
  localStorage.removeItem("mileageFrom");
  localStorage.removeItem("mileageTo");
  localStorage.removeItem("priceFrom");
  localStorage.removeItem("priceTo");
  localStorage.removeItem("isRegularSources");
  localStorage.removeItem("localSources");
  localStorage.removeItem("localColors");
  localStorage.removeItem("localRequiredValues");
  localStorage.removeItem("localIncludeValues");
};

const removeIsGridView = () => localStorage.removeItem("isGridView");

const vehiclesStateStorageGateway = {
  getSearchAndFiltersState,
  getIsGridView,
  removeSearchAndFilterState,
  removeIsGridView,
  saveSearchAndFiltersState,
  saveIsGridView,
};

export default vehiclesStateStorageGateway;
