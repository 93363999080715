import { useState, useEffect, useRef } from "react";
import Input from "components/inputs/Input";
import utilFormatter from "utils/formatter";
import serviceVehicles from "services/vehicles";

import s from "./index.module.scss";

const EditablePrice = ({
  vehicleId = "",
  priceType = "",
  title = "",
  price = null,
  isReadOnly = false,
  isDelisted = false,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEditingMode, setIsEditingMode] = useState(false);
  const [value, setValue] = useState(() => (price ? price.toString() : ""));
  const [localValue, setLocalValue] = useState(value);
  const inputRef = useRef(null);

  const isPriceInvalid =
    (localValue === "" && value === "") ||
    (localValue.replace(/^0+/, "").length === 0 && localValue.length > 0);

  const handleChange = (value) => {
    setLocalValue((currentStateValue) => {
      if (!currentStateValue) {
        return value.replace(/\D+/g, "").replace(/^0+/, "");
      }
      return value.replace(/\D+/g, "");
    });
    return;
  };

  const handlePriceClick = () => {
    if (isEditingMode) return;
    if (isReadOnly) return;

    setIsEditingMode(true);
  };

  const handleSavePrice = () => {
    if (!vehicleId) return;
    if (isLoading) return;

    if (isPriceInvalid) {
      setLocalValue(value);
      setIsEditingMode(false);
      return;
    }

    const localPrice = localValue ? parseInt(localValue) : null;
    const currentPrice = value ? parseInt(value) : null;

    if (localPrice === currentPrice) {
      setIsEditingMode(false);
      setLocalValue(value);
      return;
    }

    setIsLoading(true);

    serviceVehicles
      .updateVehicle(vehicleId, { [priceType]: localPrice })
      .then((vehicle) => {
        const inputValuePrice = vehicle[priceType]
          ? vehicle[priceType].toString()
          : "";
        setValue(inputValuePrice);
        setLocalValue(inputValuePrice);
        setIsEditingMode(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleClickAddButton = () => {
    setIsEditingMode(true);
  };

  useEffect(() => {
    if (!isEditingMode) return;
    inputRef.current.focus();
  }, [isEditingMode]);

  useEffect(() => {
    const inputValuePrice = price ? price.toString() : "";
    setValue(inputValuePrice);
    setLocalValue(inputValuePrice);
  }, [price]);

  if (isReadOnly) {
    return value ? (
      <>
        <span
          onClick={handlePriceClick}
          className={
            s["editable-price__text"] +
            " " +
            s["editable-price__text--value"] +
            (isDelisted ? " " + s["editable-price__text--delisted"] : "") +
            (isReadOnly ? " " + s["editable-price__text--read-only"] : "")
          }
        >
          {`$${utilFormatter.prettyString(value, ",")}`}
        </span>
        <span
          className={
            s["editable-price__text"] +
            " " +
            s["editable-price__text--title"] +
            (isDelisted ? " " + s["editable-price__text--delisted"] : "")
          }
        >{` - ${title}`}</span>
      </>
    ) : (
      <></>
    );
  }

  return (
    <>
      {value && !isEditingMode ? (
        <span
          onClick={handlePriceClick}
          className={
            s["editable-price__text"] + " " + s["editable-price__text--value"]
          }
        >
          {`$${utilFormatter.prettyString(value, ",")}`}
        </span>
      ) : isEditingMode ? (
        <Input
          ref={inputRef}
          className={s["editable-price__input"]}
          value={localValue}
          spliter={","}
          chunkSize={3}
          onChange={handleChange}
          onBlur={handleSavePrice}
        />
      ) : (
        <button
          className={s["editable-price__btn"]}
          onClick={handleClickAddButton}
        >
          Add
        </button>
      )}
      <span
        className={
          s["editable-price__text"] + " " + s["editable-price__text--title"]
        }
      >{` - ${title}`}</span>
    </>
  );
};

export default EditablePrice;
