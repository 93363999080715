import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, Route, Switch } from "react-router-dom";
import imageLogoFull from "assets/images/logo-autoagents-white.svg";
import imageLogoMobile from "assets/images/logo-white.svg";
import imagePrevWhite from "assets/icons/prev-page.svg";
import iconSignOutWhite from "assets/icons/exit-white.svg";
import iconWatchLists from "assets/icons/watch-lists.svg";
import iconAssignedCustomers from "assets/icons/assigned-customers.svg";
import iconSavedSearches from "assets/icons/saved-searches.svg";
import iconMyConvos from "assets/icons/convos.svg";
import iconMyConvosUnread from "assets/icons/convos-unread.svg";
import iconHotlist from "assets/icons/hotlist.svg";
import utilFormatter from "utils/formatter";
import serviceConversations from "services/conversations";
import s from "./index.module.scss";

const EmployeeHeader = () => {
  const hasUnreadConvos = useSelector((state) => state.conversations.hasUnread);
  const firstName = useSelector((state) => state.user.firstName);

  const location = useLocation();

  useEffect(() => {
    const fetchStats = serviceConversations.getStats;

    fetchStats();

    const interval = setInterval(fetchStats, 1000 * 60);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <header className={s["employee-header"]}>
        <div className={s["employee-header__wrapper-content"]}>
          <div className={s["employee-header__left-side"]}>
            <Link className={s["employee-header__logo-link"]} to="/cabinet">
              <img
                className={s["employee-header__logo-mobile"]}
                src={imageLogoMobile}
                alt="AutoAgents Logo"
              />
              <img
                className={s["employee-header__logo"]}
                src={imageLogoFull}
                alt="AutoAgents Logo"
              />
            </Link>
            <Switch>
              <Route exact path="/employee/vehicles">
                <></>
              </Route>
              <Route>
                <Link
                  to={utilFormatter.removeLastDirectory(location.pathname)}
                  className={s["employee-header__prev-page"]}
                >
                  <img
                    className={s["employee-header__prev-page__logo"]}
                    src={imagePrevWhite}
                    alt="Back"
                  />
                </Link>
              </Route>
            </Switch>
          </div>
          <div className={s["employee-header__right-side"]}>
            <Link to="/employee/customers/my">
              <img
                className={s["employee-header__my-assigned-customers-icon"]}
                src={iconAssignedCustomers}
                alt="My Assigned Customers"
                title="My Assigned Customers"
              />
            </Link>
            <Link to="/employee/my-saved-searches">
              <img
                className={s["employee-header__my-saved-searches-icon"]}
                src={iconSavedSearches}
                alt="My Saved Searches"
                title="My Saved Searches"
              />
            </Link>
            <Link to="/employee/my-convos">
              <img
                className={s["employee-header__my-convos-icon"]}
                src={hasUnreadConvos ? iconMyConvosUnread : iconMyConvos}
                alt="My Convos"
                title="My Convos"
              />
            </Link>
            <Link to="/employee/hotlist">
              <img
                className={s["employee-header__hotlist-icon"]}
                src={iconHotlist}
                alt="Hotlist"
                title="Hotlist"
              />
            </Link>
            <Link to="/employee/watch-lists">
              <img
                className={s["employee-header__watch-lists-icon"]}
                src={iconWatchLists}
                alt="My Watchlists"
                title="My Watchlists"
              />
            </Link>
            <p className={s["employee-header__greeting"]}>
              {firstName && `Hello, ${firstName}!`}
            </p>
            <Link className={s["employee-header__logout-link"]} to="/logout">
              <img
                className={s["employee-header__sign-out-icon"]}
                src={iconSignOutWhite}
                alt="Sign out"
                title="Sign Out"
              />
            </Link>
          </div>
        </div>
      </header>
    </>
  );
};

export default EmployeeHeader;
