import utilFormatter from "utils/formatter";
import iconAvatar from "assets/icons/avatar-placeholder.svg";
import s from "./index.module.scss";

const AddedSellerPhoneNumber = ({ event = {} }) => {
  const {
    event_at: eventAt = "",
    user: {
      avatar = {},
      first_name: firstName = "",
      last_name: lastName = "",
    } = {},
    old_value: oldValue = "",
    value = "",
  } = event;

  return (
    <div className={s["event-wrapper"]}>
      <img
        className={s["event-avatar"]}
        src={avatar?.link || iconAvatar}
        alt="Avatar"
      />
      <div className={s["event"]}>
        <p className={s["event__text"]}>
          <span
            className={s["event__name"]}
          >{`${firstName} ${lastName} `}</span>
          add Seller's phone number
          <span className={s["event__seller-phone-number"]}>{` ${value}`}</span>
        </p>
        <div className={s["event__date"]}>
          {utilFormatter.prettyDateTimeJsx(eventAt)}
        </div>
      </div>
    </div>
  );
};

export default AddedSellerPhoneNumber;
