import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import VehicleGallery from "components/VehicleGallery";
import serviceWatchLists from "services/watch-lists";
import VehiclePrice from "components/VehiclePrice";
import VehicleEmployeeDescription from "components/VehicleEmployeeDescription";
import VehicleInfo from "components/VehicleInfo/Customer";
import VehicleValuation from "components/VehicleValuation";
import Loader from "components/Loader";
import s from "./index.module.scss";

const WatchListVehicleDetails = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [vehicle, setVehicle] = useState({});

  const { watchListId, vehicleId } = useParams();

  useEffect(() => {
    serviceWatchLists
      .getPublicVehicle(watchListId, vehicleId)
      .then((data) => setVehicle(data))
      .finally(() => setIsLoading(false));
  }, [watchListId, vehicleId]);

  const { employee_description: employeeDescription = "" } = vehicle;

  return (
    <div className={s["watch-list-vehicle-details"]}>
      <div className={s["watch-list-vehicle-details__left-side"]}>
        <VehicleGallery
          photoLinks={vehicle.media}
          delisted={vehicle.delisted}
        />
        <VehiclePrice price={vehicle.price} delisted={vehicle.delisted} />
      </div>
      <div className={s["watch-list-vehicle-details__right-side"]}>
        <VehicleEmployeeDescription employeeDescription={employeeDescription} />
        <VehicleValuation
          reaction={vehicle.reaction}
          watchListId={watchListId}
          vehicleId={vehicle.id}
        />
        <VehicleInfo vehicle={vehicle} />
      </div>
      <Loader isOpen={isLoading} />
    </div>
  );
};

export default WatchListVehicleDetails;
