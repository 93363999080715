import { useCallback } from "react";
import { useSelector } from "react-redux";

const useVehicleViewToggle = (
  vehicleRepository,
  vehicleStateStorageGateway
) => {
  const isGridView = useSelector((state) =>
    vehicleRepository.getIsGridView(state)
  );

  const switchToGridView = useCallback(() => {
    vehicleRepository.updateLastAccessedVehicleId("");
    vehicleRepository.updateIsGridView(true);
    vehicleStateStorageGateway.saveIsGridView(true);
  }, [vehicleRepository, vehicleStateStorageGateway]);

  const switchToListView = useCallback(() => {
    vehicleRepository.updateLastAccessedVehicleId("");
    vehicleRepository.updateIsGridView(false);
    vehicleStateStorageGateway.saveIsGridView(false);
  }, [vehicleRepository, vehicleStateStorageGateway]);

  return { isGridView, switchToGridView, switchToListView };
};

export default useVehicleViewToggle;
