import { useState, useEffect, useMemo } from "react";
import { useParams, useLocation } from "react-router-dom";
import MainInfoCustomer from "components/VehicleInfo/MainInfo/MainInfoCustomer";
import VehicleGallery from "components/VehicleGallery";
import VehicleInfo from "components/VehicleInfo";
import Loader from "components/Loader";
import serviceVehicles from "services/vehicles";
import s from "./index.module.scss";

const VehicleDetails = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [vehicle, setVehicle] = useState({});

  const { id } = useParams();
  const { search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);

  useEffect(() => {
    setVehicle({});
    serviceVehicles
      .get(id, params.get("postal_code"))
      .then((data) => setVehicle(data))
      .finally(() => setIsLoading(false));
  }, [id, params]);

  return (
    <div className={s["vehicle-details"]}>
      <div className={s["vehicle-details__main-info"]}>
        <MainInfoCustomer vehicle={vehicle} isLoading={isLoading} />
      </div>
      <div className={s["vehicle-details__vehicle-gallery"]}>
        <VehicleGallery photoLinks={vehicle.media} />
      </div>
      <div className={s["vehicle-details__vehicle-info"]}>
        <VehicleInfo vehicle={vehicle} />
      </div>
      <Loader isOpen={isLoading} />
    </div>
  );
};

export default VehicleDetails;
