import s from "./index.module.scss";

const AddToWatchListIcon = ({
  className = "",
  backgroundClassName = "",
  contentColorClassName = "",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="46"
      height="46"
      data-name="Component 158"
      viewBox="0 0 46 46"
      className={className || s["icon"]}
    >
      <g data-name="Component 163 – 3">
        <rect
          width="46"
          height="46"
          fill={"#5c57ff"}
          className={backgroundClassName || ""}
          data-name="Rectangle 1569"
          rx="23"
        ></rect>
        <rect
          width="3"
          height="20"
          fill={"#fff"}
          className={contentColorClassName || ""}
          data-name="Rectangle 2304"
          rx="1.5"
          transform="translate(21.5 13)"
        ></rect>
        <rect
          width="3"
          height="20"
          fill={"#fff"}
          className={contentColorClassName || ""}
          data-name="Rectangle 2305"
          rx="1.5"
          transform="rotate(-90 18.75 5.75)"
        ></rect>
      </g>
    </svg>
  );
};

export default AddToWatchListIcon;
