import { useState } from "react";
import Modal from "components/Modal";
import Avatar from "components/Avatar";
import UnfilledButton from "components/buttons/UnfilledButton";
import FilledButton from "components/buttons/FilledButton";
import serviceUsers from "services/users";
import s from "./index.module.scss";

const DeleteUserModal = ({
  isOpen = false,
  role = null,
  roleType = {},
  onClose = () => {},
  profile: { first_name: firstName, last_name: lastName, avatar } = {},
  userId = "",
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const isDisabledCancelButton = isLoading;
  const isDisabledRemoveButton = isLoading;

  const handleClose = () => {
    if (isDisabledCancelButton) {
      return;
    }
    onClose();
  };

  const handleRemoveUser = async () => {
    if (isDisabledRemoveButton) {
      return;
    }

    setIsLoading(true);
    const method =
      role === roleType.salesRep
        ? serviceUsers.deleteSalesRep
        : serviceUsers.deleteCustomer;

    method(userId)
      .then(() => handleClose())
      .finally(() => setIsLoading(false));
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <div className={s["delete-user-modal"]}>
        <div className={s["delete-user-modal__title-wrapper"]}>
          <p className={s["delete-user-modal__title"]}>
            {role === roleType.salesRep
              ? "Remove Sales Rep"
              : "Remove Customer"}
          </p>
        </div>
        <div className={s["delete-user-modal__body-wrapper"]}>
          <div className={s["delete-user-modal__user-wrapper"]}>
            <p className={s["delete-user-modal__message"]}>
              Are you sure you want to remove{" "}
              <span
                className={
                  s["delete-user-modal__message"] +
                  " " +
                  s["delete-user-modal__message--bold"]
                }
              >
                {firstName} {lastName}
              </span>
              ?
            </p>
            <Avatar
              className={s["delete-user-modal__avatar"]}
              avatar={avatar}
            />
          </div>
          <div className={s["delete-user-modal__buttons-wrapper"]}>
            <UnfilledButton
              className={s["delete-user-modal__button"]}
              disabled={isDisabledCancelButton}
              onClick={() => handleClose()}
            >
              Cancel
            </UnfilledButton>
            <FilledButton
              className={
                s["delete-user-modal__button"] +
                " " +
                s["delete-user-modal__button--remove-user"]
              }
              disabled={isDisabledRemoveButton}
              onClick={handleRemoveUser}
            >
              Remove
            </FilledButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteUserModal;
