import { createRef, useEffect } from "react";

import { Link } from "react-router-dom";

import useVehicleCard from "hooks/useVehicleCard";

import iconMapPoint from "assets/icons/map-point.svg";

import utilFormatter from "utils/formatter";
import utilConstants from "utils/constants";

import s from "./index.module.scss";

const HotlistVehicleCard = ({
  vehicle: {
    to = "",
    linkParams = {},
    id = "",
    thumbnail = null,
    year = null,
    make = "",
    model = "",
    trim = "",
    mileage = null,
    dist = null,
    lowest_offered_price: lowestOfferedPrice = null,
    delisted = false,
    source_name: sourceName = "",
  } = {},
  vehicleRepository,
}) => {
  const cardRef = createRef();

  const {
    lastAccessedVehicleId,
    isThumbnailLoadingError,
    onClickCard,
    onThumbnailLoadingError,
  } = useVehicleCard(vehicleRepository);

  const handleCardClick = (e) => {
    e.preventDefault();
    onClickCard(id, to);
  };

  useEffect(() => {
    if (id === lastAccessedVehicleId) {
      const cardRootElement = cardRef.current;

      if (cardRootElement) {
        cardRootElement.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }, []);

  return (
    <Link
      ref={cardRef}
      to={to}
      className={s["vehicle-card"]}
      onClick={handleCardClick}
      {...linkParams}
    >
      {thumbnail?.link && !isThumbnailLoadingError ? (
        <img
          className={s["vehicle-card__photo"]}
          src={thumbnail.link}
          alt="Watchlists thumbnail"
          onError={onThumbnailLoadingError}
        />
      ) : (
        <div className={s["vehicle-card__placeholder"]}></div>
      )}
      <div className={s["vehicle-card__description-wrapper"]}>
        <div
          className={
            s["vehicle-card__description"] +
            " " +
            s["vehicle-card__description--left-side"]
          }
        >
          <p
            className={
              s["vehicle-card__text"] + " " + s["vehicle-card__text--left-side"]
            }
          >
            {typeof year === "number" && year}
          </p>
          <p
            className={
              s["vehicle-card__text"] +
              " " +
              s["vehicle-card__text--left-side"] +
              " " +
              s["vehicle-card__text--bold"]
            }
          >
            {[make, model, trim].join(" ")}
          </p>
          <p
            className={
              s["vehicle-card__text"] + " " + s["vehicle-card__text--left-side"]
            }
          >
            {typeof mileage === "number" &&
              utilFormatter.prettyString(parseInt(mileage), " ") + " km"}
          </p>
        </div>
        <div
          className={
            s["vehicle-card__description"] +
            " " +
            s["vehicle-card__description--right-side"]
          }
        >
          <div className={s["vehicle-card__distance"]}>
            {typeof dist === "number" && (
              <>
                <img
                  className={s["vehicle-card__icon-map-point"]}
                  src={iconMapPoint}
                  alt="Distance to vehicle"
                />
                <p
                  className={
                    s["vehicle-card__text"] +
                    " " +
                    s["vehicle-card__text--right-side"]
                  }
                >
                  {utilFormatter.prettyString(parseInt(dist), " ")} km
                </p>
              </>
            )}
          </div>
          <p
            className={
              s["vehicle-card__text"] +
              " " +
              s["vehicle-card__text--right-side"] +
              " " +
              s["vehicle-card__text--price"]
            }
          >
            {typeof lowestOfferedPrice === "number" &&
              "$" + utilFormatter.prettyString(lowestOfferedPrice, ",")}
          </p>
        </div>
      </div>
      {delisted && (
        <div className={s["vehicle-card__delisted"]}>
          <p className={s["vehicle-card__badge"]}>Delisted</p>
        </div>
      )}
      {Object.keys(utilConstants.sources).includes(sourceName) && (
        <div className={s["vehicle-card__source-wrapper"]}>
          <img
            className={s["vehicle-card__source"]}
            src={utilConstants.sources[sourceName].imgData.src}
            srcSet={utilConstants.sources[sourceName].imgData.srcSet}
            alt={utilConstants.sources[sourceName].imgData.alt}
          />
        </div>
      )}
    </Link>
  );
};

export default HotlistVehicleCard;
