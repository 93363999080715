import { forwardRef } from "react";
import PasswordInput from "components/inputs/PasswordInput";
import s from "./index.module.scss";

const LabledPassword = forwardRef(
  ({ title, value, isError, errorText, onChange }, ref) => {
    return (
      <label className={s["labled-password__field"]}>
        <p className={s["labled-password__label"]}>
          {title}
          <sup className={s["labled-password__label--required"]}>*</sup>
        </p>
        <PasswordInput
          className={`${s["labled-password__input"]}
        ${isError ? s["labled-password__input--error"] : ""}`}
          value={value}
          onChange={(value) => onChange(value)}
          isError={isError}
          ref={ref}
        />
        <span className={s["labled-password__error"]}>{errorText}</span>
      </label>
    );
  }
);

export default LabledPassword;
