import utilFormatter from "utils/formatter";
import iconAvatar from "assets/icons/avatar-placeholder.svg";
import s from "./index.module.scss";

const AddedUpdatedVehicleAdditionalPrice = ({ event = {} }) => {
  const {
    event_at: eventAt = "",
    user: {
      avatar = {},
      first_name: firstName = "",
      last_name: lastName = "",
    } = {},
    target_field: targetField = "",
    value = "",
  } = event;

  return (
    <div className={s["event-wrapper"]}>
      <img
        className={s["event-avatar"]}
        src={avatar?.link || iconAvatar}
        alt="Avatar"
      />
      <div className={s["event"]}>
        <p className={s["event__text"]}>
          <span
            className={s["event__name"]}
          >{`${firstName} ${lastName} `}</span>
          {targetField === "our_offered_price"
            ? `changed our offered price to`
            : targetField === "lowest_offered_price"
            ? `changed their lowest offered price to`
            : ""}
          <span
            className={s["event__additional-price"]}
          >{` $${utilFormatter.prettyString(value, ",")}`}</span>
        </p>
        <div className={s["event__date"]}>
          {utilFormatter.prettyDateTimeJsx(eventAt)}
        </div>
      </div>
    </div>
  );
};

export default AddedUpdatedVehicleAdditionalPrice;
