import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import useVehicleViewToggle from "hooks/useVehicleViewToggle";

import VehicleViewToggle from "components/VehiclesViewToggle";
import ReSaveSearchButton from "components/buttons/ReSaveSearchButton";

import ConfirmModal from "components/modals/ConfirmModal";
import StartSavedSearchAutoConvosModal from "components/modals/StartSavedSearchAutoConvosModal";

import { setIsLoading, setIsAutoConvosEnabled } from "slicers/savedsearch";

import service from "services/saved-searches";

import Switch from "components/Switch";

import toaster from "utils/toaster";

import s from "./index.module.scss";

const SavedSearchVehicleToolbar = ({
  vehicleRepository,
  vehicleStateStorageGateway,
  onReSaveSearch = () => {},
}) => {
  const dispatch = useDispatch();

  const { isGridView, switchToGridView, switchToListView } =
    useVehicleViewToggle(vehicleRepository, vehicleStateStorageGateway);

  const isLoading = useSelector((state) => state.savedsearch.isLoading);
  const isAutoConvosEnabled = useSelector(
    (state) => state.savedsearch.isAutoConvosEnabled
  );
  const savedSearchId = useSelector((state) => state.savedsearch.savedSearchId);
  const isRegularSources = useSelector(
    (state) => state.savedsearch?.filters?.isRegularSources
  );

  const isAutoConvosSwitchVisible = !isLoading && isRegularSources;

  const [isOpenStartAutoConvosModal, setIsOpenStartAutoConvosModal] =
    useState(false);
  const [isOpenStopAutoConvosModal, setIsOpenStopAutoConvosModal] =
    useState(false);

  const handleReSaveSearchButtonClick = () => {
    return onReSaveSearch()
      .then(() => {
        toaster.displaySuccessBottom("Search saved");
      })
      .catch(() => {});
  };

  const handleClickStartAutoConvos = () => setIsOpenStartAutoConvosModal(true);

  const handleCloseStartAutoConvosModal = () =>
    setIsOpenStartAutoConvosModal(false);

  const handleClickStopAutoConvos = () => setIsOpenStopAutoConvosModal(true);

  const handleCloseStopAutoConvosModal = () =>
    setIsOpenStopAutoConvosModal(false);

  const handleAutoConvosSwitchClick = () => {
    if (isLoading) return;

    if (isAutoConvosEnabled) {
      handleClickStopAutoConvos();
    } else {
      handleClickStartAutoConvos();
    }
  };

  const handleConfirmStartAutoConvos = (isForceStart) => {
    dispatch(setIsLoading(true));
    return service
      .startAutoConvos(savedSearchId, isForceStart)
      .then(({ is_autoconvo }) => {
        dispatch(setIsAutoConvosEnabled(is_autoconvo));
      })
      .catch(() =>
        toaster.displayErrorBottom(
          "Failed to start auto convo, please try again."
        )
      )
      .finally(() => dispatch(setIsLoading(false)));
  };

  const handleConfirmStopAutoConvos = () => {
    dispatch(setIsLoading(true));
    return service
      .stopAutoConvos(savedSearchId)
      .then(({ is_autoconvo }) => {
        dispatch(setIsAutoConvosEnabled(is_autoconvo));
      })
      .catch(() =>
        toaster.displayErrorBottom(
          "Failed to stop auto convo, please try again."
        )
      )
      .finally(() => dispatch(setIsLoading(false)));
  };

  return (
    <div className={s["vehicle-controllers-wrapper"]}>
      {isAutoConvosSwitchVisible && (
        <div
          className={
            s["vehicle__switch-wrapper"] +
            " " +
            s["vehicle__switch-wrapper--autoconvo"]
          }
        >
          <span
            className={s["vehicle__switch-option"]}
            onClick={handleAutoConvosSwitchClick}
          >
            Auto Convos
          </span>
          <Switch
            isChecked={isAutoConvosEnabled}
            onChange={handleAutoConvosSwitchClick}
            backgroundColor={isAutoConvosEnabled ? "#5c57ff" : "#9a9a9a"}
          />
        </div>
      )}
      <div className={s["vehicle-controllers-control"]}>
        <ReSaveSearchButton onClick={handleReSaveSearchButtonClick} />
      </div>
      <div
        className={
          s["vehicle-controllers-control"] +
          " " +
          s["vehicle-controllers-control--view-toggle"]
        }
      >
        <VehicleViewToggle
          isGridView={isGridView}
          handleClickGridIcon={switchToGridView}
          handleClickListIcon={switchToListView}
        />
      </div>
      <StartSavedSearchAutoConvosModal
        isOpen={isOpenStartAutoConvosModal}
        onClose={handleCloseStartAutoConvosModal}
        onConfirm={handleConfirmStartAutoConvos}
      />
      <ConfirmModal
        text={
          <>
            Are you sure you want to{" "}
            <strong>
              {" "}
              turn <br />
              auto convos off{" "}
            </strong>{" "}
            for all new <br />
            vehicles in this saved search? <br />
            This action will not stop any <br />
            ongoing auto convos.
          </>
        }
        isOpen={isOpenStopAutoConvosModal}
        onClose={handleCloseStopAutoConvosModal}
        onConfirm={handleConfirmStopAutoConvos}
      />
    </div>
  );
};

export default SavedSearchVehicleToolbar;
