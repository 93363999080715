import { useState } from "react";
import { useHistory } from "react-router-dom";
import Input from "components/inputs/Input";
import PasswordInput from "components/inputs/PasswordInput";
import FilledButton from "components/buttons/FilledButton";
import UnfilledButton from "components/buttons/UnfilledButton";
import Loader from "components/Loader";
import imageLogoBlackAutoagents from "assets/images/logo-autoagents-black.svg";
import serviceAuth from "services/auth";
import utilConstants from "utils/constants";
import s from "./index.module.scss";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  const isDisabledButton =
    email.trim().length === 0 || password.length === 0 || isLoading;

  const handleSignUp = () => {
    history.push("/sign-up");
  };

  const handleClickLogin = async () => {
    if (isDisabledButton) {
      return;
    }
    setIsError(false);
    setIsLoading(true);
    serviceAuth
      .login(email.trim(), password)
      .then(() => {
        history.push("/cabinet");
      })
      .catch(() => {
        setIsError(true);
        setIsLoading(false);
      });
  };

  const handleInputEnter = (e) => {
    if (e.key !== "Enter") {
      return;
    }
    handleClickLogin();
  };

  return (
    <div className={s["sign-in"]}>
      <img
        src={imageLogoBlackAutoagents}
        className={s["sign-in__logo"]}
        alt="Logo"
      />
      <h1 className={s["sign-in__title"]}>Sign in</h1>
      {isError && (
        <p className={s["sign-in__error"]}>
          Wrong email or password, please try again.
        </p>
      )}
      <div className={s["sign-in__inputs-wrapper"]}>
        <Input
          className={s["sign-in__input"]}
          value={email}
          placeholder="Email"
          isError={isError}
          onChange={(value) => setEmail(value)}
          onKeyDown={handleInputEnter}
        />
        <PasswordInput
          className={s["sign-in__input"]}
          value={password}
          placeholder="Password"
          isError={isError}
          onChange={(value) => setPassword(value)}
          onKeyDown={handleInputEnter}
        />
      </div>
      <div className={s["sign-in__forgot-password-wrapper"]}>
        Forgot password? Please&nbsp;
        <a
          href={
            "mailto:" +
            utilConstants.forgotPasswordEmailFields.address +
            "?subject=" +
            utilConstants.forgotPasswordEmailFields.subject
          }
        >
          contact us
        </a>
      </div>
      <FilledButton
        className={s["sign-in__button"]}
        disabled={isDisabledButton}
        onClick={handleClickLogin}
      >
        sign in
      </FilledButton>
      <UnfilledButton
        className={s["sign-in__sign-up-button"]}
        onClick={handleSignUp}
      >
        sign up
      </UnfilledButton>
      <Loader isOpen={isLoading} />
    </div>
  );
};

export default SignIn;
