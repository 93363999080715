import { useState, useEffect } from "react";
import Modal from "components/Modal";
import FilledButton from "components/buttons/FilledButton";
import UnfilledButton from "components/buttons/UnfilledButton";
import iconCrossBlack from "assets/icons/cross-black.svg";
import s from "./index.module.scss";

const AddDescriptionNoteModal = ({
  isOpen = false,
  defaultDescription = "",
  defaultNote = "",
  onDone = () => {},
  onSkip = () => {},
  onClose = () => {},
}) => {
  const [description, setDescription] = useState(defaultDescription);
  const [note, setNote] = useState(defaultNote);

  useEffect(() => {
    setDescription(defaultDescription);
  }, [defaultDescription]);

  useEffect(() => {
    setNote(defaultNote);
  }, [defaultNote]);

  const isDisabledAdd = !(description.length || note.length);

  const handleClose = (isMissClick = false) => {
    if (!isMissClick) {
      onClose();
      return;
    }
    onClose(description, note);
  };

  const handleChangeDescription = (e) => setDescription(e.target.value);

  const handleChangeNote = (e) => setNote(e.target.value);

  const handleClickAdd = () => {
    if (isDisabledAdd) {
      return;
    }
    onDone(description, note);
  };

  const handleClickSkip = () => {
    setDescription("");
    setNote("");
    onSkip();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <div className={s["add-description-note-modal"]}>
        <img
          className={s["add-description-note-modal__cross"]}
          src={iconCrossBlack}
          alt="Close"
          onClick={() => handleClose(false)}
        />
        <div
          className={
            s["add-description-note-modal__content-wrapper"] +
            " " +
            s["add-description-note-modal__content-wrapper--description"]
          }
        >
          <p
            className={
              s["add-description-note-modal__title"] +
              " " +
              s["add-description-note-modal__title--description"]
            }
          >
            Description for the client{" "}
            <span className={s["add-description-note-modal__title--normal"]}>
              (optional)
            </span>
          </p>
          <textarea
            className={
              s["add-description-note-modal__textarea"] +
              " " +
              s["add-description-note-modal__textarea--description"]
            }
            placeholder="Write the description here"
            value={description}
            onChange={handleChangeDescription}
          />
        </div>
        <div
          className={
            s["add-description-note-modal__content-wrapper"] +
            " " +
            s["add-description-note-modal__content-wrapper--note"]
          }
        >
          <p
            className={
              s["add-description-note-modal__title"] +
              " " +
              s["add-description-note-modal__title--note"]
            }
          >
            Your own notes{" "}
            <span className={s["add-description-note-modal__title--normal"]}>
              (optional)
            </span>
          </p>
          <textarea
            className={
              s["add-description-note-modal__textarea"] +
              " " +
              s["add-description-note-modal__textarea--note"]
            }
            placeholder="Write your notes here"
            value={note}
            onChange={handleChangeNote}
          />
        </div>
        <div className={s["add-description-note-modal__buttons-wrapper"]}>
          <FilledButton
            className={
              s["add-description-note-modal__button"] +
              " " +
              s["add-description-note-modal__button--add"]
            }
            disabled={isDisabledAdd}
            onClick={handleClickAdd}
          >
            Add to watchlist
          </FilledButton>
          <UnfilledButton
            className={s["add-description-note-modal__button"]}
            onClick={handleClickSkip}
          >
            Skip this step
          </UnfilledButton>
        </div>
      </div>
    </Modal>
  );
};

export default AddDescriptionNoteModal;
