import { useSelector } from "react-redux";
import Preview from "./Preview";
import Editor from "./Editor";

const components = {
  preview: Preview,
  editor: Editor,
};

const Comment = ({ event = {}, vehicleId = "", onRemove = () => {} }) => {
  const { user: { id: authorId = "" } = "" } = event;

  const myId = useSelector((state) => state.user.id);

  const componentKey = authorId === myId ? "editor" : "preview";
  const Component = components[componentKey];

  return <Component event={event} vehicleId={vehicleId} onRemove={onRemove} />;
};

export default Comment;
