import serviceApi from "services/api";
import serviceUsers from "services/users";

import store from "store";

import { setTokens, clearTokens } from "slicers/auth";
import { resetUserState } from "slicers/user";

import vehicleRepository from "repositories/vehicleRepository";
import hotlistVehicleRepository from "repositories/hotlistVehicleRepository";
import savedSearchRepository from "repositories/savedSearchRepository";

import authStateStorageGateway from "gateways/storage/authStateStorage";
import vehiclesStateStorageGateway from "gateways/storage/vehiclesStateStorage";
import hotlistStateStorageGateway from "gateways/storage/hotlistStateStorage";
import savedSearchStateStorageGateway from "gateways/storage/savedSearchStateStorage";

const isAuthed = () => {
  const refreshToken = store.getState().auth.refresh;
  if (!refreshToken) {
    return false;
  }
  return new Date() < getExpirationDate(refreshToken);
};

const loadTokens = () => {
  const access = authStateStorageGateway.getAccessToken() || "";
  const refresh = authStateStorageGateway.getRefreshToken() || "";
  store.dispatch(setTokens({ access, refresh }));
};

const saveTokens = ({ access, refresh }) => {
  authStateStorageGateway.saveAccessToken(access);
  authStateStorageGateway.saveRefreshToken(refresh);
  store.dispatch(setTokens({ access, refresh }));
};

const removeTokens = () => {
  authStateStorageGateway.removeAccessToken();
  authStateStorageGateway.removeRefreshToken();
  store.dispatch(clearTokens());
};

const removeSearchAndFilterState = () => {
  vehiclesStateStorageGateway.removeSearchAndFilterState();
};

const removeIsGridView = () => {
  return vehiclesStateStorageGateway.removeIsGridView();
};

const removeIsHotlistGridView = () => {
  return hotlistStateStorageGateway.removeIsGridView();
};

const removeIsSavedSearchGridView = () => {
  return savedSearchStateStorageGateway.removeIsGridView();
};

const getValueFromTokenPayload = (token, key) =>
  JSON.parse(atob(token.split(".")[1]))[key];

const getExpirationDate = (token) =>
  new Date(getValueFromTokenPayload(token, "exp") * 1000);

const getUserRole = () => {
  return getValueFromTokenPayload(store.getState().auth.refresh, "role");
};

const getUserId = () => {
  return getValueFromTokenPayload(store.getState().auth.access, "user_id");
};

const baseURL = "auth";

const login = async (email, password) =>
  serviceApi
    .post(baseURL + "/login", { email, password })
    .then(saveTokens)
    .then(serviceUsers.getMe);

const logout = async () =>
  serviceApi.post(baseURL + "/logout").then(() => {
    removeTokens();
    removeSearchAndFilterState();
    removeIsGridView();
    removeIsHotlistGridView();
    removeIsSavedSearchGridView();
    store.dispatch(resetUserState());
    vehicleRepository.resetState();
    hotlistVehicleRepository.resetState();
    savedSearchRepository.resetState();
  });

const refresh = () =>
  serviceApi
    .post(baseURL + "/refresh", {
      refresh_token: store.getState().auth.refresh,
    })
    .then(saveTokens)
    .then(serviceUsers.getMe);

const service = {
  isAuthed,
  loadTokens,
  saveTokens,
  removeTokens,
  getValueFromTokenPayload,
  getExpirationDate,
  getUserRole,
  getUserId,
  login,
  logout,
  refresh,
};

export default service;
