import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import WatchListsDetailsTitle from "components/WatchListsDetailsTitle";
import MainInfo from "components/VehicleInfo/MainInfo";
import VehicleGallery from "components/VehicleGallery";
import VehicleInfo from "components/VehicleInfo";
import EmployeeDescription from "components/EmployeeDescription";
import Note from "components/Note";
import Loader from "components/Loader";
import DeleteVehicleFromWatchListModal from "components/modals/DeleteVehicleFromWatchListModal";
import serviceWatchLists from "services/watch-lists";
import s from "./index.module.scss";

const WatchListVehicleDetails = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [
    isOpenDeleteVehicleFromWatchListModal,
    setisOpenDeleteVehicleFromWatchListModal,
  ] = useState(false);
  const [vehicle, setVehicle] = useState({});
  const [addons, setAddons] = useState({});

  const { watchListId, vehicleId } = useParams();

  const history = useHistory();

  useEffect(() => {
    const fetch = async () => {
      try {
        await serviceWatchLists
          .getVehicle(watchListId, vehicleId)
          .then(setVehicle);
      } catch (e) {}
      try {
        await serviceWatchLists
          .getAddons(watchListId, vehicleId)
          .then(setAddons);
      } catch (e) {}
      setIsLoading(false);
    };
    fetch();
  }, [watchListId, vehicleId]);

  const { employee_description: employeeDescription = "", note = "" } = addons;

  const handleCloseDeleteVehicleFromWatchListModal = (isDeleted = false) => {
    setisOpenDeleteVehicleFromWatchListModal(false);
    if (isDeleted === true) {
      history.push(`/employee/watch-lists/${watchListId}`);
    }
  };

  return (
    <div className={s["watch-list-vehicle-details__wrapper"]}>
      <WatchListsDetailsTitle />
      <div className={s["watch-list-vehicle-details"]}>
        <div className={s["watch-list-vehicle-details__main-info"]}>
          <MainInfo
            vehicle={vehicle}
            watchListId={watchListId}
            addonPrice={addons.price}
            isVehicleLoading={isLoading}
            onAddToWatchList={() => {}}
            onRemoveFromWatchList={() =>
              setisOpenDeleteVehicleFromWatchListModal(true)
            }
          />
        </div>
        <div className={s["watch-list-vehicle-details__vehicle-gallery"]}>
          <VehicleGallery
            photoLinks={vehicle.media}
            reaction={vehicle.reaction}
          />
        </div>
        <div className={s["watch-list-vehicle-details__description-and-note"]}>
          <EmployeeDescription
            defaultValue={employeeDescription}
            watchListId={watchListId}
            vehicleId={vehicleId}
          />
          <Note
            defaultValue={note}
            watchListId={watchListId}
            vehicleId={vehicleId}
          />
        </div>
        <div className={s["watch-list-vehicle-details__vehicle-info"]}>
          <VehicleInfo
            vehicle={vehicle}
            watchListId={watchListId}
            onVehicleEdited={(value) => setVehicle(value)}
          />
        </div>
        <Loader isOpen={isLoading} />
        <DeleteVehicleFromWatchListModal
          isOpen={isOpenDeleteVehicleFromWatchListModal}
          onClose={handleCloseDeleteVehicleFromWatchListModal}
          text={`${vehicle.build?.make ? vehicle.build.make : ""} ${
            vehicle.build?.model ? ` ${vehicle.build.model}` : ""
          } ${vehicle.build?.trim ? ` ${vehicle.build.trim}` : ""}`}
          watchListId={watchListId}
          vehicleId={vehicleId}
        />
      </div>
    </div>
  );
};

export default WatchListVehicleDetails;
