import { Switch, Route, Redirect } from "react-router-dom";
import * as uuid from "uuid";
import EmployeeHeader from "components/headers/EmployeeHeader";
import Vehicles from "pages/employee/vehicles";
import VehicleDetails from "pages/employee/vehicles/details";
import MySavedSearches from "./my-saved-searches";
import WatchLists from "pages/employee/watch-lists";
import WatchListsDetails from "pages/employee/watch-lists/details";
import WatchListsVehicles from "pages/employee/watch-lists/details/vehicles";
import WatchListVehicleDetails from "pages/employee/watch-lists/details/vehicles/details";
import SavedSearchDetails from "./my-saved-searches/details";
import MyCustomerWatchList from "pages/employee/customers/my/watch-list";
import MyConvos from "pages/employee/my-convos";
import Hotlist from "pages/employee/hotlist";
import AllConvos from "pages/employee/vehicles/details/all-convos";
import Customers from "pages/employee/customers";
import s from "./index.module.scss";

const Employee = () => (
  <div className={s["employee"]}>
    <EmployeeHeader />

    <div className={s["employee__page-wrapper"]}>
      <Switch>
        <Route
          path="/employee"
          render={() => <Redirect to="/employee/vehicles" />}
          exact
        />
        <Route path="/employee/vehicles" render={() => <Vehicles />} exact />
        <Route
          path="/employee/vehicles/:id"
          render={() => <VehicleDetails />}
          exact
        />
        <Route
          path="/employee/watch-lists"
          render={() => <WatchLists />}
          exact
        />
        <Route
          path="/employee/watch-lists/:watchListId"
          render={() => <WatchListsDetails />}
          exact
        />
        <Route
          path="/employee/watch-lists/:watchListId/vehicles"
          render={() => <WatchListsVehicles />}
          exact
        />
        <Route
          path="/employee/watch-lists/:watchListId/vehicles/:vehicleId"
          render={() => <WatchListVehicleDetails />}
          exact
        />
        <Route path="/employee/hotlist" render={() => <Hotlist />} exact />
        <Route
          path="/employee/hotlist/:id"
          render={() => <VehicleDetails />}
          exact
        />
        <Route
          path="/employee/my-saved-searches"
          render={() => <MySavedSearches />}
          exact
        />
        <Route
          path="/employee/my-saved-searches/:savedSearchId"
          render={() => <SavedSearchDetails />}
          exact
        />
        <Route
          path="/employee/my-saved-searches/:savedSearchId/:id"
          render={() => <VehicleDetails />}
          exact
        />
        <Route
          path="/employee/customers/my/:customerId"
          render={() => <MyCustomerWatchList baseUrl="/employee/vehicles/" />}
        />
        <Route
          path="/employee/my-convos"
          render={() => <MyConvos key={uuid.v4()} />}
          exact
        />
        <Route
          path="/employee/vehicles/:id/all-convos"
          render={() => <AllConvos />}
          exact
        />
        <Route path="/employee/customers" render={() => <Customers />} />
      </Switch>
    </div>
  </div>
);

export default Employee;
