import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import iconAvatar from "assets/icons/avatar-placeholder.svg";
import utilFormatter from "utils/formatter";
import s from "./index.module.scss";

const FinishedAutoConvo = ({ event = {}, vehicleId = "" }) => {
  const {
    event_at: eventAt = "",
    user: {
      avatar = {},
      first_name: firstName = "",
      last_name: lastName = "",
      id: userId = "",
    } = {},
  } = event;

  const myId = useSelector((state) => state.user.id);

  return (
    <div className={s["event-wrapper"]}>
      <img
        className={s["event-avatar"]}
        src={avatar?.link || iconAvatar}
        alt="Avatar"
      />
      <div className={s["event"]}>
        <p className={s["event__text"]}>
          <span className={s["event__name"]}>
            {`${firstName} ${lastName}'s `}
          </span>
          <span className={s["event__convo"]}>
            {userId !== myId ? (
              <Link
                to={`/employee/vehicles/${vehicleId}/all-convos?user_id=${userId}`}
                className={s["event__link"]}
              >
                auto convo
              </Link>
            ) : (
              <>auto&nbsp;convo</>
            )}
          </span>{" "}
          finished successfully
        </p>
        <div className={s["event__date"]}>
          {utilFormatter.prettyDateTimeJsx(eventAt)}
        </div>
      </div>
    </div>
  );
};

export default FinishedAutoConvo;
