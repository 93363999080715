import serviceApi from "services/api";

const baseURL = "autoconvos";

const getStatus = (vehicleId) =>
  serviceApi.get(`${baseURL}/status`, { vehicle_id: vehicleId });

const start = (vehicleId) =>
  serviceApi.post(`${baseURL}/start`, { vehicle_id: vehicleId });

const bulkStart = (vehicleIds) =>
  serviceApi.post(`${baseURL}/bulk_start`, { vehicles_id: vehicleIds });

const bulkStop = (vehicleIds) =>
  serviceApi.post(`${baseURL}/bulk_stop`, { vehicles_id: vehicleIds });

const stop = (vehicleId) =>
  serviceApi.post(`${baseURL}/stop`, { vehicle_id: vehicleId });

const service = {
  getStatus,
  start,
  stop,
  bulkStart,
  bulkStop,
};

export default service;
