import { useEffect } from "react";
import GridViewIcon from "components/icons/GridViewIcon";
import ListViewIcon from "components/icons/ListViewIcon";
import s from "./index.module.scss";

const MIN_WIDTH_FOR_DISPLAY_TOGGLE = 1200;

const VehicleViewToggle = ({
  isGridView = false,
  handleClickGridIcon = () => {},
  handleClickListIcon = () => {},
}) => {
  useEffect(() => {
    const handleResize = () => {
      if (document.body.clientWidth < MIN_WIDTH_FOR_DISPLAY_TOGGLE) {
        handleClickGridIcon();
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  return (
    <div className={s["vehicle-view-toggle"]}>
      <div className={s["vehicle-view-controls-wrapper"]}>
        <button
          className={
            s["vehicle-view-toggle__btn"] +
            " " +
            s["vehicle-view-toggle__btn--car"]
          }
          onClick={handleClickGridIcon}
        >
          <GridViewIcon
            className={s["vehicle-view-toggle__grid-icon"]}
            isDisabled={isGridView}
          />
        </button>
        <button
          className={
            s["vehicle-view-toggle__btn"] +
            " " +
            s["vehicle-view-toggle__btn--list"]
          }
          onClick={handleClickListIcon}
        >
          <ListViewIcon
            className={s["vehicle-view-toggle__grid-icon"]}
            isDisabled={!isGridView}
          />
        </button>
      </div>
    </div>
  );
};

export default VehicleViewToggle;
