import { useEffect, useState } from "react";
import iconWhiteTick from "assets/icons/tick-white.svg";
import iconBlackTick from "assets/icons/tick-black.svg";
import s from "./index.module.scss";

const ColoredCheckbox = ({
  onChange = () => {},
  isChecked = false,
  title = "",
  tickColor,
  ...style
}) => {
  const [isCheckMarkVisible, setIsCheckMarkVisible] = useState(false);

  useEffect(() => {
    setIsCheckMarkVisible(isChecked);
  }, [isChecked]);

  const handleCheckMark = () => {
    setIsCheckMarkVisible(!isCheckMarkVisible);
    onChange(!isCheckMarkVisible);
  };

  return (
    <div
      className={s["colored-check-box"]}
      {...style}
      onClick={handleCheckMark}
      title={title}
    >
      <img
        className={
          s["colored-check-box__tick"] +
          " " +
          (!isCheckMarkVisible
            ? ` ${s["colored-check-box__tick--hidden"]}`
            : "")
        }
        src={tickColor === "black" ? iconBlackTick : iconWhiteTick}
        alt="Check mark"
      />
    </div>
  );
};

export default ColoredCheckbox;
