import { useState } from "react";
import Avatar from "components/Avatar";
import CreateEditUserModal from "components/modals/CreateEditUserModal";
import DeleteUserModal from "components/modals/DeleteUserModal";
import UserDetailsModal from "components/modals/UserDetailsModal";
import iconPen from "assets/icons/pen.svg";
import iconCross from "assets/icons/cross.svg";
import s from "./index.module.scss";

const UsersTable = ({ users = [], roleType = {}, role = "" }) => {
  const [isOpenUpdateModal, setIsOpenUpdateModal] = useState(false);
  const [userToUpdate, setUserToUpdate] = useState({});
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState({});
  const [isOpenDetailsModal, setIsOpenDetailsModal] = useState(false);
  const [userDetails, setUserDetails] = useState({});

  const handleClickUpdateUser = (user) => {
    setUserToUpdate(user);
    setIsOpenUpdateModal(true);
  };

  const handleClickDeleteUser = (user) => {
    setUserToDelete(user);
    setIsOpenDeleteModal(true);
  };

  const handleClickUserDetails = (e, user) => {
    if (e.target.classList.contains(s["users-table__actions-icon"])) {
      return;
    }
    setUserDetails(user);
    setIsOpenDetailsModal(true);
  };

  return (
    <div className={s["users-table"]}>
      <div
        className={s["users-table__row"] + " " + s["users-table__row--first"]}
      >
        <p className={s["users-table__title"]}>User</p>
        <p className={s["users-table__title"]}>Email</p>
        <p className={s["users-table__title"]}>Actions</p>
      </div>
      {users.map((user) => (
        <div
          className={s["users-table__row"]}
          key={user.id}
          onClick={(e) => handleClickUserDetails(e, user)}
        >
          <Avatar
            className={s["users-table__avatar"]}
            avatar={user.profile.avatar}
          />

          <p
            className={
              s["users-table__text"] + " " + s["users-table__text--first"]
            }
          >
            {user.profile.first_name} {user.profile.last_name}
          </p>
          <p className={s["users-table__text"]}>{user.email}</p>
          <div className={s["users-table__actions-wrapper"]}>
            <button
              className={s["users-table__actions-icon"]}
              alt="Edit"
              onClick={() => handleClickUpdateUser(user)}
            >
              <img src={iconPen} alt="Edit" />
            </button>
            <button
              className={s["users-table__actions-icon"]}
              alt="Delete"
              onClick={() => handleClickDeleteUser(user)}
            >
              <img src={iconCross} alt="Delete" />
            </button>
          </div>
        </div>
      ))}

      <CreateEditUserModal
        isOpen={isOpenUpdateModal}
        onClose={() => setIsOpenUpdateModal(false)}
        isEditing
        defaultValues={{
          defaultFirstName: userToUpdate.profile?.first_name,
          defaultLastName: userToUpdate.profile?.last_name,
          defaultEmail: userToUpdate.email,
          defaultTwilioPhoneNumber: userToUpdate.profile?.twilio_phone_number
            ? userToUpdate.profile.twilio_phone_number
            : "",
          defaultAvatar: userToUpdate.profile?.avatar,
        }}
        userId={userToUpdate.id}
      />
      <DeleteUserModal
        isOpen={isOpenDeleteModal}
        role={role}
        roleType={roleType}
        onClose={() => setIsOpenDeleteModal(false)}
        profile={userToDelete.profile}
        userId={userToDelete.id}
      />
      <UserDetailsModal
        isOpen={isOpenDetailsModal}
        onClose={() => setIsOpenDetailsModal(false)}
        user={userDetails}
      />
    </div>
  );
};

export default UsersTable;
