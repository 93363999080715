import serviceApi from "services/api";
import store from "store";
import { setHasUnread } from "slicers/conversations";

const baseURL = "conversations";

const getConversationsFilterParams = (filters) => {
  const filterParams = {};
  if (filters.isUnread) {
    filterParams.unread_only = filters.isUnread;
  } else {
    filterParams.time_period = filters.timePeriod;
    filterParams.is_responded = filters.isResponded;
  }

  return filterParams;
};

const get = (filters) => {
  const filterParams = getConversationsFilterParams(filters);
  return serviceApi.get(baseURL, filterParams);
};

const getStats = () =>
  serviceApi.get(`${baseURL}/stats`).then((stats) => {
    store.dispatch(setHasUnread(stats.has_unread));
  });

const getCsv = (selectedConversationVehicleIds, csvType) => {
  return serviceApi
    .postFile(`${baseURL}/csv`, {
      vehicles_ids: selectedConversationVehicleIds,
      csv_type: csvType,
    })
    .then((response) => {
      const href = URL.createObjectURL(response);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", "vehicles.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
};

const service = {
  get,
  getStats,
  getCsv,
};

export default service;
