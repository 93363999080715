import hotlistGateway from "gateways/api/hotlistGateway";

import hotlistVehicleRepository from "repositories/hotlistVehicleRepository";

const getVehiclesPage = () => {
  const pageNumber = hotlistVehicleRepository.getPageNumber();
  const pageSize = hotlistVehicleRepository.getPageSize();

  hotlistVehicleRepository.updateLoadingState(true);

  return hotlistGateway
    .getVehiclesPage(pageNumber, pageSize)
    .then(({ items, page_count: pageCount }) => {
      hotlistVehicleRepository.appendVehicles(items);
      hotlistVehicleRepository.updatePageCount(pageCount);
    })
    .catch((e) => {
      hotlistVehicleRepository.updateVehicles([]);
      return Promise.reject(e);
    })
    .finally(() => hotlistVehicleRepository.updateLoadingState(false));
};

const service = {
  getVehiclesPage,
};

export default service;
