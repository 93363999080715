import VehicleReactions from "components/VehicleReactions";
import s from "./index.module.scss";

const VehicleValuation = ({
  reaction = null,
  watchListId = "",
  vehicleId = "",
}) => (
  <div className={s["vehicle-valuation-wrapper"]}>
    <p className={s["vehicle-valuation-wrapper__text"]}>
      Do you want us to explore this vehicle further?
    </p>
    <div className={s["vehicle-valuation-wrapper__btns"]}>
      <VehicleReactions
        reaction={reaction}
        watchListId={watchListId}
        vehicleId={vehicleId}
      />
    </div>
  </div>
);

export default VehicleValuation;
