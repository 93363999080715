import store from "store";
import {
  setSavedSearchId,
  setTitle,
  setIsGridView,
  setLastAccessedVehicleId,
  resetVehiclesAndPaginationState,
  setPage,
  setDefaultSorting,
  setSorting,
  setDefaultZipCode,
  setZipCode,
  setDefaultFilters,
  setFilters,
  setDefaultSearchText,
  setSearchText,
  setPageCount,
  setIsAutoConvosEnabled,
  setIsLoading,
  setVehicles,
  setVehicleFields,
  setVehicleWorkInProgress,
  setStats,
  resetSavedSearchState,
} from "slicers/savedsearch";

const getSavedSearchId = (state) => {
  if (state) {
    return state.savedsearch.savedSearchId;
  }

  const { getState } = store;
  return getState().savedsearch.savedSearchId;
};

const getTitle = (state) => {
  if (state) {
    return state.savedsearch.title;
  }

  const { getState } = store;
  return getState().savedsearch.title;
};

const getIsGridView = (state) => {
  if (state) {
    return state.savedsearch.isGridView;
  }

  const { getState } = store;
  return getState().savedsearch.isGridView;
};

const getLastAccessedVehicleId = (state) => {
  if (state) {
    return state.savedsearch.lastAccessedVehicleId;
  }

  const { getState } = store;
  return getState().savedsearch.lastAccessedVehicleId;
};

const getVehicles = (state) => {
  if (state) {
    return state.savedsearch.vehicles;
  }

  const { getState } = store;
  return getState().savedsearch.vehicles;
};

const getPageNumber = (state) => {
  if (state) {
    return state.savedsearch.page;
  }

  const { getState } = store;
  return getState().savedsearch.page;
};

const getPageCount = (state) => {
  if (state) {
    return state.savedsearch.pageCount;
  }

  const { getState } = store;
  return getState().savedsearch.pageCount;
};

const getPageSize = (state) => {
  if (state) {
    return state.savedsearch.pageSize;
  }

  const { getState } = store;
  return getState().savedsearch.pageSize;
};

const getIsAutoConvosEnabled = (state) => {
  if (state) {
    return state.savedsearch.isAutoConvosEnabled;
  }

  const { getState } = store;
  return getState().savedsearch.isAutoConvosEnabled;
}

const getLoadingState = (state) => {
  if (state) {
    return state.savedsearch.isLoading;
  }

  const { getState } = store;
  return getState().savedsearch.isLoading;
};

const getDefaultZipCode = (state) => {
  if (state) {
    return state.savedsearch.defaultZipCode;
  }

  const { getState } = store;
  return getState().savedsearch.defaultZipCode;
};

const getZipCode = (state) => {
  if (state) {
    return state.savedsearch.zipCode;
  }

  const { getState } = store;
  return getState().savedsearch.zipCode;
};

const getDefaultSorting = (state) => {
  if (state) {
    return state.savedsearch.defaultSorting;
  }

  const { getState } = store;
  return getState().savedsearch.defaultSorting;
};

const getSorting = (state) => {
  if (state) {
    return state.savedsearch.sorting;
  }

  const { getState } = store;
  return getState().savedsearch.sorting;
};

const getDefaultFilters = (state) => {
  if (state) {
    return state.savedsearch.defaultFilters;
  }

  const { getState } = store;
  return getState().savedsearch.defaultFilters;
};

const getFilters = (state) => {
  if (state) {
    return state.savedsearch.filters;
  }

  const { getState } = store;
  return getState().savedsearch.filters;
};

const getDefaultSearchText = (state) => {
  if (state) {
    return state.savedsearch.defaultSearchText;
  }

  const { getState } = store;
  return getState().savedsearch.defaultSearchText;
};

const getSearchText = (state) => {
  if (state) {
    return state.savedsearch.searchText;
  }

  const { getState } = store;
  return getState().savedsearch.searchText;
};

const getSearchedAtTimestamp = (state) => {
  if (state) {
    return state.savedsearch.lastFilterApplyingTimestamp;
  }

  const { getState } = store;
  return getState().savedsearch.lastFilterApplyingTimestamp;
};

const getStats = (state) => {
  const { getState } = store;

  const currentState = state ? state : getState();

  return currentState.savedsearch.stats;
};

const updateSavedSearchId = (savedSearchId) =>
  store.dispatch(setSavedSearchId(savedSearchId));

const updateTitle = (title) => store.dispatch(setTitle(title));

const updateIsGridView = (isGridView) =>
  store.dispatch(setIsGridView(isGridView));

const updateLastAccessedVehicleId = (lastAccessedVehicleId) =>
  store.dispatch(setLastAccessedVehicleId(lastAccessedVehicleId));

const updatePageNumber = (pageNumber) => store.dispatch(setPage(pageNumber));

const updatePageCount = (pageCount) => store.dispatch(setPageCount(pageCount));

const updateIsAutoConvosEnabled = (isAutoConvosEnabled) => store.dispatch(setIsAutoConvosEnabled(isAutoConvosEnabled));

const updateStats = (stats) => store.dispatch(setStats(stats));

const updateDefaultSorting = (sorting) =>
  store.dispatch(setDefaultSorting(sorting));

const updateSorting = (sorting) => store.dispatch(setSorting(sorting));

const updateDefaultZipCode = (zipCode) =>
  store.dispatch(setDefaultZipCode(zipCode));

const updateZipCode = (zipCode) => store.dispatch(setZipCode(zipCode));

const updateDefaultFilters = (filters) =>
  store.dispatch(setDefaultFilters(filters));

const updateFilters = (filters) => store.dispatch(setFilters(filters));

const updateDefaultSearchText = (searchText) =>
  store.dispatch(setDefaultSearchText(searchText));

const updateSearchText = (searchText) =>
  store.dispatch(setSearchText(searchText));

const updateLoadingState = (isLoading) =>
  store.dispatch(setIsLoading(isLoading));

const updateVehicles = (vehicles) => store.dispatch(setVehicles(vehicles));

const updateVehicleFields = (id, updatedFields) =>
  store.dispatch(setVehicleFields({ id, updatedFields }));

const updateVehicleWorkInProgress = (id) =>
  store.dispatch(setVehicleWorkInProgress({ id }));

const appendVehicles = (vehicles) =>
  store.dispatch(setVehicles([...getVehicles(), ...vehicles]));

const resetVehicles = () => store.dispatch(resetVehiclesAndPaginationState());

const resetState = () => store.dispatch(resetSavedSearchState());

const savedSearchRepository = {
  getSavedSearchId,
  getTitle,
  getIsGridView,
  getLastAccessedVehicleId,
  getVehicles,
  getPageNumber,
  getPageCount,
  getPageSize,
  getIsAutoConvosEnabled,
  getLoadingState,
  getDefaultZipCode,
  getZipCode,
  getDefaultSorting,
  getSorting,
  getDefaultFilters,
  getFilters,
  getDefaultSearchText,
  getSearchText,
  getSearchedAtTimestamp,
  getStats,
  updateSavedSearchId,
  updateTitle,
  updateIsGridView,
  updateLastAccessedVehicleId,
  updatePageNumber,
  updatePageCount,
  updateIsAutoConvosEnabled,
  updateDefaultSorting,
  updateSorting,
  updateDefaultZipCode,
  updateZipCode,
  updateDefaultFilters,
  updateFilters,
  updateDefaultSearchText,
  updateSearchText,
  updateLoadingState,
  updateVehicles,
  updateVehicleFields,
  updateVehicleWorkInProgress,
  updateStats,
  appendVehicles,
  resetVehicles,
  resetState,
};

export default savedSearchRepository;
