import { forwardRef } from "react";
import Input from "../Input";
import s from "./index.module.scss";

const LabledInput = forwardRef(
  (
    {
      title,
      type,
      required = false,
      spliter = "",
      chunkSize = null,
      value,
      isError,
      errorText,
      onChange,
    },
    ref
  ) => {
    return (
      <label className={s["labled-input__field"]}>
        <p className={s["labled-input__label"]}>
          {title}
          {required ? (
            <sup className={s["labled-input__label--required"]}>*</sup>
          ) : (
            ""
          )}
        </p>
        <Input
          className={`${s["labled-input__input"]}
        ${isError ? s["labled-input__input--error"] : ""}`}
          value={value}
          spliter={spliter}
          chunkSize={chunkSize}
          type={type}
          onChange={(e) => onChange(e)}
          ref={ref}
        />
        <span className={s["labled-input__error"]}>{errorText}</span>
      </label>
    );
  }
);

export default LabledInput;
