import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import iconSave from "assets/icons/icon-save.svg";
import iconSaveDisabled from "assets/icons/icon-save-disabled.svg";
import iconCross from "assets/icons/cross-black-bold.svg";
import serviceWatchLists from "services/watch-lists";
import utilFormatter from "utils/formatter";
import s from "./index.module.scss";

const CustomPrice = ({
  defaultValue: startValue = "",
  watchListId = "",
  vehicleId = "",
  delisted = false,
}) => {
  const [defaultValue, setDefaultValue] = useState(startValue || "");
  const [value, setValue] = useState(startValue || "");
  const [isLoading, setIsLoading] = useState(false);

  const isAbleToSave = !isLoading && defaultValue !== value;

  useEffect(() => {
    setDefaultValue(startValue || "");
    setValue(startValue || "");
  }, [startValue]);

  const handleChange = (e) => setValue(e.target.value.replace(/\D/g, ""));

  const handleKeyDown = (e) => {
    if (e.key !== "Enter") {
      return;
    }
    handleClickSave();
  };

  const handleClickSave = () => {
    if (!isAbleToSave) {
      return;
    }
    setIsLoading(true);
    if (value) {
      serviceWatchLists
        .patchAddons(watchListId, vehicleId, { price: value })
        .then(() => {
          setDefaultValue(value);
          toast.success("Price for customer saved", {
            position: "bottom-right",
            autoClose: 2000,
            closeOnClick: true,
            pauseOnHover: true,
          });
        })
        .finally(() => setIsLoading(false));
    } else {
      serviceWatchLists
        .removeAddons(watchListId, vehicleId, ["price"])
        .then(() => {
          setDefaultValue(value);
          toast.success("Price for customer removed", {
            position: "bottom-right",
            autoClose: 2000,
            closeOnClick: true,
            pauseOnHover: true,
          });
        })
        .finally(() => setIsLoading(false));
    }
  };

  const handleClickClose = () => setValue(defaultValue);

  return (
    <div className={s["custom-price"]}>
      <textarea
        className={
          s["custom-price__textarea"] +
          (delisted ? ` ${s["custom-price__textarea--delisted"]}` : "")
        }
        placeholder="Price for customer"
        value={value ? `$${utilFormatter.prettyString(value, ",")}` : ""}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        disabled={delisted}
      ></textarea>
      <div className={s["custom-price__icons"]}>
        <img
          className={
            s["custom-price__icon"] +
            (isAbleToSave ? "" : ` ${s["custom-price__icon--disabled"]}`)
          }
          src={isAbleToSave ? iconSave : iconSaveDisabled}
          alt="Save"
          onClick={handleClickSave}
        />
        {isAbleToSave && (
          <img
            className={
              s["custom-price__icon"] + " " + s["custom-price__icon--cancel"]
            }
            src={iconCross}
            alt="Сancel"
            onClick={handleClickClose}
          />
        )}
      </div>
    </div>
  );
};

export default CustomPrice;
