import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import CustomersTable from "components/Tables/CustomersTable";
import Pagination from "components/Pagination";
import serviceUsers from "services/users";
import { setPageCustomer, setPageSize, resetState } from "slicers/customers";
import s from "./index.module.scss";

const Customers = ({ setRole, roleType = {} }) => {
  const customers = useSelector((state) => state.customers.customers);
  const pageCustomer = useSelector((state) => state.customers.pageCustomer);
  const pageSize = useSelector((state) => state.customers.pageSize);
  const isLoading = useSelector((state) => state.customers.isLoading);
  const pageCountCustomer = useSelector(
    (state) => state.customers.pageCountCustomer
  );

  const dispatch = useDispatch();

  const handleChangePage = (pageCustomer) =>
    dispatch(setPageCustomer(pageCustomer));

  const handleChangePageSize = (pageSize) => {
    dispatch(setPageSize(pageSize));
    dispatch(setPageCustomer(1));
  };

  const [employeeInfo, setEmployeeInfo] = useState([]);

  useEffect(() => {
    serviceUsers.getCustomersPage();
    setRole(roleType.customer);
  }, [pageCustomer, pageSize, setRole, roleType.customer]);

  useEffect(() => () => dispatch(resetState()), [dispatch]);

  useEffect(() => {
    serviceUsers.getSearch("", "employee", 1, 100).then((data) => {
      const newSales = data.items.map((employee) => {
        return {
          value: employee.id,
          label: employee.profile.first_name + " " + employee.profile.last_name,
        };
      });

      const emptySalesRep = {
        value: null,
        label: "No sales rep",
      };

      newSales.unshift(emptySalesRep);

      setEmployeeInfo(newSales);
    });
  }, []);

  return (
    <div className={s["customers"]}>
      <CustomersTable
        customers={customers}
        salesReps={employeeInfo}
        role={roleType.customer}
      />
      <Pagination
        page={pageCustomer}
        pageSize={pageSize}
        pageCount={pageCountCustomer}
        isLoading={isLoading}
        onChangePage={handleChangePage}
        onChangePageSize={handleChangePageSize}
      />
    </div>
  );
};

export default Customers;
