import serviceApi from "services/api";
import serviceAuth from "services/auth";

import watchlistsGateway from "gateways/api/watchlistsGateway";

import authRepository from "repositories/authRepository";
import vehicleRepository from "repositories/vehicleRepository";
import savedSearchRepository from "repositories/savedSearchRepository";

const baseURL = "watchlists";
const publicURL = "public-watchlists";

const create = (title, client_name) =>
  serviceApi.post(baseURL, { title, client_name });

const addVehicleToWatchlist = (watchListId, vehicleId, description, note) => {
  let addons = {};
  if (description || note) {
    addons = { addons: {} };
  }
  if (description) {
    addons.addons.employee_description = description;
  }
  if (note) {
    addons.addons.note = note;
  }

  if (vehicleId) {
    const vehiclesIds = [vehicleId];
    return watchlistsGateway
      .addVehicleToWatchlist(watchListId, vehiclesIds, addons)
      .then(() => {
        vehicleRepository.updateVehicleWorkInProgress(vehicleId);
        savedSearchRepository.updateVehicleWorkInProgress(vehicleId);
      })
      .catch(() => {});
  }

  const vehicleIds = vehicleRepository.getSelectedVehicleIds();
  return watchlistsGateway
    .addVehicleToWatchlist(watchListId, vehicleIds, addons)
    .then(() => {
      vehicleRepository.updateVehicleWorkInProgress(vehicleId);
      vehicleRepository.unselectAllVehicles();
      savedSearchRepository.updateVehicleWorkInProgress(vehicleId);
    })
    .catch(() => {});
};

const searchMe = (search_text) =>
  serviceApi.get(`${baseURL}/${serviceAuth.getUserId()}/search`, {
    search_text,
  });

const getMyWatchlists = () => {
  const userId = authRepository.getUserId();

  // Have to pass page size and page number to get all watchlists
  const pageNumber = 1;
  const pageSize = 1000;

  return watchlistsGateway.getWatchlists(userId, pageNumber, pageSize);
};

const vehicles = (watchListId) =>
  serviceApi.get(`${baseURL}/${watchListId}/vehicles`);

const getPublicVehicles = (watchListId) =>
  serviceApi.get(`${publicURL}/${watchListId}/vehicles`);

const get = (watchListId) => serviceApi.get(`${baseURL}/${watchListId}`);

const getPublic = (watchListId) =>
  serviceApi.get(`${publicURL}/${watchListId}`);

const _delete = (watchListId) => serviceApi.delete(`${baseURL}/${watchListId}`);

const deleteVehicle = (watchListId, vehicleId) =>
  serviceApi.delete(`${baseURL}/${watchListId}/vehicles/${vehicleId}`);

const getVehicle = (watchListId, vehicleId) =>
  serviceApi.get(`${baseURL}/${watchListId}/vehicles/${vehicleId}`);

const renameWatchlists = (watchListId, title, clientName) =>
  serviceApi.put(`${baseURL}/${watchListId}`, {
    title,
    client_name: clientName,
  });

const getPublicVehicle = (watchListId, vehicleId) =>
  serviceApi.get(`${publicURL}/${watchListId}/vehicles/${vehicleId}`);

const getAddons = (watchListId, vehicleId) =>
  serviceApi.get(`${baseURL}/${watchListId}/vehicles/${vehicleId}/addons`);

const patchAddons = (watchListId, vehicleId, data) =>
  serviceApi.patch(
    `${baseURL}/${watchListId}/vehicles/${vehicleId}/addons`,
    data
  );

const removeAddons = (watchListId, vehicleId, keys) =>
  serviceApi.delete(`${baseURL}/${watchListId}/vehicles/${vehicleId}/addons`, {
    delete_properties: keys,
  });

const patchVehicle = (watchListId, vehicleId, data) =>
  serviceApi.patch(`${baseURL}/${watchListId}/vehicles/${vehicleId}`, data);

const dislikeVehicle = (watchListId, vehicleId) =>
  serviceApi.post(`${publicURL}/${watchListId}/vehicles/${vehicleId}/dislike`);

const likeVehicle = (watchListId, vehicleId) =>
  serviceApi.post(`${publicURL}/${watchListId}/vehicles/${vehicleId}/like`);

const service = {
  create,
  addVehicleToWatchlist,
  searchMe,
  getMyWatchlists,
  vehicles,
  getPublicVehicles,
  get,
  getPublic,
  delete: _delete,
  deleteVehicle,
  getVehicle,
  renameWatchlists,
  getPublicVehicle,
  getAddons,
  patchAddons,
  removeAddons,
  patchVehicle,
  dislikeVehicle,
  likeVehicle,
};

export default service;
