import FinishedAutoConvo from "../FinishedAutoConvo";
import StoppedAutoConvoByUser from "../StoppedAutoConvoByUser";

const StoppedAutoConvo = ({ event = {}, vehicleId = "" }) => {
  if (event.stopped_by === "user") {
    return <StoppedAutoConvoByUser event={event} vehicleId={vehicleId} />;
  }

  if (
    ["finished", "otherwise_finished", "wrecked_finished"].includes(
      event.stopped_by
    )
  ) {
    return <FinishedAutoConvo event={event} vehicleId={vehicleId} />;
  }

  return <></>;
};

export default StoppedAutoConvo;
