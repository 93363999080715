import { useState, useEffect } from "react";
import Modal from "components/Modal";
import AddToWatchList from "components/AddToWatchList";
import CreateAndAddToWatchList from "components/CreateAndAddToWatchList";
import iconCrossBlack from "assets/icons/cross-black.svg";
import serviceWatchLists from "services/watch-lists";
import s from "./index.module.scss";

const AddToWatchListModal = ({
  isOpen = false,
  vehicleId,
  description = "",
  note = "",
  onClose = () => {},
  onDone = () => {},
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [watchListsOptions, setWatchListsOptions] = useState([]);
  const [watchListValue, setWatchListValue] = useState(null);
  const [title, setTitle] = useState("");
  const [name, setName] = useState("");

  useEffect(() => {
    serviceWatchLists.searchMe("").then((watchLists) =>
      setWatchListsOptions(
        watchLists.map((watchList) => ({
          value: watchList.id,
          label: `${watchList.title} (${watchList.client_name})`,
        }))
      )
    );
  }, []);

  const isDisabledAddToWatchList = isLoading || !watchListValue?.value;
  const isDisabledCreateAndAddToWatchList =
    isLoading || !title.trim() || !name.trim();

  const reset = () => {
    setWatchListValue(null);
    setTitle("");
    setName("");
  };

  const handleClose = (isMissClick = false) => {
    if (!isMissClick) {
      reset();
    }
    onClose();
  };

  const handleAddToWatchList = () => {
    if (isDisabledAddToWatchList) {
      return;
    }
    setIsLoading(true);
    serviceWatchLists
      .addVehicleToWatchlist(watchListValue.value, vehicleId, description, note)
      .then(() => {
        onDone();
        handleClose(false);
      })
      .finally(() => setIsLoading(false));
  };

  const handleCreateAndAddToWatchList = async () => {
    if (isDisabledCreateAndAddToWatchList) {
      return;
    }
    setIsLoading(true);
    const { id } = await serviceWatchLists
      .create(title.trim(), name.trim())
      .then((watchList) => {
        setWatchListsOptions((watchListsOptions) => [
          ...watchListsOptions,
          {
            value: watchList.id,
            label: `${watchList.title} (${watchList.client_name})`,
          },
        ]);
        return watchList;
      });
    if (!id) {
      setIsLoading(false);
      return;
    }
    serviceWatchLists
      .addVehicleToWatchlist(id, vehicleId, description, note)
      .then(() => {
        onDone();
        handleClose(false);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <div className={s["add-to-watch-list-modal"]}>
        <img
          className={s["add-to-watch-list-modal__cross"]}
          src={iconCrossBlack}
          alt="Close"
          onClick={() => handleClose(false)}
        />
        <AddToWatchList
          options={watchListsOptions}
          value={watchListValue}
          isDisabled={isDisabledAddToWatchList}
          onChange={(value) => setWatchListValue(value)}
          onDone={handleAddToWatchList}
        />
        <CreateAndAddToWatchList
          title={title}
          name={name}
          isDisabled={isDisabledCreateAndAddToWatchList}
          onChangeTitle={(value) => setTitle(value)}
          onChangeName={(value) => setName(value)}
          onDone={handleCreateAndAddToWatchList}
        />
      </div>
    </Modal>
  );
};

export default AddToWatchListModal;
