import { useEffect, useState } from "react";
import DisplayConvosSelect from "components/inputs/selects/DisplayConvosSelect";
import ConvosVehiclesExportCsvButton from "components/buttons/ConvosVehiclesExportCsvButton";
import ConvosCheckbox from "components/inputs/ConvosCheckbox";
import utilsConstants from "utils/constants";
import s from "./index.module.scss";

const ConvosToolbar = ({
  filters = {},
  onChangeFilters = () => {},
  onGetCsv = () => {},
}) => {
  const [selectedConvosSelectValue, setSelectedConvosSelectValue] = useState(
    filters.isUnread ? "unread" : filters.timePeriod
  );
  const [isResponded, setIsResponded] = useState(filters.isResponded);

  const handleChangeConvosSelectOption = ({ value }) => {
    if (value === "unread") {
      setSelectedConvosSelectValue(value);
      onChangeFilters({
        timePeriod: null,
        isUnread: true,
      });
      return;
    }

    setSelectedConvosSelectValue(value);
    onChangeFilters({
      timePeriod: value,
      isUnread: false,
    });
    return;
  };

  const handleChangeIsRespondedCheckbox = (value) => {
    setIsResponded(value);
    onChangeFilters({
      isResponded: value,
    });
  };

  useEffect(() => {
    setSelectedConvosSelectValue(
      filters.isUnread ? "unread" : filters.timePeriod
    );
  }, [filters]);

  return (
    <div className={s["convos-toolbar-wrapper"]}>
      <div
        className={
          s["convos-toolbar-control"] +
          " " +
          s["convos-toolbar-control--display-convos"]
        }
      >
        <DisplayConvosSelect
          options={utilsConstants.displayConvosSelectOptions}
          selectedValue={selectedConvosSelectValue}
          onChange={handleChangeConvosSelectOption}
        />
      </div>
      <div
        className={
          s["convos-toolbar-control"] +
          " " +
          s["convos-toolbar-control--answered-only"]
        }
      >
        <ConvosCheckbox
          title="Answered Convos only"
          checkmarkClassName={s["convos-checkbox-checkmark"]}
          titleClassName={s["convos-checkbox-title"]}
          labelClassName={s["convos-checkbox-label"]}
          wrapperClassName={s["convos-checkbox-wrapper"]}
          onChange={handleChangeIsRespondedCheckbox}
          isChecked={isResponded}
        />
      </div>
      <div
        className={
          s["convos-toolbar-control"] +
          " " +
          s["convos-toolbar-control--export-csv"]
        }
      >
        <ConvosVehiclesExportCsvButton onGetCsv={onGetCsv}>
          <span className={s["export-csv__btn__text"]}>export</span>{" "}
          <span className={s["export-csv__btn__text"]}>convo vehicles</span>
        </ConvosVehiclesExportCsvButton>
      </div>
    </div>
  );
};

export default ConvosToolbar;
