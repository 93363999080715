import utilFormatter from "utils/formatter";
import AttachedMessageImages from "components/AttachedMessageImages";
import s from "./index.module.scss";

const Message = ({
  message = {},
  setConvoImages = () => {},
  setIsOpenConvoImagesModal = () => {},
}) => {
  const {
    createdAt = "",
    sender: { type = "" },
    media = [],
    text = "",
  } = message;

  const isUser = type === "user";

  const handleClickImage = () => {
    setConvoImages(media);
    setIsOpenConvoImagesModal(true);
  };

  return (
    <div className={s["message"] + (isUser ? ` ${s["message--user"]}` : "")}>
      {text ? (
        <div
          className={
            s["message__text-wrapper"] +
            (isUser ? ` ${s["message__text-wrapper--user"]}` : "")
          }
        >
          <div className={s["message__text-content"]}>
            <p className={s["message__text"]}>{text}</p>
            <p className={s["message__date"]}>
              {utilFormatter.prettyDateTimeJsx(createdAt)}
            </p>
          </div>
        </div>
      ) : (
        <></>
      )}
      {media.length > 0 ? (
        <>
          <AttachedMessageImages
            media={media}
            handleClickImage={handleClickImage}
          />
          {text ? (
            <></>
          ) : (
            <p className={s["message__date"] + " " + s["message__date--media"]}>
              {utilFormatter.prettyDateTimeJsx(createdAt)}
            </p>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Message;
