import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import iconLogoWhite from "assets/images/logo-white.svg";
import serviceWatchLists from "services/watch-lists";
import s from "./index.module.scss";

const WatchListDetails = () => {
  const [title, setTitle] = useState("");
  const [clientName, setClientName] = useState("");

  const { watchListId } = useParams();

  useEffect(
    () =>
      serviceWatchLists.getPublic(watchListId).then((watchList) => {
        setTitle(watchList.title);
        setClientName(watchList.client_name);
      }),
    [watchListId]
  );

  return (
    <div className={s["watch-list-details"]}>
      <img
        className={s["watch-list-details__logo"]}
        src={iconLogoWhite}
        alt="logo"
      />
      {clientName && title && (
        <div className={s["watch-list-details__info-wrapper"]}>
          <p className={s["watch-list-details__text"]}>
            Watchlist
            <span
              className={
                s["watch-list-details__text"] +
                " " +
                s["watch-list-details__text--bold"]
              }
            >
              &nbsp;{title}
            </span>
          </p>
          <p className={s["watch-list-details__text"]}>
            &nbsp;for
            <span
              className={
                s["watch-list-details__text"] +
                " " +
                s["watch-list-details__text--bold"]
              }
            >
              &nbsp;{clientName}
            </span>
          </p>
        </div>
      )}
    </div>
  );
};

export default WatchListDetails;
