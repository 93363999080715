import AdditionalAttributes from "components/AdditionalAttributes/Customer";
import s from "./index.module.scss";

const CustomerVehicleInfo = ({ vehicle }) => {
  const {
    build: {
      body_type: bodyType = "",
      drivetrain = "",
      transmission = "",
      engine = "",
      fuel_type: fuelType = "",
      doors = "",
      std_seating: stdSeating = "",
    } = {},
    exterior_color: exteriorColor = "",
    interior_color: interiorColor = "",
    vin = "",
    inventory_type: inventoryType = "",
    dealer_description: dealerDescription = "",
  } = vehicle;

  return (
    <div className={s["customer-vehicle-info"]}>
      <div className={s["customer-vehicle-info__overview"]}>
        {bodyType && (
          <div className={s["customer-vehicle-info__row"]}>
            <p className={s["customer-vehicle-info__title"]}>Body type</p>
            <p className={s["customer-vehicle-info__text"]}>{bodyType}</p>
          </div>
        )}
        {transmission && (
          <div className={s["customer-vehicle-info__row"]}>
            <p className={s["customer-vehicle-info__title"]}>Transmission</p>
            <p className={s["customer-vehicle-info__text"]}>{transmission}</p>
          </div>
        )}
        {engine && (
          <div className={s["customer-vehicle-info__row"]}>
            <p className={s["customer-vehicle-info__title"]}>Engine</p>
            <p className={s["customer-vehicle-info__text"]}>{engine}</p>
          </div>
        )}
        {fuelType && (
          <div className={s["customer-vehicle-info__row"]}>
            <p className={s["customer-vehicle-info__title"]}>Fuel type</p>
            <p className={s["customer-vehicle-info__text"]}>{fuelType}</p>
          </div>
        )}
        {drivetrain && (
          <div className={s["customer-vehicle-info__row"]}>
            <p className={s["customer-vehicle-info__title"]}>Drivetrain</p>
            <p className={s["customer-vehicle-info__text"]}>{drivetrain}</p>
          </div>
        )}
        {vin && (
          <div className={s["customer-vehicle-info__row"]}>
            <p className={s["customer-vehicle-info__title"]}>Vin</p>
            <p className={s["customer-vehicle-info__text"]}>{vin}</p>
          </div>
        )}
        {exteriorColor && (
          <div className={s["customer-vehicle-info__row"]}>
            <p className={s["customer-vehicle-info__title"]}>Exterior color</p>
            <p className={s["customer-vehicle-info__text"]}>{exteriorColor}</p>
          </div>
        )}
        {interiorColor && (
          <div className={s["customer-vehicle-info__row"]}>
            <p className={s["customer-vehicle-info__title"]}>Interior color</p>
            <p className={s["customer-vehicle-info__text"]}>{interiorColor}</p>
          </div>
        )}
        {doors && (
          <div className={s["customer-vehicle-info__row"]}>
            <p className={s["customer-vehicle-info__title"]}>Doors</p>
            <p className={s["customer-vehicle-info__text"]}>{doors}</p>
          </div>
        )}
        {stdSeating && (
          <div className={s["customer-vehicle-info__row"]}>
            <p className={s["customer-vehicle-info__title"]}>Seats</p>
            <p className={s["customer-vehicle-info__text"]}>{stdSeating}</p>
          </div>
        )}
        {inventoryType && (
          <div className={s["customer-vehicle-info__row"]}>
            <p className={s["customer-vehicle-info__title"]}>Status</p>
            <p className={s["customer-vehicle-info__text"]}>{inventoryType}</p>
          </div>
        )}
        {dealerDescription && (
          <div className={s["customer-vehicle-info__row"]}>
            <p className={s["customer-vehicle-info__title"]}>Description</p>
            <p className={s["customer-vehicle-info__text"]}>
              {dealerDescription}
            </p>
          </div>
        )}
        <AdditionalAttributes attributes={vehicle.additional_attributes} />
      </div>
    </div>
  );
};

export default CustomerVehicleInfo;
