import utilFormatter from "utils/formatter";
import s from "./index.module.scss";
import AttachedMessageImages from "components/AttachedMessageImages";

const ConversationModalMessage = ({
  message = {},
  setConvoImages = () => {},
  setIsOpenConvoImagesModal = () => {},
}) => {
  const {
    createdAt = "",
    sender: { name = "", imgData = {}, type = "" },
    media = [],
    text = "",
  } = message;

  const handleClickImage = () => {
    setConvoImages(media);
    setIsOpenConvoImagesModal(true);
  };

  return (
    <div className={s["message-wrapper"]}>
      <img
        alt="avatar"
        className={
          s["message-avatar"] +
          (type === "user" ? ` ${s["message-avatar--rounded"]}` : "")
        }
        {...imgData}
      />
      <div className={s["message"]}>
        <div className={s["message__header"]}>
          <p className={s["message__name"]}>{name}</p>
          <p className={s["message__date"]}>
            {utilFormatter.prettyDateTimeJsx(createdAt)}
          </p>
        </div>
        {text ? <div className={s["message__text"]}>{text}</div> : <></>}
        {media.length > 0 ? (
          <AttachedMessageImages
            media={media}
            handleClickImage={handleClickImage}
            className={s["message__attached-images"]}
            imagesListWrapperClassName={s["message__attached-images-wrapper"]}
            imageWrapperClassName={s["message__attached-image-wrapper"]}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default ConversationModalMessage;
