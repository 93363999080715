import { useState } from "react";
import Modal from "components/Modal";
import FilledButton from "components/buttons/FilledButton";
import iconCrossBlack from "assets/icons/cross-black.svg";
import serviceWatchLists from "services/watch-lists";
import s from "./index.module.scss";

const DeleteVehicleFromWatchListModal = ({
  isOpen = false,
  onClose = () => {},
  text = "",
  watchListId = "",
  vehicleId = "",
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    if (isLoading) {
      return;
    }
    onClose();
  };

  const handleClickRemove = () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    serviceWatchLists
      .deleteVehicle(watchListId, vehicleId)
      .then(() => onClose(true))
      .catch((error) => {
        if (error.response?.status !== 404) {
          return;
        }
        onClose(true);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <div className={s["delete-vehicle-from-watch-list-modal"]}>
        <img
          className={s["delete-vehicle-from-watch-list-modal__cross"]}
          src={iconCrossBlack}
          alt="Close"
          onClick={handleClose}
        />
        <p className={s["delete-vehicle-from-watch-list-modal__text"]}>
          Are you sure you want to remove
          <br />
          <span
            className={
              s["delete-vehicle-from-watch-list-modal__text"] +
              " " +
              s["delete-vehicle-from-watch-list-modal__text--bold"]
            }
          >
            {text}
          </span>
          <br />
          from watchlist?
        </p>
        <FilledButton
          className={s["delete-vehicle-from-watch-list-modal__button"]}
          disabled={isLoading}
          onClick={handleClickRemove}
        >
          Remove
        </FilledButton>
      </div>
    </Modal>
  );
};

export default DeleteVehicleFromWatchListModal;
