import { useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import AdminHeader from "components/headers/AdminHeader";
import Users from "pages/admin/users";
import ControlButtons from "components/buttons/ControlButtons";
import Customers from "pages/admin/customers";
import s from "./index.module.scss";

const Admin = () => {
  const [role, setRole] = useState("");

  const roleType = {
    customer: "customer",
    salesRep: "salesRep",
  };

  return (
    <div className={s["admin"]}>
      <AdminHeader role={role} roleType={roleType} />
      <ControlButtons setRole={setRole} role={role} roleType={roleType} />
      <Switch>
        <Route
          path="/admin"
          render={() => <Redirect to="/admin/salesReps" />}
          exact
        />
        <Route
          path="/admin/salesReps"
          render={() => <Users setRole={setRole} roleType={roleType} />}
        />
        <Route
          path="/admin/customers"
          render={() => <Customers setRole={setRole} roleType={roleType} />}
        />
      </Switch>
    </div>
  );
};

export default Admin;
