import { createSlice } from "@reduxjs/toolkit";

export const usersSlice = createSlice({
  name: "users",
  initialState: {
    page: 1,
    pageSize: 10,
    pageCount: 1,
    isLoading: false,
    users: [],
  },
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
    setPageCount: (state, action) => {
      state.pageCount = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    resetState: (state, action) => {
      state.page = 1;
      state.pageSize = 10;
      state.pageCount = 1;
      state.isLoading = false;
      state.users = [];
    },
  },
});

export const {
  setPage,
  setPageSize,
  setPageCount,
  setIsLoading,
  setUsers,
  resetState,
} = usersSlice.actions;

export default usersSlice.reducer;
