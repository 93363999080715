import { useHistory } from "react-router-dom";
import s from "./index.module.scss";

const ControlButtons = ({ setRole, role = null, roleType = {} }) => {
  const history = useHistory();

  const handleSalesReps = () => {
    history.push("salesReps");
    setRole(roleType.salesRep);
  };

  const handleCustomers = () => {
    history.push("customers");
    setRole(roleType.customer);
  };

  return (
    <div className={s["control-buttons"]}>
      <button
        className={`${s["control-buttons__button"]}
        ${
          role === roleType.salesRep
            ? s["control-buttons__button--salesReps"]
            : ""
        }
        `}
        onClick={handleSalesReps}
      >
        Sales Reps
      </button>
      <button
        className={`${s["control-buttons__button"]}
         ${
           role === roleType.customer
             ? s["control-buttons__button--customers"]
             : ""
         }
         `}
        onClick={handleCustomers}
      >
        Customers
      </button>
    </div>
  );
};

export default ControlButtons;
