import { useState, useEffect } from "react";

export const useScrollToTop = () => {
  const [isOpenScrollToTop, setIsOpenScrollToTop] = useState(false);

  useEffect(() => {
    const handleScroll = () => setIsOpenScrollToTop(window.scrollY > 100);

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleClickScrollToTop = () => {
    if (!isOpenScrollToTop) {
      return;
    }
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return { isOpenScrollToTop, handleClickScrollToTop };
};
