import { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useInput } from "hooks/input.hook";
import FilledButton from "components/buttons/FilledButton";
import UnfilledButton from "components/buttons/UnfilledButton";
import LabledInput from "components/inputs/LabledInput";
import LabledPassword from "components/inputs/LabledPassword";
import Loader from "components/Loader";
import imageLogoBlackAutoagents from "assets/images/logo-autoagents-black.svg";
import utilValidator from "utils/validator";
import utilFormatter from "utils/formatter";
import serviceUsers from "services/users";
import s from "./index.module.scss";

const SignUp = () => {
  const zipCodeController = useInput("");
  const firstNameController = useInput("");
  const lastNameController = useInput("");
  const emailController = useInput("");
  const phoneNumberController = useInput("");
  const passwordController = useInput("");
  const confirmPasswordController = useInput("");

  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const zipCodeRef = useRef(null);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const confirmPasswordRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  const setIsErrorHandler = {
    zip_code: zipCodeController.setIsError,
    first_name: firstNameController.setIsError,
    last_name: lastNameController.setIsError,
    email: emailController.setIsError,
    password: firstNameController.setIsError,
  };

  const handleChangeLocalZipCode = (value) => {
    zipCodeController.setIsError(false);
    zipCodeController.setValue(utilFormatter.prettyZip(value));
  };

  const setErrorText = {
    zip_code: zipCodeController.setErrorText,
    first_name: firstNameController.setErrorText,
    last_name: lastNameController.setErrorText,
    email: emailController.setErrorText,
    password: passwordController.setErrorText,
    confirm_password: confirmPasswordController.setErrorText,
  };

  const setDefaultInputField = () => {
    for (const key in setErrorText) {
      setErrorText[key]("");
    }

    for (const key in setIsErrorHandler) {
      setIsErrorHandler[key](false);
    }
  };

  const isDisabledButton =
    emailController.value.length === 0 ||
    passwordController.value.length === 0 ||
    confirmPasswordController.value.length === 0 ||
    isLoading ||
    zipCodeController.value.length === 0 ||
    firstNameController.value.length === 0 ||
    lastNameController.value.length === 0;

  const handleClickSignIn = () => {
    history.push("/sign-in");
  };

  const setFocusToErrorField = (fieldKeyToErrorMap) => {
    //detect mouse and touchpad
    if (matchMedia("(hover: hover) and (pointer: fine)").matches) return;

    const fieldRefs = [
      {
        key: "first_name",
        fieldRef: firstNameRef,
      },
      {
        key: "last_name",
        fieldRef: lastNameRef,
      },
      {
        key: "zip_code",
        fieldRef: zipCodeRef,
      },
      {
        key: "email",
        fieldRef: emailRef,
      },
      {
        key: "password",
        fieldRef: passwordRef,
      },
      {
        key: "confirm_password",
        fieldRef: confirmPasswordRef,
      },
    ];

    const errorToFieldsRefs = fieldRefs.map((field) => {
      if (Object.keys(fieldKeyToErrorMap).includes(field.key)) {
        return {
          key: field.key,
          error: fieldKeyToErrorMap[field.key],
          fieldRef: field.fieldRef,
        };
      }
      return null;
    });

    const filteredErrorToFieldsRefs = errorToFieldsRefs.filter(
      (field) => !!field?.error
    );

    if (filteredErrorToFieldsRefs.length === 0) return;

    const fieldToFocus = filteredErrorToFieldsRefs[0]?.fieldRef?.current;

    if (!fieldToFocus) return;
    fieldToFocus.focus();
  };

  const errorHandler = (data) => {
    const errorKeys = Object.keys(data);

    const fieldKeyToErrorMap = {};
    const errorMesages = [];

    for (const key of errorKeys) {
      data[key].forEach((value) => {
        errorMesages.push(value.message);
        setErrorText[key](value.message);
        fieldKeyToErrorMap[key] = value.message;
      });
      setIsErrorHandler[key](true);
    }

    setFocusToErrorField(fieldKeyToErrorMap);
  };

  const handleClickSignUp = async () => {
    if (isDisabledButton) {
      return;
    }

    setDefaultInputField();

    const emailErrorMessage = utilValidator.validateEmailAndGetError(
      emailController.value
    );

    const zipCodeErrorMessage = utilValidator.validateZipCodeAndGetError(
      zipCodeController.value
    );

    const passwordErrorMessage = utilValidator.validatePasswordAndGetError(
      passwordController.value
    );

    const passwordMatchErrorMessage =
      utilValidator.validatePasswordConfirmationAndGetError(
        passwordController.value,
        confirmPasswordController.value
      );

    const errorsMsg = [
      emailErrorMessage,
      zipCodeErrorMessage,
      passwordErrorMessage,
      passwordMatchErrorMessage,
    ].filter((value) => !!value);

    emailController.setIsError(!!emailErrorMessage);

    zipCodeController.setIsError(!!zipCodeErrorMessage);

    passwordController.setIsError(!!passwordErrorMessage);

    confirmPasswordController.setIsError(!!passwordMatchErrorMessage);

    if (errorsMsg.length !== 0) {
      if (emailErrorMessage) {
        setErrorText["email"](emailErrorMessage);
      }
      if (zipCodeErrorMessage) {
        setErrorText["zip_code"](zipCodeErrorMessage);
      }
      if (passwordErrorMessage) {
        setErrorText["password"](passwordErrorMessage);
      }
      if (passwordMatchErrorMessage) {
        setErrorText["confirm_password"](passwordMatchErrorMessage);
      }
      setFocusToErrorField({
        zip_code: zipCodeErrorMessage,
        email: emailErrorMessage,
        password: passwordErrorMessage,
        confirm_password: passwordMatchErrorMessage,
      });
      return;
    }

    setIsLoading(true);
    serviceUsers
      .signUp(
        emailController.value.trim(),
        passwordController.value,
        firstNameController.value,
        lastNameController.value,
        phoneNumberController.value,
        zipCodeController.value
      )
      .then(() => history.push(`/cabinet`))
      .catch((error) => {
        errorHandler(error.response.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleInputEnter = (e) => {
    if (e.key !== "Enter") {
      return;
    }
    handleClickSignUp();
  };

  return (
    <div className={s["sign-up"]}>
      <img
        src={imageLogoBlackAutoagents}
        className={s["sign-up__logo"]}
        alt="AutoAgents Logo"
      />
      <h1 className={s["sign-up__title"]}>Sign up</h1>
      <div
        className={s["sign-up__inputs-wrapper"]}
        onKeyDown={(e) => handleInputEnter(e)}
      >
        <LabledInput
          title="First Name"
          type="text"
          required
          value={firstNameController.value}
          isError={firstNameController.isError}
          errorText={firstNameController.errorText}
          onChange={firstNameController.setValue}
          ref={firstNameRef}
        />
        <LabledInput
          title="Last Name"
          type="text"
          required
          value={lastNameController.value}
          isError={lastNameController.isError}
          errorText={lastNameController.errorText}
          onChange={(value) => {
            lastNameController.setValue(value);
          }}
          ref={lastNameRef}
        />
        <LabledInput
          className={s["sign-up__inputs-wrapper-zip-code"]}
          title="Postal Code"
          type="text"
          required
          spliter=" "
          chunkSize={3}
          value={zipCodeController.value}
          isError={zipCodeController.isError}
          errorText={zipCodeController.errorText}
          onChange={handleChangeLocalZipCode}
          ref={zipCodeRef}
        />
        <LabledInput
          title="Email"
          type="text"
          required
          value={emailController.value}
          isError={emailController.isError}
          errorText={emailController.errorText}
          onChange={(value) => {
            emailController.setValue(value);
          }}
          ref={emailRef}
        />
        <LabledInput
          title="Phone number"
          type="text"
          value={phoneNumberController.value}
          onChange={(value) => {
            phoneNumberController.setValue(value);
          }}
        />
        <LabledPassword
          title="Password"
          value={passwordController.value}
          isError={passwordController.isError}
          errorText={passwordController.errorText}
          onChange={(value) => passwordController.setValue(value)}
          ref={passwordRef}
        />
        <LabledPassword
          title="Confirm Password"
          value={confirmPasswordController.value}
          isError={confirmPasswordController.isError}
          errorText={confirmPasswordController.errorText}
          onChange={(value) => confirmPasswordController.setValue(value)}
          ref={confirmPasswordRef}
        />
      </div>
      <FilledButton
        className={s["sign-up__button"]}
        disabled={isDisabledButton}
        onClick={handleClickSignUp}
      >
        sign up
      </FilledButton>
      <UnfilledButton
        className={s["sign-up__sign-in-button"]}
        onClick={handleClickSignIn}
      >
        sign in
      </UnfilledButton>
      <Loader isOpen={isLoading} />
    </div>
  );
};

export default SignUp;
