import { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import iconSave from "assets/icons/icon-save.svg";
import iconSaveDisabled from "assets/icons/icon-save-disabled.svg";
import iconCross from "assets/icons/cross-black-bold.svg";
import serviceWatchLists from "services/watch-lists";
import s from "./index.module.scss";

const EmployeeDescription = ({
  defaultValue: startValue = "",
  watchListId = "",
  vehicleId = "",
}) => {
  const [defaultValue, setDefaultValue] = useState(startValue || "");
  const [value, setValue] = useState(startValue || "");
  const [isLoading, setIsLoading] = useState(false);

  const isAbleToSave = !isLoading && defaultValue !== value;

  const textareaRef = useRef();

  const resize = () => {
    const textarea = textareaRef.current;

    if (!textarea) {
      return;
    }

    textarea.style.height = `max-content`;
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  useEffect(resize, [value]);

  useEffect(() => {
    window.addEventListener("resize", resize);

    return () => window.removeEventListener("resize", resize);
  }, []);

  useEffect(() => {
    setDefaultValue(startValue || "");
    setValue(startValue || "");
  }, [startValue]);

  const handleChange = (e) => setValue(e.target.value);

  const handleClickSave = () => {
    if (!isAbleToSave) {
      return;
    }
    setIsLoading(true);
    if (value) {
      setValue(value.trim());
      serviceWatchLists
        .patchAddons(watchListId, vehicleId, {
          employee_description: value.trim(),
        })
        .then(() => {
          setDefaultValue(value.trim());
          toast.success("Description saved", {
            position: "bottom-right",
            autoClose: 2000,
            closeOnClick: true,
            pauseOnHover: true,
          });
        })
        .finally(() => setIsLoading(false));
    } else {
      serviceWatchLists
        .removeAddons(watchListId, vehicleId, ["employee_description"])
        .then(() => {
          setDefaultValue(value);
          toast.success("Description removed", {
            position: "bottom-right",
            autoClose: 2000,
            closeOnClick: true,
            pauseOnHover: true,
          });
        })
        .finally(() => setIsLoading(false));
    }
  };

  const handleClickClose = () => setValue(defaultValue);

  return (
    <div className={s["employee-description"]}>
      <textarea
        className={s["employee-description__textarea"]}
        placeholder="Write the description here"
        value={value}
        onChange={handleChange}
        ref={textareaRef}
      ></textarea>
      <div className={s["employee-description__icons"]}>
        <img
          className={
            s["employee-description__icon"] +
            (isAbleToSave
              ? ""
              : ` ${s["employee-description__icon--disabled"]}`)
          }
          src={isAbleToSave ? iconSave : iconSaveDisabled}
          alt="Save notes"
          onClick={handleClickSave}
        />
        {isAbleToSave && (
          <img
            className={s["employee-description__icon"]}
            src={iconCross}
            alt="Сancel"
            onClick={handleClickClose}
          />
        )}
      </div>
    </div>
  );
};

export default EmployeeDescription;
