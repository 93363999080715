import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import CustomPrice from "components/CustomPrice";
import Loader from "components/Loader";
import EditablePrice from "components/EditablePrice";
import AddToWatchlistButton from "components/buttons/AddToWatchlist";

import serviceWatchLists from "services/watch-lists";

import utilConstants from "utils/constants";
import utilFormatter from "utils/formatter";

import iconMapPoint from "assets/icons/map-point.svg";
import iconRoundedPen from "assets/icons/rounded-pen.svg";
import iconSave from "assets/icons/icon-save.svg";
import iconRemoveToWatchlist from "assets/icons/remove-to-watchlist.svg";
import iconCrossBlack from "assets/icons/cross-black-bold.svg";
import iconOut from "../../../assets/icons/out.svg";

import s from "./index.module.scss";

const MainInfo = ({
  handleClickOpenModal = () => {},
  onAddToWatchList = () => {},
  onRemoveFromWatchList = () => {},
  watchListId = null,
  vehicle = {},
  addonPrice = null,
  isVehicleLoading = false,
}) => {
  const [localVehicle, setLocalVehicle] = useState(vehicle);

  useEffect(() => {
    setLocalVehicle(vehicle);
  }, [vehicle]);

  const {
    build: {
      year: startYear = "",
      make: startMake = "",
      model: startModel = "",
      trim: startTrim = "",
    } = {},
    delisted = false,
    mileage = null,
    dist = null,
    price = null,
    our_offered_price: offeredPrice = null,
    lowest_offered_price: lowestOfferedPrice = null,
    source: sourceName = "",
    id: vehicleId = "",
  } = localVehicle;
  const inWatchList = !!watchListId;
  const isAbleToEdit = inWatchList && !delisted && !isVehicleLoading;
  const isReadOnlyPrice = inWatchList || delisted;
  const isAdditionalPrices = offeredPrice || lowestOfferedPrice;
  const showAdditionalPrices =
    (!isReadOnlyPrice || isAdditionalPrices) && !isVehicleLoading;

  const isMarginPriceRow =
    typeof mileage !== "number" && typeof dist !== "number";

  const [editMode, setEditMode] = useState(false);
  const [year, setYear] = useState(startYear || "");
  const [make, setMake] = useState(startMake || "");
  const [model, setModel] = useState(startModel || "");
  const [trim, setTrim] = useState(startTrim || "");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setYear(startYear);
    setMake(startMake);
    setModel(startModel);
    setTrim(startTrim);
  }, [startYear, startMake, startModel, startTrim]);

  const yearRef = useRef();
  const makeRef = useRef();
  const modelRef = useRef();
  const trimRef = useRef();

  const handleClickEdit = () => {
    if (!isAbleToEdit) {
      return;
    }
    setEditMode(true);
  };

  const discardValue = (ref, value) => (ref.current.textContent = value);

  const getValue = (ref) => ref.current.textContent;

  const discardValues = () => {
    discardValue(yearRef, year);
    discardValue(makeRef, make);
    discardValue(modelRef, model);
    discardValue(trimRef, trim);
  };

  const saveValue = (ref, value, callback) => {
    ref.current.textContent = value;
    callback(value);
  };

  const handleClickCancel = () => {
    if (isLoading) {
      return;
    }

    setEditMode(false);
    discardValues();
  };

  const handleClickSave = () => {
    if (isLoading) {
      return;
    }

    const newYear = parseInt(getValue(yearRef).replaceAll(/\D/g, "")) || null;
    const newMake = getValue(makeRef);
    const newModel = getValue(modelRef);
    const newTrim = getValue(trimRef);

    setIsLoading(true);
    serviceWatchLists
      .patchVehicle(watchListId, vehicleId, {
        year: newYear,
        make: newMake,
        model: newModel,
        trim: newTrim,
      })
      .then((vehicle) => {
        const { build: { year = "", make = "", model = "", trim = "" } = {} } =
          vehicle;
        saveValue(yearRef, year, setYear);
        saveValue(makeRef, make, setMake);
        saveValue(modelRef, model, setModel);
        saveValue(trimRef, trim, setTrim);
      })
      .catch(() => {
        discardValues();
      })
      .finally(() => {
        setIsLoading(false);
        setEditMode(false);
      });
  };

  return (
    <div
      className={
        s["main-info__main"] +
        (inWatchList ? " " + s["main-info__main--watchlist"] : "")
      }
    >
      {isAbleToEdit ? (
        !editMode ? (
          <img
            className={
              s["main-info__edit-icon"] + " " + s["main-info__edit-icon--edit"]
            }
            src={iconRoundedPen}
            alt="Edit vehicle"
            onClick={handleClickEdit}
          />
        ) : (
          <>
            <img
              className={
                s["main-info__edit-icon"] +
                " " +
                s["main-info__edit-icon--cancel"]
              }
              src={iconCrossBlack}
              alt="Cancel"
              onClick={handleClickCancel}
            />
            <img
              className={
                s["main-info__edit-icon"] +
                " " +
                s["main-info__edit-icon--save"]
              }
              src={iconSave}
              alt="Save vehicle"
              onClick={handleClickSave}
            />
          </>
        )
      ) : (
        <></>
      )}
      <div
        className={
          s["main-info__row"] +
          (isAbleToEdit ? ` ${s["main-info__row--editable"]}` : "")
        }
      >
        <span
          className={
            s["main-info__text"] +
            (editMode ? ` ${s["main-info__text--edit"]}` : "") +
            (delisted ? ` ${s["main-info__text--delisted"]}` : "")
          }
          contentEditable={editMode}
          data-placeholder="Year"
          ref={yearRef}
          suppressContentEditableWarning={true}
        >
          {year}
        </span>
        {delisted && <p className={s["main-info__badge"]}>Delisted</p>}
        {!delisted &&
          !inWatchList &&
          (utilConstants.sources[sourceName] || { isAbleToDelete: false })
            .isAbleToDelete && (
            <div
              className={s["main-info__delete-icon"]}
              onClick={handleClickOpenModal}
            ></div>
          )}
      </div>
      <div className={isAbleToEdit ? s["main-info__row--editable"] : ""}>
        <span
          className={
            s["main-info__text"] +
            " " +
            s["main-info__text--bold"] +
            (editMode ? ` ${s["main-info__text--edit"]}` : "") +
            (delisted ? ` ${s["main-info__text--delisted"]}` : "")
          }
          contentEditable={editMode}
          data-placeholder="Make"
          ref={makeRef}
          suppressContentEditableWarning={true}
        >
          {make}
        </span>
        &nbsp;
        <span
          className={
            s["main-info__text"] +
            " " +
            s["main-info__text--bold"] +
            (editMode ? ` ${s["main-info__text--edit"]}` : "") +
            (delisted ? ` ${s["main-info__text--delisted"]}` : "")
          }
          contentEditable={editMode}
          data-placeholder="Model"
          ref={modelRef}
          suppressContentEditableWarning={true}
        >
          {model}
        </span>
        &nbsp;
        <span
          className={
            s["main-info__text"] +
            " " +
            s["main-info__text--bold"] +
            (editMode ? ` ${s["main-info__text--edit"]}` : "") +
            (delisted ? ` ${s["main-info__text--delisted"]}` : "")
          }
          contentEditable={editMode}
          data-placeholder="Trim"
          ref={trimRef}
          suppressContentEditableWarning={true}
        >
          {trim}
        </span>
      </div>
      {typeof mileage === "number" && (
        <div className={s["main-info__row"]}>
          <p
            className={
              s["main-info__text"] +
              (delisted ? ` ${s["main-info__text--delisted"]}` : "")
            }
          >
            {utilFormatter.prettyString(parseInt(mileage), " ")} km
          </p>
        </div>
      )}
      {typeof dist === "number" && (
        <div className={s["main-info__row"]}>
          <div className={s["main-info__distance-wrapper"]}>
            <img
              className={
                s["main-info__icon-map-point"] +
                (delisted ? ` ${s["main-info__icon-map-point--delisted"]}` : "")
              }
              src={iconMapPoint}
              alt="Distance to vehicle"
            />
            <p
              className={
                s["main-info__text"] +
                " " +
                s["main-info__text--number"] +
                (delisted ? ` ${s["main-info__text--delisted"]}` : "")
              }
            >
              {utilFormatter.prettyString(parseInt(dist), " ")} km
            </p>
          </div>
        </div>
      )}
      <div
        className={
          s["main-info__row"] +
          (isMarginPriceRow ? ` ${s["main-info__row--margin"]}` : "") +
          (inWatchList ? ` ${s["main-info__row--watchlist"]}` : "")
        }
      >
        <div>
          <p
            className={
              s["main-info__text"] +
              " " +
              s["main-info__text--price"] +
              (inWatchList ? " " + s["main-info__text--price-watchlist"] : "") +
              (delisted ? ` ${s["main-info__text--price-delisted"]}` : "")
            }
          >
            {typeof price === "number" &&
              "$" + utilFormatter.prettyString(price, ",")}
          </p>
          {inWatchList && (
            <CustomPrice
              defaultValue={addonPrice}
              watchListId={watchListId}
              vehicleId={vehicleId}
              delisted={delisted}
            />
          )}
        </div>

        {inWatchList ? (
          <div
            className={
              s["main-info__button"] + " " + s["main-info__button--remove"]
            }
            onClick={onRemoveFromWatchList}
          >
            <img src={iconRemoveToWatchlist} alt="Remove" />
            <div className={s["main-info__button-minus"]}></div>
          </div>
        ) : (
          <AddToWatchlistButton onClick={onAddToWatchList} />
        )}
      </div>
      {showAdditionalPrices ? (
        <div
          className={
            s["main-info__editable-prices"] +
            (inWatchList
              ? " " + s["main-info__editable-prices--watchlist"]
              : "")
          }
        >
          <EditablePrice
            vehicleId={vehicleId}
            priceType="our_offered_price"
            title="our offered price"
            price={offeredPrice}
            isReadOnly={isReadOnlyPrice}
            isDelisted={delisted}
          />
          <EditablePrice
            vehicleId={vehicleId}
            priceType="lowest_offered_price"
            title="their lowest offered price"
            price={lowestOfferedPrice}
            isReadOnly={isReadOnlyPrice}
            isDelisted={delisted}
          />
        </div>
      ) : (
        <></>
      )}
      {inWatchList ? (
        <Link
          className={s["main-info__link-original-page"]}
          to={`/employee/vehicles/${vehicleId}`}
          target="_blank"
          rel="noreferrer"
        >
          <p className={s["main-info__link-original-page__text"]}>
            Vehicle’s original page
          </p>
          <img
            className={s["main-info__link-original-page__icon"]}
            src={iconOut}
            alt="link Vehicle’s original page"
          />
        </Link>
      ) : (
        <></>
      )}
      <Loader isOpen={isLoading} />
    </div>
  );
};

export default MainInfo;
