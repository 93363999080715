import { useState, useEffect } from "react";
import Modal from "components/Modal";
import SimpleInput from "components/inputs/SimpleInput";
import AvatarUploader from "components/AvatarUploader";
import UnfilledButton from "components/buttons/UnfilledButton";
import FilledButton from "components/buttons/FilledButton";
import servicePassword from "services/password";
import serviceUsers from "services/users";
import utilValidator from "utils/validator";
import s from "./index.module.scss";

const CreateEditUserModal = ({
  isOpen = false,
  onClose = () => {},
  isEditing = false,
  defaultValues: {
    defaultFirstName = "",
    defaultLastName = "",
    defaultEmail = "",
    defaultTwilioPhoneNumber = "",
    defaultAvatar = null,
  } = {},
  userId = "",
}) => {
  const [firstName, setFirstName] = useState(defaultFirstName);
  const [lastName, setLastName] = useState(defaultLastName);
  const [email, setEmail] = useState(defaultEmail);
  const [emailError, setEmailError] = useState("");
  const [twilioPhoneNumber, setTwilioPhoneNumber] = useState(
    defaultTwilioPhoneNumber
  );
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [avatar, setAvatar] = useState(defaultAvatar);
  const [isLoading, setIsLoading] = useState(false);

  const isDisabledCancelButton = isLoading;
  const isDisabledAddButton =
    !firstName || !lastName || !email || !password || isLoading;
  const isDisabledSaveButton = !firstName || !lastName || !email || isLoading;
  const isDisabledPrimaryButton =
    (!isEditing && isDisabledAddButton) || (isEditing && isDisabledSaveButton);

  const reset = () => {
    setFirstName(defaultFirstName);
    setLastName(defaultLastName);
    setEmail(defaultEmail);
    setTwilioPhoneNumber(defaultTwilioPhoneNumber);
    setEmailError("");
    setPassword("");
    setAvatar(defaultAvatar);
  };

  useEffect(reset, [
    defaultFirstName,
    defaultLastName,
    defaultEmail,
    defaultTwilioPhoneNumber,
    defaultAvatar,
  ]);

  const resetErrorState = () => {
    setEmailError("");
    setPasswordError("");
  };

  const handleClose = () => {
    if (isEditing) reset();

    resetErrorState();
    onClose();
  };

  const handleClickCancel = () => {
    if (isDisabledCancelButton) {
      return;
    }

    reset();
    handleClose();
  };

  const handleClickGeneratePassword = () =>
    setPassword(servicePassword.generate(16));

  const handleAddEditUser = async () => {
    if (isDisabledPrimaryButton) {
      return;
    }

    resetErrorState();

    let passwordErrorMessage;

    if (password) {
      passwordErrorMessage =
        utilValidator.validatePasswordAndGetError(password);
      setPasswordError(passwordErrorMessage);
    }

    const trimmedEmail = email.trim();
    setEmail(trimmedEmail);

    const trimmedTwilioPhoneNumber = twilioPhoneNumber.trim();
    setTwilioPhoneNumber(trimmedTwilioPhoneNumber);

    const emailErrorMessage =
      utilValidator.validateEmailAndGetError(trimmedEmail);
    if (email !== defaultEmail && emailErrorMessage) {
      setEmailError(emailErrorMessage);
    }

    const errorsMsg = [passwordErrorMessage, emailErrorMessage].filter(
      (value) => !!value
    );

    if (errorsMsg.length !== 0) return;

    setIsLoading(true);

    serviceUsers
      .createOrUpdateEmployee(
        firstName,
        lastName,
        trimmedEmail,
        trimmedTwilioPhoneNumber,
        password,
        avatar,
        userId
      )
      .then(() => {
        reset();
        handleClose();
      })
      .catch((e) => {
        const errors = e.response?.data?.email || [];
        const isAlreadyTakenEmail = errors.filter(
          (error) =>
            error.key === "error_email_already_taken" || "error_already_taken"
        ).length;
        if (isAlreadyTakenEmail) {
          setEmailError("User with this email already exists");
        }
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <div className={s["create-user-modal"]}>
        <div className={s["create-user-modal__title-wrapper"]}>
          <p className={s["create-user-modal__title"]}>
            {isEditing ? (
              <span className={s["create-user-modal__title--bold"]}>
                Edit Sales Rep
              </span>
            ) : (
              <>
                <span className={s["create-user-modal__title--bold"]}>
                  Add a new Sales Rep{" "}
                </span>
                (fill in all fields)
              </>
            )}
          </p>
        </div>
        <div className={s["create-user-modal__form-wrapper"]}>
          <div className={s["create-user-modal__inputs-wrapper"]}>
            <SimpleInput
              className={s["create-user-modal__input--first-name"]}
              value={firstName}
              placeholder="First name"
              onChange={(e) => setFirstName(e.target.value)}
            />
            <SimpleInput
              value={lastName}
              placeholder="Last name"
              onChange={(e) => setLastName(e.target.value)}
            />
            <SimpleInput
              value={email}
              placeholder="Email"
              onChange={(e) => {
                setEmail(e.target.value);
                setEmailError("");
              }}
              error={emailError}
            />
            <SimpleInput
              value={twilioPhoneNumber}
              placeholder="Twilio phone number"
              onChange={(e) => setTwilioPhoneNumber(e.target.value)}
            />
            <SimpleInput
              value={password}
              placeholder="Password"
              onChange={(e) => {
                setPasswordError("");
                setPassword(e.target.value);
              }}
              error={passwordError}
            />
            <p
              className={
                s["create-user-modal__link"] +
                (passwordError
                  ? ` ${s["create-user-modal__link--password-error"]}`
                  : "")
              }
              onClick={handleClickGeneratePassword}
            >
              Generate password
            </p>
          </div>
          <div className={s["create-user-modal__avatar-wrapper"]}>
            <AvatarUploader
              className={s["create-user-modal__avatar"]}
              avatar={avatar}
              onChange={(avatar) => setAvatar(avatar)}
            />
            <span className={s["create-user-modal__avatar__text"]}>
              Upload photo
            </span>
          </div>
          <div className={s["create-user-modal__buttons-wrapper"]}>
            <UnfilledButton
              className={s["create-user-modal__button"]}
              disabled={isDisabledCancelButton}
              onClick={handleClickCancel}
            >
              Cancel
            </UnfilledButton>
            <FilledButton
              className={
                s["create-user-modal__button"] +
                " " +
                s["create-user-modal__button--primary"]
              }
              disabled={isDisabledPrimaryButton}
              onClick={handleAddEditUser}
            >
              {isEditing ? "Save" : "Add user"}
            </FilledButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CreateEditUserModal;
