import { useEffect } from "react";
import { useSelector } from "react-redux";
import VehiclesFilters from "components/VehiclesFilters";
import utilConstants from "utils/constants";

const SavedSearchVehiclesFilters = ({
  vehicleRepository,
  vehicleService,
  userRepository,
  defaultSearchText,
  defaultSorting,
  defaultZipCode,
  defaultFilters,
}) => {
  const isLoading = useSelector((state) =>
    vehicleRepository.getLoadingState(state)
  );
  const vehiclesZipCode = useSelector((state) =>
    vehicleRepository.getZipCode(state)
  );
  const userZipCode = useSelector((state) => userRepository.getZipCode(state));
  const role = useSelector((state) => userRepository.getRole(state));
  const stats = useSelector((state) => vehicleRepository.getStats(state));

  const searchText = useSelector((state) =>
    vehicleRepository.getSearchText(state)
  );
  const sorting = useSelector((state) => vehicleRepository.getSorting(state));
  const filters = useSelector((state) => vehicleRepository.getFilters(state));
  const storedZipCode = userZipCode ? userZipCode : vehiclesZipCode;

  const isExtendedFiltering = utilConstants.roles[role].isExtendedFiltering;

  const handleResetFilters = () => {
    vehicleRepository.resetVehicles();
    vehicleRepository.updateSearchText(
      vehicleRepository.getDefaultSearchText()
    );
    vehicleRepository.updateZipCode(vehicleRepository.getDefaultZipCode());
    vehicleRepository.updateSorting(vehicleRepository.getDefaultSorting());
    vehicleRepository.updateFilters(vehicleRepository.getDefaultFilters());
    vehicleService.getVehiclesPage().catch(() => {});
  };

  const handleApplyFilters = (searchText, sorting, zipCode, filters) => {
    vehicleRepository.resetVehicles();
    vehicleRepository.updateLoadingState(true);
    vehicleRepository.updateZipCode(zipCode);

    vehicleRepository.updateSearchText(searchText);
    vehicleRepository.updateSorting(sorting);
    vehicleRepository.updateFilters(filters);

    return vehicleService
      .getVehiclesPage()
      .catch(() => Promise.reject())
      .finally(() => {
        vehicleRepository.updateLoadingState(false);
      });
  };

  useEffect(() => {
    vehicleRepository.updateSearchText(defaultSearchText);
  }, [defaultSearchText, vehicleRepository]);

  useEffect(() => {
    vehicleRepository.updateSorting(defaultSorting);
  }, [defaultSorting, vehicleRepository]);

  useEffect(() => {
    vehicleRepository.updateZipCode(defaultZipCode);
  }, [defaultZipCode, vehicleRepository]);

  useEffect(() => {
    vehicleRepository.updateFilters(defaultFilters);
  }, [defaultFilters, vehicleRepository]);

  return (
    <VehiclesFilters
      isLoading={isLoading}
      defaultZipCode={storedZipCode}
      defaultSearchText={searchText}
      defaultSorting={sorting}
      defaultFilters={filters}
      stats={stats}
      isExtendingFiltering={isExtendedFiltering}
      ableToChangeVehiclesType={false}
      onResetFilters={handleResetFilters}
      onApplyFilters={handleApplyFilters}
    />
  );
};

export default SavedSearchVehiclesFilters;
