import s from "./index.module.scss";

const Switch = ({
  isChecked = false,
  onChange = () => {},
  className = "",
  backgroundColor = "#9a9a9a",
}) => {
  return (
    <label className={s["switch"] + (className ? " " + className : "")}>
      <input
        className={s["switch__input"]}
        type="checkbox"
        checked={isChecked}
        onChange={onChange}
      />
      <span className={s["switch__slider"]} style={{ backgroundColor }}></span>
    </label>
  );
};

export default Switch;
