import s from "./index.module.scss";

const GridViewIcon = ({ className = "", isDisabled = true }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 11"
      className={className}
    >
      <path
        d="M15.625 7.708h-1.871l-.52-1.192A3.494 3.494 0 0 0 9.985 4.5H6.016a3.5 3.5 0 0 0-3.25 2.017l-.52 1.192H.375a.352.352 0 0 0-.364.427l.189.687a.37.37 0 0 0 .364.26h.626a1.762 1.762 0 0 0-.69 1.375v1.375a1.736 1.736 0 0 0 .5 1.2v1.549a.961.961 0 0 0 1 .918h1a.961.961 0 0 0 1-.917v-.917h8v.917a.961.961 0 0 0 1 .917h1a.961.961 0 0 0 1-.917v-1.549a1.734 1.734 0 0 0 .5-1.2v-1.376a1.763 1.763 0 0 0-.69-1.375h.627a.37.37 0 0 0 .364-.26l.188-.687a.352.352 0 0 0-.364-.428zm-11-.511a1.505 1.505 0 0 1 1.393-.864h3.967a1.505 1.505 0 0 1 1.393.864L12 8.625H4L4.623 7.2zM3 11.828A.918.918 0 1 1 3 10a1.851 1.851 0 0 1 1.5 1.371c0 .548-.9.457-1.5.457zm10 0c-.6 0-1.5.091-1.5-.457A1.851 1.851 0 0 1 13 10a.918.918 0 1 1 0 1.828z"
        transform="translate(0 -4.5)"
        className={
          s["icon-path"] + (isDisabled ? " " + s["icon-path--disabled"] : "")
        }
      />
    </svg>
  );
};

export default GridViewIcon;
