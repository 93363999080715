import useVehicleCard from "hooks/useVehicleCard";
import { Link } from "react-router-dom";
import VehicleReactions from "components/VehicleReactions";
import utilFormatter from "utils/formatter";
import s from "./index.module.scss";

const PublicWatchListVehicleCard = ({
  vehicle: {
    to = "",
    id = "",
    linkParams = {},
    thumbnail = null,
    year = null,
    make = "",
    model = "",
    trim = "",
    mileage = null,
    price = null,
    delisted = false,
    reaction = null,
  } = {},
  watchListId = "",
}) => {
  const { isThumbnailLoadingError, onThumbnailLoadingError } = useVehicleCard();

  return (
    <Link className={s["vehicle-card"]} to={to} {...linkParams}>
      {thumbnail?.link && !isThumbnailLoadingError ? (
        <img
          className={s["vehicle-card__photo"]}
          src={thumbnail.link}
          alt="Watchlists thumbnail"
          onError={onThumbnailLoadingError}
        />
      ) : (
        <div className={s["vehicle-card__placeholder"]}></div>
      )}
      <div className={s["vehicle-card__description-wrapper"]}>
        <div
          className={
            s["vehicle-card__description"] +
            " " +
            s["vehicle-card__description--left-side"]
          }
        >
          <p
            className={
              s["vehicle-card__text"] + " " + s["vehicle-card__text--left-side"]
            }
          >
            {typeof year === "number" && year}
          </p>
          <p
            className={
              s["vehicle-card__text"] +
              " " +
              s["vehicle-card__text--left-side"] +
              " " +
              s["vehicle-card__text--bold"]
            }
          >
            {make} {model} {trim}
          </p>
          <p
            className={
              s["vehicle-card__text"] +
              " " +
              s["vehicle-card__text--left-side"] +
              " " +
              s["vehicle-card__text--mileage"]
            }
          >
            {typeof mileage === "number" &&
              utilFormatter.prettyString(parseInt(mileage), " ") + " km"}
          </p>
        </div>
        <div
          className={
            s["vehicle-card__description"] +
            " " +
            s["vehicle-card__description--right-side"]
          }
        >
          <div className={s["vehicle-card__valuation-wrapper"]}>
            <VehicleReactions
              reaction={reaction}
              watchListId={watchListId}
              vehicleId={id}
            />
          </div>
          <p
            className={
              s["vehicle-card__text"] +
              " " +
              s["vehicle-card__text--right-side"] +
              " " +
              s["vehicle-card__text--price"]
            }
          >
            {typeof price === "number" &&
              "$" + utilFormatter.prettyString(price, ",")}
          </p>
        </div>
      </div>
      {delisted && (
        <div className={s["vehicle-card__delisted"]}>
          <p className={s["vehicle-card__badge"]}>Delisted</p>
        </div>
      )}
    </Link>
  );
};

export default PublicWatchListVehicleCard;
