import { useState, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import s from "./index.module.scss";

const Tabs = ({ tabs = [], currentTabValue = "", onChange = () => {} }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const isActivitiesUnread = useSelector((state) => state.activities.hasUnread);

  const handleClickTab = useCallback(
    (index) => {
      setCurrentIndex(index);
      onChange(tabs[index].value);
    },
    [onChange, tabs]
  );

  useEffect(() => {
    let newTabIndex = 0;
    tabs.forEach((tab, index) => {
      if (tab.value !== currentTabValue) {
        return;
      }
      newTabIndex = index;
    });
    handleClickTab(newTabIndex);
  }, [currentTabValue, tabs, handleClickTab]);

  return (
    <div
      className={s["tabs"]}
      style={{ gridTemplateColumns: `repeat(${tabs.length}, 1fr)` }}
    >
      {tabs.map(({ title }, index) => (
        <div
          className={
            s["tabs__tab"] +
            (currentIndex === index ? ` ${s["tabs__tab--active"]}` : "")
          }
          onClick={() => handleClickTab(index)}
          key={index}
        >
          <div
            className={
              s["tabs__content"] +
              (currentIndex === index ? ` ${s["tabs__content--active"]}` : "") +
              (currentIndex === index && currentIndex === 0
                ? ` ${s["tabs__content--first"]}`
                : "") +
              (currentIndex === index && currentIndex === tabs.length - 1
                ? ` ${s["tabs__content--last"]}`
                : "") +
              (currentIndex !== index && index + 1 === currentIndex
                ? ` ${s["tabs__content--prev"]}`
                : "") +
              (currentIndex !== index && index - 1 === currentIndex
                ? ` ${s["tabs__content--next"]}`
                : "") +
              (tabs.length === 1 ? ` ${s["tabs__content--single"]}` : "")
            }
          >
            <span
              className={
                s["tabs__text"] +
                (title === "Activity" && isActivitiesUnread
                  ? " " + s["tabs__text--unread"]
                  : "")
              }
            >
              {title}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Tabs;
