import { createPortal } from "react-dom";
import s from "./index.module.scss";

const LoaderComponent = () => (
  <div className={s["loader-component"]}>
    <div className={s["loader-component__loader"]}></div>
  </div>
);

const Loader = ({ isOpen = false }) => {
  if (!isOpen) {
    return <></>;
  }

  return createPortal(
    <LoaderComponent />,
    document.querySelector("#loader-root")
  );
};

export default Loader;
