import VehicleCard from "components/VehicleCard";
import s from "./index.module.scss";

const VehicleGrid = ({
  vehicles = [],
  cardComponent = null,
  vehicleRepository,
  className = "",
}) => {
  const CardComponent = cardComponent || VehicleCard;

  return (
    <div className={s["vehicle-grid"] + (className ? " " + className : "")}>
      {vehicles.map((vehicle) => (
        <CardComponent
          vehicle={vehicle}
          key={vehicle.to}
          vehicleRepository={vehicleRepository}
        />
      ))}
    </div>
  );
};

export default VehicleGrid;
