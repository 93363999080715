import { createRef } from "react";
import UploadPhoto from "components/inputs/UploadPhoto";
import s from "./index.module.scss";

const UploadPhotoButton = ({ onClick = () => {} }) => {
  const inputRef = createRef();

  const handleClick = () => inputRef.current.click();

  return (
    <>
      <button className={s["upload-photo__button"]} onClick={handleClick}>
        Upload photo
      </button>
      <UploadPhoto onClick={onClick} inputRef={inputRef} />
    </>
  );
};

export default UploadPhotoButton;
