import serviceApi from "services/api";
import store from "store";
import { setHasUnread, setHasEvents } from "slicers/activities";

import vehiclesGateway from "gateways/api/vehiclesGateway";

import vehicleRepository from "repositories/vehicleRepository";
import savedSearchRepository from "repositories/savedSearchRepository";
import hotlistVehicleRepository from "repositories/hotlistVehicleRepository";
import userRepository from "repositories/userRepository";

import vehiclesStateStorageGateway from "gateways/storage/vehiclesStateStorage";

import serviceConversations from "services/conversations";

import utilConstants from "utils/constants";

const baseURL = "vehicles";

const getVehiclesPage = async () => {
  const userRole = userRepository.getRole();

  const zipCode = vehicleRepository.getZipCode();
  const sorting = vehicleRepository.getSorting();
  const filters = vehicleRepository.getFilters();
  const searchText = vehicleRepository.getSearchText();
  const isExtendedFiltering = utilConstants.roles[userRole].isExtendedFiltering;
  const pageNumber = vehicleRepository.getPageNumber();
  const pageSize = vehicleRepository.getPageSize();
  const searchedAt = vehicleRepository.getSearchedAtTimestamp();

  vehiclesStateStorageGateway.saveSearchAndFiltersState(
    zipCode,
    searchText,
    filters,
    sorting
  );

  vehicleRepository.updateLoadingState(true);

  return vehiclesGateway
    .getVehiclesPage(
      zipCode,
      sorting,
      filters,
      searchText,
      isExtendedFiltering,
      pageNumber,
      pageSize,
      searchedAt
    )
    .then(
      ({
        items,
        page_count: pageCount,
        vehicles_stats: vehicleStats = null,
      }) => {
        vehicleRepository.appendVehicles(items);
        vehicleRepository.updatePageCount(pageCount);
        if (utilConstants.roles[userRole].isLoadVehicleStats) {
          vehicleRepository.updateStats(vehicleStats);
        }
      }
    )
    .catch((e) => {
      vehicleRepository.updateVehicles([]);
      return Promise.reject(e);
    })
    .finally(() => vehicleRepository.updateLoadingState(false));
};

const getCsv = async (csvType) => {
  const userRole = userRepository.getRole();

  const zipCode = vehicleRepository.getZipCode();
  const sorting = vehicleRepository.getSorting();
  const filters = vehicleRepository.getFilters();
  const searchText = vehicleRepository.getSearchText();
  const isExtendedFiltering = utilConstants.roles[userRole].isExtendedFiltering;

  return vehiclesGateway
    .getCsv(zipCode, sorting, filters, searchText, isExtendedFiltering, csvType)
    .then((response) => {
      const href = URL.createObjectURL(response);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", "vehicles.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
};

const get = (id, zipCode) => {
  return vehiclesGateway.getVehicle(id, zipCode);
};

const _delete = (id) => serviceApi.delete(`${baseURL}/${id}`);

const addComment = (vehicleId, comment) => {
  return vehiclesGateway.addComment(vehicleId, comment).then(() => {
    vehicleRepository.updateVehicleWorkInProgress(vehicleId);
    savedSearchRepository.updateVehicleWorkInProgress(vehicleId);
  });
};

const removeComment = (vehicleId, commentId) =>
  serviceApi.delete(`${baseURL}/${vehicleId}/comments/${commentId}`);

const changeComment = (id, commentId, text) =>
  serviceApi.put(`${baseURL}/${id}/comments/${commentId}`, { text });

const getMessages = (id, messageType) =>
  serviceApi
    .get(`${baseURL}/${id}/messages`, { message_type: messageType })
    .then((messages) => {
      serviceConversations.getStats();
      return messages;
    });

const sendMessage = (id, text, messageType) =>
  serviceApi.post(`${baseURL}/${id}/messages`, {
    text,
    message_type: messageType,
  });

const deleteConvo = (id) => serviceApi.delete(`${baseURL}/${id}/messages`);

const getConversations = (id) => vehiclesGateway.getConversations(id);

const getConversation = (vehicleId, userId) =>
  vehiclesGateway.getConversation(vehicleId, userId);

const getActivityStats = (vehicleId) =>
  serviceApi.get(`${baseURL}/${vehicleId}/events_stats`).then((stats) => {
    store.dispatch(setHasUnread(stats.has_unread));
    store.dispatch(setHasEvents(stats.has_events));
  });

const getActivity = (id) =>
  serviceApi.get(`${baseURL}/${id}/activity`).then((activities) => {
    getActivityStats(id);
    return activities;
  });

const updateVehicle = (vehicleId, updatedFields) => {
  return vehiclesGateway
    .updateVehicle(vehicleId, updatedFields)
    .then((vehicle) => {
      vehicleRepository.updateVehicleFields(vehicleId, updatedFields);
      savedSearchRepository.updateVehicleFields(vehicleId, updatedFields);
      hotlistVehicleRepository.updateVehicleFields(vehicleId, updatedFields);

      return vehicle;
    });
};

const service = {
  getVehiclesPage,
  getCsv,
  get,
  delete: _delete,
  addComment,
  removeComment,
  changeComment,
  getMessages,
  sendMessage,
  deleteConvo,
  getConversations,
  getConversation,
  getActivity,
  getActivityStats,
  updateVehicle,
};

export default service;
