const getAccessToken = () => {
  return localStorage.getItem("access");
};

const getRefreshToken = () => {
  return localStorage.getItem("refresh");
};

const saveAccessToken = (accessToken) => {
  localStorage.setItem("access", accessToken);
};

const saveRefreshToken = (refreshToken) => {
  localStorage.setItem("refresh", refreshToken);
};

const removeAccessToken = () => {
  localStorage.removeItem("access");
};

const removeRefreshToken = () => {
  localStorage.removeItem("refresh");
};

const authStateStorageGateway = {
  getAccessToken,
  getRefreshToken,
  saveAccessToken,
  saveRefreshToken,
  removeAccessToken,
  removeRefreshToken,
};

export default authStateStorageGateway;
