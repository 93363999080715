import { Switch, Route } from "react-router-dom";
import PublicWatchListHeader from "components/headers/PublicWatchListHeader";
import HeaderWatchListDetails from "components/headers/components/customer/WatchListDetails";
import HeaderWatchListVehicleDetails from "components/headers/components/customer/WatchListVehicleDetails";
import WatchListDetails from "pages/public-watch-lists/details";
import WatchListVehicleDetails from "pages/public-watch-lists/details/vehicles/details";
import s from "./index.module.scss";

const PublicWatchList = () => {
  return (
    <>
      <PublicWatchListHeader>
        <Switch>
          <Route
            path="/public-watch-lists/:watchListId"
            render={() => <HeaderWatchListDetails />}
            exact
          />
          <Route
            path="/public-watch-lists/:watchListId/vehicles/:vehicleId"
            render={() => <HeaderWatchListVehicleDetails />}
            exact
          />
        </Switch>
      </PublicWatchListHeader>
      <div className={s["public-watch-list"]}>
        <Switch>
          <Route
            path="/public-watch-lists/:watchListId"
            render={() => <WatchListDetails />}
            exact
          />
          <Route
            path="/public-watch-lists/:watchListId/vehicles/:vehicleId"
            render={() => <WatchListVehicleDetails />}
          />
        </Switch>
      </div>
    </>
  );
};
export default PublicWatchList;
