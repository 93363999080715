import { useSelector } from "react-redux";

import DefaultVehiclesFilters from "components/DefaultVehiclesFilters";
import VehiclePager from "components/VehiclePager";
import EmployeeVehicleCard from "components/VehicleCard/employee";
import EmployeeVehicleListTable from "components/Tables/VehicleListTable/employee";
import EmployeeVehiclesListTableRow from "components/Tables/VehicleListTable/VehicleListTableRow/employee/index";
import VehicleToolbar from "components/VehicleToolbar";
import AddToWatchlistButton from "components/buttons/AddToWatchlist";
import AddToWatchListModals from "components/AddToWatchListModals";
import serviceVehicles from "services/vehicles";
import vehicleRepository from "repositories/vehicleRepository";
import userRepository from "repositories/userRepository";
import vehiclesStateStorageGateway from "gateways/storage/vehiclesStateStorage";

import s from "./index.module.scss";
import { useState } from "react";

const Vehicles = () => {
  const [isOpenAddToWatchListModals, setIsOpenAddToWatchListModals] =
    useState(false);

  const isAddToWatchlistButtonVisible = useSelector(
    (state) => state.vehicles.selectedVehicleIds.length > 0
  );

  return (
    <>
      <div className={s["vehicles"]}>
        <DefaultVehiclesFilters
          vehicleRepository={vehicleRepository}
          vehicleService={serviceVehicles}
          userRepository={userRepository}
        />
        <VehicleToolbar
          canSelectAll
          vehicleRepository={vehicleRepository}
          vehicleStateStorageGateway={vehiclesStateStorageGateway}
        />
        <VehiclePager
          vehicleService={serviceVehicles}
          vehicleRepository={vehicleRepository}
          baseUrl="/employee/vehicles/"
          cardComponent={EmployeeVehicleCard}
          tableComponent={EmployeeVehicleListTable}
          rowComponent={EmployeeVehiclesListTableRow}
        />
        <AddToWatchlistButton
          className={
            s["add-to-watch-list-button"] +
            (isAddToWatchlistButtonVisible
              ? " " + s["add-to-watch-list-button--visible"]
              : "")
          }
          backgroundColorClassName={s["add-to-watch-list-button-background"]}
          contentColorClassName={s["add-to-watch-list-button-content"]}
          onClick={() => setIsOpenAddToWatchListModals(true)}
        />
      </div>
      <AddToWatchListModals
        isOpen={isOpenAddToWatchListModals}
        onClose={() => setIsOpenAddToWatchListModals(false)}
      />
    </>
  );
};

export default Vehicles;
