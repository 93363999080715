import { createSlice } from "@reduxjs/toolkit";
import utilConstants from "utils/constants";
import utilHelpers from "utils/helpers";
import vehiclesStateStorageGateway from "gateways/storage/vehiclesStateStorage";

const initialSearchAndFiltersState =
  vehiclesStateStorageGateway.getSearchAndFiltersState();
const isGridView = vehiclesStateStorageGateway.getIsGridView();

const getDefaultIsRegularSources = () => true;

const getIsRegularSources = () =>
  typeof initialSearchAndFiltersState.filters.isRegularSources === "boolean"
    ? initialSearchAndFiltersState.filters.isRegularSources
    : getDefaultIsRegularSources();

const getDefaultLastAccessedVehicleId = () => "";

const getDefaultLastFilterApplyingTimestamp = () =>
  utilHelpers.getDateInTimestamp();

const getDefaultZipCode = () => "";

const getDefaultDistanceRange = () =>
  utilConstants.distanceFilter.defaultDistance;

const getDefaultSortingField = () =>
  utilConstants.sortingVehiclesOptions[0].value.field;

const getDefaultSortingOrder = () =>
  utilConstants.sortingVehiclesOptions[0].value.order;

const getDefaultTransmissionType = () =>
  utilConstants.vehicleTransmissionOptions[0].value;

const getDefaultFuelType = () => utilConstants.vehicleFuelOptions[0].value;

const getDefaultProgressState = () =>
  utilConstants.vehicleProgressStateOptions[0].value;

const getDefaultYearFrom = () =>
  utilConstants.filtersVehiclesYearOptions[
    utilConstants.filtersVehiclesYearOptions.length - 1
  ].value;

const getDefaultYearTo = () =>
  utilConstants.filtersVehiclesYearOptions[0].value;

const getDefaultMileageFrom = () => "";

const getDefaultMileageTo = () => "";

const getDefaultPriceFrom = () => "";

const getDefaultPriceTo = () => "";

const getDefaultSources = () =>
  getIsRegularSources()
    ? Object.keys(utilConstants.regularSources)
    : Object.keys(utilConstants.powersportsSources);

const getDefaultColors = () => [];

const getDefaultRequiredValues = () => [];

const getDefaultIncludeValues = () => [];

const getDefaultSearchText = () => "";

const initialState = {
  page: 0,
  pageSize: 60,
  pageCount: 1,
  isLoading: true,
  isGridView: isGridView !== null ? JSON.parse(isGridView) : true,
  vehicles: [],
  selectedVehicleIds: [],
  lastAccessedVehicleId: getDefaultLastAccessedVehicleId(),
  lastFilterApplyingTimestamp: getDefaultLastFilterApplyingTimestamp(),
  sorting: {
    field:
      initialSearchAndFiltersState.sorting.field || getDefaultSortingField(),
    order:
      initialSearchAndFiltersState.sorting.order || getDefaultSortingOrder(),
  },
  zipCode: initialSearchAndFiltersState.zipCode || getDefaultZipCode(),
  filters: {
    distanceRange:
      isNaN(initialSearchAndFiltersState.filters.distanceRange) ||
      initialSearchAndFiltersState.filters.distanceRange === null
        ? getDefaultDistanceRange()
        : Number(initialSearchAndFiltersState.filters.distanceRange),
    transmissionType:
      initialSearchAndFiltersState.filters.transmissionType ||
      getDefaultTransmissionType(),
    fuelType:
      initialSearchAndFiltersState.filters.fuelType || getDefaultFuelType(),
    progressState:
      initialSearchAndFiltersState.filters.progressState ||
      getDefaultProgressState(),
    year: {
      from:
        Number(initialSearchAndFiltersState.filters.year.from) ||
        getDefaultYearFrom(),
      to:
        Number(initialSearchAndFiltersState.filters.year.to) ||
        getDefaultYearTo(),
    },
    mileage: {
      from:
        Number(initialSearchAndFiltersState.filters.mileage.from) ||
        getDefaultMileageFrom(),
      to:
        Number(initialSearchAndFiltersState.filters.mileage.to) ||
        getDefaultMileageTo(),
    },
    price: {
      from:
        Number(initialSearchAndFiltersState.filters.price.from) ||
        getDefaultPriceFrom(),
      to:
        Number(initialSearchAndFiltersState.filters.price.to) ||
        getDefaultPriceTo(),
    },
    isRegularSources: getIsRegularSources(),
    sources: initialSearchAndFiltersState.filters.localSources
      ? initialSearchAndFiltersState.filters.localSources.split(",")
      : getDefaultSources(),
    colors: initialSearchAndFiltersState.filters.localColors
      ? initialSearchAndFiltersState.filters.localColors.split(",")
      : getDefaultColors(),
    requiredValues: initialSearchAndFiltersState.filters.localRequiredValues
      ? initialSearchAndFiltersState.filters.localRequiredValues.split(",")
      : getDefaultRequiredValues(),
    includeValues: initialSearchAndFiltersState.filters.localIncludeValues
      ? initialSearchAndFiltersState.filters.localIncludeValues.split(",")
      : getDefaultIncludeValues(),
  },
  searchText:
    initialSearchAndFiltersState.searchString || getDefaultSearchText(),
  stats: [],
};

export const vehiclesSlice = createSlice({
  name: "vehicles",
  initialState,
  reducers: {
    resetVehiclesStateWithFilters: (state) => {
      state.page = 0;
      state.pageCount = 1;
      state.isLoading = true;
      state.isGridView = true;
      state.vehicles = [];
      state.selectedVehicleIds = [];
      state.lastAccessedVehicleId = getDefaultLastAccessedVehicleId();
      state.lastFilterApplyingTimestamp =
        getDefaultLastFilterApplyingTimestamp();
      state.sorting.field = getDefaultSortingField();
      state.sorting.order = getDefaultSortingOrder();
      state.zipCode = getDefaultZipCode();
      state.filters.transmissionType = getDefaultTransmissionType();
      state.filters.fuelType = getDefaultFuelType();
      state.filters.progressState = getDefaultProgressState();
      state.filters.distanceRange = getDefaultDistanceRange();
      state.filters.year.from = getDefaultYearFrom();
      state.filters.year.to = getDefaultYearTo();
      state.filters.mileage.from = getDefaultMileageFrom();
      state.filters.mileage.to = getDefaultMileageTo();
      state.filters.price.from = getDefaultPriceFrom();
      state.filters.price.to = getDefaultPriceTo();
      state.filters.isRegularSources = getDefaultIsRegularSources();
      state.filters.sources = getDefaultSources();
      state.filters.colors = getDefaultColors();
      state.filters.requiredValues = getDefaultRequiredValues();
      state.filters.includeValues = getDefaultIncludeValues();
      state.searchText = getDefaultSearchText();
      state.stats = [];
    },
    resetVehiclesStateWithoutFilters: (state) => {
      state.page = 1;
      state.pageCount = 1;
      state.isLoading = true;
      state.vehicles = [];
      state.selectedVehicleIds = [];
      state.lastAccessedVehicleId = getDefaultLastAccessedVehicleId();
      state.stats = [];
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setPageCount: (state, action) => {
      state.pageCount = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setIsGridView: (state, action) => {
      state.isGridView = action.payload;
    },
    setVehicles: (state, action) => {
      state.vehicles = action.payload;
    },
    setVehicleFields: (state, action) => {
      state.vehicles = state.vehicles.map((vehicle) => {
        if (vehicle.id === action.payload.id) {
          return {
            ...vehicle,
            ...action.payload.updatedFields,
            work_in_progress: true,
          };
        }
        return vehicle;
      });
    },
    setVehicleWorkInProgress: (state, action) => {
      state.vehicles = state.vehicles.map((vehicle) => {
        if (vehicle.id === action.payload.id) {
          return {
            ...vehicle,
            work_in_progress: true,
          };
        }
        return vehicle;
      });
    },
    setLastAccessedVehicleId: (state, action) => {
      state.lastAccessedVehicleId = action.payload;
    },
    setLastFilterApplyingTimestamp: (state, action) => {
      state.lastFilterApplyingTimestamp = action.payload;
    },
    setSorting: (state, action) => {
      state.sorting = action.payload;
    },
    setZipCode: (state, action) => {
      state.zipCode = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setSearchText: (state, action) => {
      state.searchText = action.payload;
    },
    setStats: (state, action) => {
      state.stats = action.payload;
    },
    selectAllVehicles: (state, action) => {
      state.selectedVehicleIds = action.payload;
    },
    selectVehicle: (state, action) => {
      state.selectedVehicleIds = [...state.selectedVehicleIds, action.payload];
    },
    unselectVehicle: (state, action) => {
      state.selectedVehicleIds = state.selectedVehicleIds.filter(
        (vehicleId) => vehicleId !== action.payload
      );
    },
    unselectAllVehicles: (state, action) => {
      state.selectedVehicleIds = [];
    },
  },
});

export const {
  resetVehiclesStateWithFilters,
  resetVehiclesStateWithoutFilters,
  setPage,
  setPageCount,
  setIsLoading,
  setIsGridView,
  setVehicles,
  setVehicleFields,
  setVehicleWorkInProgress,
  setLastAccessedVehicleId,
  setLastFilterApplyingTimestamp,
  setSorting,
  setZipCode,
  setFilters,
  setSearchText,
  setSource,
  setStats,
  selectAllVehicles,
  selectVehicle,
  unselectAllVehicles,
  unselectVehicle,
} = vehiclesSlice.actions;

export default vehiclesSlice.reducer;
