import s from "./index.module.scss";

const ReSaveSearchButton = ({ onClick = () => {} }) => {
  return (
    <div className={s["save-search"]}>
      <button className={s["save-search__btn"]} onClick={onClick}>
        re-save search
      </button>
    </div>
  );
};

export default ReSaveSearchButton;
