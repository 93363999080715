const getIsGridView = () => localStorage.getItem("isHotlistGridView");

const saveIsGridView = (value) =>
  localStorage.setItem("isHotlistGridView", value);

const removeIsGridView = () => localStorage.removeItem("isHotlistGridView");

const hotlistStateStorageGateway = {
  getIsGridView,
  saveIsGridView,
  removeIsGridView,
};

export default hotlistStateStorageGateway;
