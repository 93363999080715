import { useState } from "react";
import DeleteUserModal from "components/modals/DeleteUserModal";
import CustomerDetailsModal from "components/modals/CustomerDetailsModal";
import EditCustomerModal from "components/modals/EditCustomerModal";
import Avatar from "components/Avatar";
import iconPen from "assets/icons/pen.svg";
import iconCross from "assets/icons/cross.svg";
import s from "./index.module.scss";

const CustomersTable = ({ customers = {}, salesReps = [], role = "" }) => {
  const [isOpenUpdateModal, setIsOpenUpdateModal] = useState(false);
  const [customerToUpdate, setCustomerToUpdate] = useState({});
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [customerToDelete, setCustomerToDelete] = useState({});
  const [isOpenDetailsModal, setIsOpenDetailsModal] = useState(false);
  const [customerDetails, setCustomerDetails] = useState({});

  const handleClickCustomerDetails = (e, user) => {
    if (e.target.classList.contains(s["customers-table__actions-icon"])) {
      return;
    }
    setCustomerDetails(user);
    setIsOpenDetailsModal(true);
  };

  const handleClickUpdateCustomer = (user) => {
    setCustomerToUpdate(user);
    setIsOpenUpdateModal(true);
  };

  const handleClickDeleteCustomer = (user) => {
    setCustomerToDelete(user);
    setIsOpenDeleteModal(true);
  };

  return (
    <div className={s["customers-table"]}>
      <div
        className={
          s["customers-table__row"] + " " + s["customers-table__row--first"]
        }
      >
        <p className={s["customers-table__title"]}>User</p>
        <p className={s["customers-table__title"]}>Email</p>
        <p className={s["customers-table__title"]}>Actions</p>
      </div>
      {customers.map((customer) => (
        <div
          className={s["customers-table__row"]}
          key={customer.id}
          onClick={(e) => handleClickCustomerDetails(e, customer)}
        >
          <Avatar
            className={s["customers-table__avatar"]}
            avatar={customer.profile.avatar}
          />

          <p
            className={
              s["customers-table__text"] +
              " " +
              s["customers-table__text--first"]
            }
          >
            {customer.profile.first_name} {customer.profile.last_name}
          </p>
          <p className={s["customers-table__text"]}>{customer.email}</p>
          <div className={s["customers-table__actions-wrapper"]}>
            <button
              className={s["customers-table__actions-icon"]}
              alt="Edit"
              onClick={() => handleClickUpdateCustomer(customer)}
            >
              <img src={iconPen} alt="Edit" />
            </button>
            <button
              className={s["customers-table__actions-icon"]}
              alt="Delete"
              onClick={() => handleClickDeleteCustomer(customer)}
            >
              <img src={iconCross} alt="Delete" />
            </button>
          </div>
        </div>
      ))}
      <EditCustomerModal
        isOpen={isOpenUpdateModal}
        salesReps={salesReps}
        onClose={() => setIsOpenUpdateModal(false)}
        defaultValues={{
          defaultZipCode: customerToUpdate.profile?.zip_code,
          defaultFirstName: customerToUpdate.profile?.first_name,
          defaultLastName: customerToUpdate.profile?.last_name,
          defaultEmail: customerToUpdate.email,
          defaultPhoneNumber: customerToUpdate.profile?.phone_number,
          defaultSalesRep: customerToUpdate.profile?.employee_id,
          defaultAvatar: customerToUpdate.profile?.avatar,
        }}
        customerId={customerToUpdate.id}
      />
      <DeleteUserModal
        isOpen={isOpenDeleteModal}
        role={role}
        onClose={() => setIsOpenDeleteModal(false)}
        profile={customerToDelete.profile}
        userId={customerToDelete.id}
      />
      <CustomerDetailsModal
        salesReps={salesReps}
        isOpen={isOpenDetailsModal}
        onClose={() => setIsOpenDetailsModal(false)}
        customer={customerDetails}
      />
    </div>
  );
};

export default CustomersTable;
