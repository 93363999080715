import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Tabs from "components/Tabs";
import AdditionalAttributes from "components/AdditionalAttributes";
import Loader from "components/Loader";
import Activity from "components/Activity";
import Seller from "components/Seller";
import InfoFromSeller from "components/InfoFromSeller";

import serviceVehicles from "services/vehicles";

import utilFormatter from "utils/formatter";
import utilGenerators from "utils/generators";
import utilConstants from "utils/constants";
import utilHelpers from "utils/helpers";

import { resetSeller } from "slicers/conversation";
import { resetActivities } from "slicers/activities";

import s from "./index.module.scss";

const VehicleInfo = ({ vehicle = {}, onVehicleEdited = () => {} }) => {
  const userRole = useSelector((state) => state.user.role);
  const dispatch = useDispatch();

  const tabs = utilGenerators.vehicleInfoTabs(vehicle, userRole);
  const [localVehicle, setLocalVehicle] = useState(vehicle);
  const [currentTab, setCurrentTab] = useState(tabs[0].value);
  const [isOthersConversations, setIsOthersConversations] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [localInfoFromSeller, setLocalInfoFromSeller] = useState(null);

  useEffect(() => setCurrentTab(tabs[0].value), [vehicle.id]);

  useEffect(() => {
    dispatch(resetSeller());
    dispatch(resetActivities());
  }, [vehicle.id]);

  useEffect(() => {
    if (Object.keys(vehicle).length === 0) return;
    if (!vehicle.id) return;

    setLocalVehicle(vehicle);
  }, [vehicle]);

  useEffect(() => {
    if (Object.keys(localVehicle).length === 0) return;
    if (!localVehicle.id) return;

    setLocalInfoFromSeller(infoFromSeller);
  }, [localVehicle]);

  useEffect(() => {
    if (Object.keys(vehicle).length === 0) return;

    const tabsTitles = tabs.map((tab) => tab.title);
    if (!tabsTitles.includes("Seller")) return;
    if (!vehicle.id) return;

    setIsLoading(true);
    serviceVehicles
      .getConversations(vehicle.id)
      .then((conversations) => {
        if (conversations.length > 0) {
          setIsOthersConversations(true);
          return;
        }
        setIsOthersConversations(false);
        return;
      })
      .catch(() => {
        setIsOthersConversations(false);
      })
      .finally(() => setIsLoading(false));
  }, [vehicle.id]);

  useEffect(() => {
    if (Object.keys(vehicle).length === 0) return;

    const tabsTitles = tabs.map((tab) => tab.title);

    if (!tabsTitles.includes("Activity")) return;
    if (!vehicle.id) return;

    const fetchActivityStats = () =>
      serviceVehicles.getActivityStats(vehicle.id);
    fetchActivityStats();

    const interval = setInterval(fetchActivityStats, 1000 * 60);

    return () => clearInterval(interval);
  }, [vehicle.id]);

  useEffect(() => {
    if (!(userRole === utilConstants.roles.employee.name)) return;
    if (!vehicle.id) return;

    const fetchVehicleDetails = () =>
      serviceVehicles.get(vehicle.id).then((updatedVehicle) => {
        if (utilHelpers.isVehiclesEqual(vehicle, updatedVehicle)) return;
        onVehicleEdited(updatedVehicle);
      });

    const interval = setInterval(fetchVehicleDetails, 1000 * 60);

    return () => clearInterval(interval);
  }, [vehicle]);

  const handleChangeTab = (tab) => setCurrentTab(tab);

  const {
    is_accidents: hasAccidents = false,
    is_smoked: isSmoked = false,
    is_price_negotiable: isPriceNegotiable = false,
    accidents_details: accidentsDetails = "",
    money_owed_on_car: moneyOwedOnCar = "",
    damages_on_car: damagesOnCar = "",
    reason_to_sell: reasonToSell = "",
    when_to_sell: urgencyOfSale = "",
    build: {
      body_type: bodyType = "",
      drivetrain = "",
      transmission = "",
      engine = "",
      engine_size: engineSize = "",
      engine_type: engineType = "",
      engine_classification: engineClassification = "",
      engine_configuration: engineConfiguration = "",
      fuel_type: fuelType = "",
      doors = "",
      std_seating: stdSeating = "",
      trim = "",
      status = "",
      number_of_hours: numberOfHours = "",
      trailer = "",
      length = "",
      hull_design: hullDesign = "",
      steering = "",
      passengers = "",
      weight = "",
    } = {},
    dealer = {},
    lead = {},
    price = null,
    dealer_description: rootDealerDescription = "",
    max_price: maxPrice = "",
    exterior_color: exteriorColor = "",
    interior_color: interiorColor = "",
    vin = "",
    inventory_type: inventoryType = "",
    vdp_url: vdpUrl = "",
    province = "",
    created_at: createdAt = null,
    id: vehicleId = "",
    source: vehicleSource = "",
    delisted = false,
    type,
  } = localVehicle;

  const {
    website = "",
    phone_number: phoneNumber = "",
    name: dealerName = "",
    dealer_description: innerDealerDescription = "",
  } = dealer || {};

  const dealerDescription = innerDealerDescription || rootDealerDescription;

  const { name: leadName = "", email = "", phone = "" } = lead || {};

  const seller = {
    vdpUrl,
    website,
    phoneNumber,
    dealerName,
    vehicleId,
    vehicleSource,
    delisted,
  };

  const infoFromSeller = {
    hasAccidents,
    accidentsDetails,
    vin,
    trim,
    moneyOwedOnCar,
    damagesOnCar,
    reasonToSell,
    urgencyOfSale,
    isSmoked,
    isPriceNegotiable,
  };

  const handleChangeHasAccidents = (value) => {
    setLocalInfoFromSeller({
      ...localInfoFromSeller,
      hasAccidents: value,
    });

    setIsLoading(true);
    serviceVehicles
      .updateVehicle(vehicleId, { is_accidents: value })
      .then(() =>
        onVehicleEdited({
          ...localVehicle,
          is_accidents: value,
        })
      )
      .catch(() => {})
      .finally(() => setIsLoading(false));
  };

  const handleChangeIsSmoked = (value) => {
    setLocalInfoFromSeller({
      ...localInfoFromSeller,
      isSmoked: value,
    });
    setIsLoading(true);
    serviceVehicles
      .updateVehicle(vehicleId, { is_smoked: value })
      .then(() =>
        onVehicleEdited({
          ...localVehicle,
          is_smoked: value,
        })
      )
      .catch(() => {})
      .finally(() => setIsLoading(false));
  };

  const handleChangeIsPriceNegotiable = (value) => {
    setLocalInfoFromSeller({
      ...localInfoFromSeller,
      isPriceNegotiable: value,
    });

    setIsLoading(true);
    serviceVehicles
      .updateVehicle(vehicleId, { is_price_negotiable: value })
      .then(() =>
        onVehicleEdited({
          ...localVehicle,
          is_price_negotiable: value,
        })
      )
      .catch(() => {})
      .finally(() => setIsLoading(false));
  };

  const handleChangeTrim = (value) => {
    setLocalInfoFromSeller({
      ...localInfoFromSeller,
      trim: value,
    });

    setIsLoading(true);
    serviceVehicles
      .updateVehicle(vehicleId, { trim: value ? value : null })
      .then(() =>
        onVehicleEdited({
          ...localVehicle,
          build: {
            ...localVehicle.build,
            trim: value,
          },
        })
      )
      .catch(() => {})
      .finally(() => setIsLoading(false));
  };

  const handleChangeVin = (value) => {
    setLocalInfoFromSeller({
      ...localInfoFromSeller,
      vin: value,
    });

    setIsLoading(true);
    serviceVehicles
      .updateVehicle(vehicleId, { vin: value ? value : null })
      .then(() =>
        onVehicleEdited({
          ...localVehicle,
          vin: value,
        })
      )
      .catch(() => {})
      .finally(() => setIsLoading(false));
  };

  const handleChangeAccidentsDetails = (value) => {
    setLocalInfoFromSeller({
      ...localInfoFromSeller,
      accidentsDetails: value,
    });

    setIsLoading(true);
    serviceVehicles
      .updateVehicle(vehicleId, { accidents_details: value ? value : null })
      .then(() =>
        onVehicleEdited({
          ...localVehicle,
          accidents_details: value,
        })
      )
      .catch(() => {})
      .finally(() => setIsLoading(false));
  };

  const handleChangeMoneyOwedOnCar = (value) => {
    setLocalInfoFromSeller({
      ...localInfoFromSeller,
      moneyOwedOnCar: value,
    });

    setIsLoading(true);
    serviceVehicles
      .updateVehicle(vehicleId, { money_owed_on_car: value ? value : null })
      .then(() =>
        onVehicleEdited({
          ...localVehicle,
          money_owed_on_car: value,
        })
      )
      .catch(() => {})
      .finally(() => setIsLoading(false));
  };

  const handleChangeDamagesOnCar = (value) => {
    setLocalInfoFromSeller({
      ...localInfoFromSeller,
      damagesOnCar: value,
    });

    setIsLoading(true);
    serviceVehicles
      .updateVehicle(vehicleId, { damages_on_car: value ? value : null })
      .then(() =>
        onVehicleEdited({
          ...localVehicle,
          damages_on_car: value,
        })
      )
      .catch(() => {})
      .finally(() => setIsLoading(false));
  };

  const handleChangeReasonToSell = (value) => {
    setLocalInfoFromSeller({
      ...localInfoFromSeller,
      reasonToSell: value,
    });

    setIsLoading(true);
    serviceVehicles
      .updateVehicle(vehicleId, { reason_to_sell: value ? value : null })
      .then(() =>
        onVehicleEdited({
          ...localVehicle,
          reason_to_sell: value,
        })
      )
      .catch(() => {})
      .finally(() => setIsLoading(false));
  };

  const handleChangeUrgencyOfSale = (value) => {
    setLocalInfoFromSeller({
      ...localInfoFromSeller,
      urgencyOfSale: value,
    });

    setIsLoading(true);
    serviceVehicles
      .updateVehicle(vehicleId, { when_to_sell: value ? value : null })
      .then(() => {
        onVehicleEdited({
          ...localVehicle,
          when_to_sell: value,
        });
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  };

  return (
    <div className={s["vehicle-info"]}>
      <div className={s["vehicle-info__details"]}>
        <Tabs
          tabs={tabs}
          currentTabValue={currentTab}
          onChange={handleChangeTab}
        />
        {currentTab === "overview" && (
          <div className={s[`vehicle-info__details-${currentTab}-wrapper`]}>
            <div className={s[`vehicle-info__details-${currentTab}`]}>
              {province && (
                <div className={s["vehicle-info__details-overview-item"]}>
                  <p className={s["vehicle-info__details-text"]}>Province</p>
                  <p
                    className={
                      s["vehicle-info__details-text"] +
                      " " +
                      s["vehicle-info__details-text--bold"]
                    }
                  >
                    {province}
                  </p>
                </div>
              )}
              {maxPrice && price && (
                <div className={s["vehicle-info__details-overview-item"]}>
                  <p className={s["vehicle-info__details-text"]}>Price Range</p>
                  <p
                    className={
                      s["vehicle-info__details-text"] +
                      " " +
                      s["vehicle-info__details-text--bold"]
                    }
                  >
                    {"$" + price + " - $" + maxPrice}
                  </p>
                </div>
              )}
              {createdAt && (
                <div className={s["vehicle-info__details-overview-item"]}>
                  <p className={s["vehicle-info__details-text"]}>Date</p>
                  <p
                    className={
                      s["vehicle-info__details-text"] +
                      " " +
                      s["vehicle-info__details-text--bold"]
                    }
                  >
                    {utilFormatter.prettyDate(createdAt)}
                  </p>
                </div>
              )}
              {(inventoryType || status) && (
                <div className={s["vehicle-info__details-overview-item"]}>
                  <p className={s["vehicle-info__details-text"]}>Status</p>
                  <p
                    className={
                      s["vehicle-info__details-text"] +
                      " " +
                      s["vehicle-info__details-text--bold"]
                    }
                  >
                    {inventoryType || status}
                  </p>
                </div>
              )}
              {bodyType && (
                <div className={s["vehicle-info__details-overview-item"]}>
                  <p className={s["vehicle-info__details-text"]}>Body type</p>
                  <p
                    className={
                      s["vehicle-info__details-text"] +
                      " " +
                      s["vehicle-info__details-text--bold"]
                    }
                  >
                    {bodyType}
                  </p>
                </div>
              )}
              {hullDesign && (
                <div className={s["vehicle-info__details-overview-item"]}>
                  <p className={s["vehicle-info__details-text"]}>Hull design</p>
                  <p
                    className={
                      s["vehicle-info__details-text"] +
                      " " +
                      s["vehicle-info__details-text--bold"]
                    }
                  >
                    {hullDesign}
                  </p>
                </div>
              )}
              {length && (
                <div className={s["vehicle-info__details-overview-item"]}>
                  <p className={s["vehicle-info__details-text"]}>Length</p>
                  <p
                    className={
                      s["vehicle-info__details-text"] +
                      " " +
                      s["vehicle-info__details-text--bold"]
                    }
                  >
                    {length}
                  </p>
                </div>
              )}
              {weight && (
                <div className={s["vehicle-info__details-overview-item"]}>
                  <p className={s["vehicle-info__details-text"]}>Weight</p>
                  <p
                    className={
                      s["vehicle-info__details-text"] +
                      " " +
                      s["vehicle-info__details-text--bold"]
                    }
                  >
                    {weight}
                  </p>
                </div>
              )}
              {exteriorColor && (
                <div className={s["vehicle-info__details-overview-item"]}>
                  <p className={s["vehicle-info__details-text"]}>
                    Exterior color
                  </p>
                  <p
                    className={
                      s["vehicle-info__details-text"] +
                      " " +
                      s["vehicle-info__details-text--bold"]
                    }
                  >
                    {exteriorColor}
                  </p>
                </div>
              )}
              {interiorColor && (
                <div className={s["vehicle-info__details-overview-item"]}>
                  <p className={s["vehicle-info__details-text"]}>
                    Interior color
                  </p>
                  <p
                    className={
                      s["vehicle-info__details-text"] +
                      " " +
                      s["vehicle-info__details-text--bold"]
                    }
                  >
                    {interiorColor}
                  </p>
                </div>
              )}
              {drivetrain && (
                <div className={s["vehicle-info__details-overview-item"]}>
                  <p className={s["vehicle-info__details-text"]}>Drivetrain</p>
                  <p
                    className={
                      s["vehicle-info__details-text"] +
                      " " +
                      s["vehicle-info__details-text--bold"]
                    }
                  >
                    {drivetrain}
                  </p>
                </div>
              )}
              {steering && (
                <div className={s["vehicle-info__details-overview-item"]}>
                  <p className={s["vehicle-info__details-text"]}>Steering</p>
                  <p
                    className={
                      s["vehicle-info__details-text"] +
                      " " +
                      s["vehicle-info__details-text--bold"]
                    }
                  >
                    {steering}
                  </p>
                </div>
              )}
              {vin && (
                <div className={s["vehicle-info__details-overview-item"]}>
                  <p className={s["vehicle-info__details-text"]}>VIN</p>
                  <p
                    className={
                      s["vehicle-info__details-text"] +
                      " " +
                      s["vehicle-info__details-text--bold"]
                    }
                  >
                    {vin}
                  </p>
                </div>
              )}
              {transmission && (
                <div className={s["vehicle-info__details-overview-item"]}>
                  <p className={s["vehicle-info__details-text"]}>
                    Transmission
                  </p>
                  <p
                    className={
                      s["vehicle-info__details-text"] +
                      " " +
                      s["vehicle-info__details-text--bold"]
                    }
                  >
                    {transmission}
                  </p>
                </div>
              )}
              {engine && (
                <div className={s["vehicle-info__details-overview-item"]}>
                  <p className={s["vehicle-info__details-text"]}>Engine</p>
                  <p
                    className={
                      s["vehicle-info__details-text"] +
                      " " +
                      s["vehicle-info__details-text--bold"]
                    }
                  >
                    {engine}
                  </p>
                </div>
              )}
              {engineSize && (
                <div className={s["vehicle-info__details-overview-item"]}>
                  <p className={s["vehicle-info__details-text"]}>Engine size</p>
                  <p
                    className={
                      s["vehicle-info__details-text"] +
                      " " +
                      s["vehicle-info__details-text--bold"]
                    }
                  >
                    {engineSize}
                  </p>
                </div>
              )}
              {engineType && (
                <div className={s["vehicle-info__details-overview-item"]}>
                  <p className={s["vehicle-info__details-text"]}>Engine type</p>
                  <p
                    className={
                      s["vehicle-info__details-text"] +
                      " " +
                      s["vehicle-info__details-text--bold"]
                    }
                  >
                    {engineType}
                  </p>
                </div>
              )}
              {engineClassification && (
                <div className={s["vehicle-info__details-overview-item"]}>
                  <p className={s["vehicle-info__details-text"]}>
                    Engine classification
                  </p>
                  <p
                    className={
                      s["vehicle-info__details-text"] +
                      " " +
                      s["vehicle-info__details-text--bold"]
                    }
                  >
                    {engineClassification}
                  </p>
                </div>
              )}
              {engineConfiguration && (
                <div className={s["vehicle-info__details-overview-item"]}>
                  <p className={s["vehicle-info__details-text"]}>
                    Engine configuration
                  </p>
                  <p
                    className={
                      s["vehicle-info__details-text"] +
                      " " +
                      s["vehicle-info__details-text--bold"]
                    }
                  >
                    {engineConfiguration}
                  </p>
                </div>
              )}
              {fuelType && (
                <div className={s["vehicle-info__details-overview-item"]}>
                  <p className={s["vehicle-info__details-text"]}>Fuel type</p>
                  <p
                    className={
                      s["vehicle-info__details-text"] +
                      " " +
                      s["vehicle-info__details-text--bold"]
                    }
                  >
                    {fuelType}
                  </p>
                </div>
              )}
              {numberOfHours && (
                <div className={s["vehicle-info__details-overview-item"]}>
                  <p className={s["vehicle-info__details-text"]}>
                    Number of hours
                  </p>
                  <p
                    className={
                      s["vehicle-info__details-text"] +
                      " " +
                      s["vehicle-info__details-text--bold"]
                    }
                  >
                    {numberOfHours}
                  </p>
                </div>
              )}
              {trailer && (
                <div className={s["vehicle-info__details-overview-item"]}>
                  <p className={s["vehicle-info__details-text"]}>Trailer</p>
                  <p
                    className={
                      s["vehicle-info__details-text"] +
                      " " +
                      s["vehicle-info__details-text--bold"]
                    }
                  >
                    {trailer}
                  </p>
                </div>
              )}
              {doors && (
                <div className={s["vehicle-info__details-overview-item"]}>
                  <p className={s["vehicle-info__details-text"]}>Doors</p>
                  <p
                    className={
                      s["vehicle-info__details-text"] +
                      " " +
                      s["vehicle-info__details-text--bold"]
                    }
                  >
                    {doors}
                  </p>
                </div>
              )}
              {stdSeating && (
                <div className={s["vehicle-info__details-overview-item"]}>
                  <p className={s["vehicle-info__details-text"]}>Seats</p>
                  <p
                    className={
                      s["vehicle-info__details-text"] +
                      " " +
                      s["vehicle-info__details-text--bold"]
                    }
                  >
                    {stdSeating}
                  </p>
                </div>
              )}
              {passengers && (
                <div className={s["vehicle-info__details-overview-item"]}>
                  <p className={s["vehicle-info__details-text"]}>Passengers</p>
                  <p
                    className={
                      s["vehicle-info__details-text"] +
                      " " +
                      s["vehicle-info__details-text--bold"]
                    }
                  >
                    {passengers}
                  </p>
                </div>
              )}
              {dealerDescription && (
                <div className={s["vehicle-info__details-overview-item"]}>
                  <p className={s["vehicle-info__details-text"]}>Description</p>
                  <p
                    className={
                      s["vehicle-info__details-text"] +
                      " " +
                      s["vehicle-info__details-text--bold"]
                    }
                  >
                    {dealerDescription}
                  </p>
                </div>
              )}
              {localInfoFromSeller &&
                userRole === utilConstants.roles.employee.name && (
                  <InfoFromSeller
                    infoFromSeller={localInfoFromSeller}
                    onHasAccidentsChanged={handleChangeHasAccidents}
                    onIsSmokedChanged={handleChangeIsSmoked}
                    onIsPriceNegotiableChanged={handleChangeIsPriceNegotiable}
                    onAccidentsDetailsChanged={handleChangeAccidentsDetails}
                    onVINChanged={handleChangeVin}
                    onTrimChanged={handleChangeTrim}
                    onMoneyOwedOnCarChanged={handleChangeMoneyOwedOnCar}
                    onDamagesOnCarChanged={handleChangeDamagesOnCar}
                    onReasonToSellChanged={handleChangeReasonToSell}
                    onUrgencyOfSaleChanged={handleChangeUrgencyOfSale}
                  />
                )}
              <AdditionalAttributes
                attributes={vehicle.additional_attributes}
              />
            </div>
          </div>
        )}

        {currentTab === "dealer" && (
          <>
            <div className={s[`vehicle-info__details-${currentTab}`]}>
              <Seller
                seller={seller}
                vehicleType={type}
                isOthersConversations={isOthersConversations}
              />
            </div>
          </>
        )}
        {currentTab === "lead" && (
          <div className={s[`vehicle-info__details-${currentTab}`]}>
            {leadName && (
              <div className={s["vehicle-info__details-item"]}>
                <p className={s["vehicle-info__details-text"]}>Name</p>
                <p
                  className={
                    s["vehicle-info__details-text"] +
                    " " +
                    s["vehicle-info__details-text--bold"]
                  }
                >
                  {leadName}
                </p>
              </div>
            )}
            {email && (
              <div className={s["vehicle-info__details-item"]}>
                <p className={s["vehicle-info__details-text"]}>Email</p>
                <a
                  className={
                    s["vehicle-info__details-text"] +
                    " " +
                    s["vehicle-info__details-text--bold"] +
                    " " +
                    s["vehicle-info__details-text--link"]
                  }
                  href={"mailto:" + email}
                  rel="noreferrer"
                >
                  {email}
                </a>
              </div>
            )}
            {phone && (
              <div className={s["vehicle-info__details-item"]}>
                <p className={s["vehicle-info__details-text"]}>Phone</p>
                <p
                  className={
                    s["vehicle-info__details-text"] +
                    " " +
                    s["vehicle-info__details-text--bold"]
                  }
                >
                  {phone}
                </p>
              </div>
            )}
          </div>
        )}
        {currentTab === "activity" && (
          <div className={s[`vehicle-info__details-${currentTab}-wrapper`]}>
            <div className={s[`vehicle-info__details-${currentTab}`]}>
              <Activity vehicleId={vehicleId} vin={vin} />
            </div>
          </div>
        )}
      </div>
      <Loader isOpen={isLoading} />
    </div>
  );
};

export default VehicleInfo;
