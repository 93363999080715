const getDateInTimestamp = () => {
  return new Date().getTime();
};

const isVehiclesEqual = (currentVehicle, updatedVehicle) => {
  return JSON.stringify(currentVehicle) === JSON.stringify(updatedVehicle);
};

const util = {
  getDateInTimestamp,
  isVehiclesEqual,
};

export default util;
