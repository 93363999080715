import VehicleToolbar from "components/VehicleToolbar";
import VehiclePager from "components/VehiclePager";
import HotlistVehicleCard from "components/VehicleCard/hotlist";
import HotlistVehiclesListTableRow from "components/Tables/VehicleListTable/VehicleListTableRow/hotlist";
import ReloadPageButton from "components/buttons/ReloadPageButton";
import serviceHotlistVehicles from "services/hotlist";
import hotlistVehicleRepository from "repositories/hotlistVehicleRepository";
import hotlistStateStorageGateway from "gateways/storage/hotlistStateStorage";
import s from "./index.module.scss";
import HotlistVehicleListTable from "components/Tables/VehicleListTable/hotlist";

const Hotlist = () => {
  const handleClickReloadButton = () => {
    hotlistVehicleRepository.resetVehicles();
  };

  return (
    <div className={s["hotlist"]}>
      <div className={s["hotlist__title-wrapper"]}>
        <ReloadPageButton onClick={handleClickReloadButton} />
        <h1 className={s["hotlist__title"]}>Hotlist</h1>
        <VehicleToolbar
          vehicleRepository={hotlistVehicleRepository}
          vehicleStateStorageGateway={hotlistStateStorageGateway}
          canSaveSearch={false}
          canExportCsv={false}
        />
      </div>
      <VehiclePager
        vehicleService={serviceHotlistVehicles}
        vehicleRepository={hotlistVehicleRepository}
        baseUrl="/employee/hotlist/"
        cardComponent={HotlistVehicleCard}
        tableComponent={HotlistVehicleListTable}
        rowComponent={HotlistVehiclesListTableRow}
      />
    </div>
  );
};

export default Hotlist;
