import { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import FilledButton from "components/buttons/FilledButton";
import Modal from "components/Modal";
import Loader from "components/Loader";
import iconCrossBlack from "assets/icons/cross-black.svg";
import serviceUsers from "services/users";
import s from "./index.module.scss";

const UnassignCustomerModal = ({
  isOpen = false,
  firstName = "",
  lastName = "",
  onClose = () => {},
  customerId = "",
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const handleClose = () => {
    onClose();
  };

  const handleUnassignCustomer = async () => {
    setIsLoading(true);

    serviceUsers
      .unassignCustomer(customerId)
      .then(() => {
        handleClose();
        history.push("/employee/customers/my");
      })
      .catch(() => {
        toast.error("Something went wrong. Please try again");
        handleClose();
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Modal isOpen={isOpen}>
      <div className={s["unassign-customer-modal"]}>
        <img
          className={s["unassign-customer-modal__cross"]}
          src={iconCrossBlack}
          alt="Close"
          onClick={handleClose}
        />
        <p className={s["unassign-customer-modal__message"]}>
          Are you sure you want to unassign&nbsp;
          <span
            className={
              s["unassign-customer-modal__message"] +
              " " +
              s["unassign-customer-modal__message--bold"]
            }
          >
            {firstName} {lastName}
          </span>
          ?
        </p>
        <div className={s["unassign-customer-modal__button-wrapper"]}>
          <FilledButton
            className={s["unassign-customer-modal__button"]}
            onClick={handleUnassignCustomer}
          >
            Unassign
          </FilledButton>
        </div>
      </div>
      <Loader isOpen={isLoading} />
    </Modal>
  );
};

export default UnassignCustomerModal;
