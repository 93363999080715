import { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import iconAvatar from "assets/icons/avatar-placeholder.svg";
import iconSave from "assets/icons/icon-save.svg";
import iconSaveDisabled from "assets/icons/icon-save-disabled.svg";
import serviceVehicles from "services/vehicles";
import s from "./index.module.scss";

const AddComment = ({ vehicleId = "", onUpdate = () => {} }) => {
  const [value, setValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const avatarUrl = useSelector((state) => state.user.avatar);

  const isAbleToSave = !isLoading && value.trim();

  const textareaRef = useRef();

  const resize = () => {
    const textarea = textareaRef.current;

    if (!textarea) {
      return;
    }

    textarea.style.height = `max-content`;
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  useEffect(resize, [value]);

  const handleChange = (e) => setValue(e.target.value);

  const handleClickSave = async () => {
    if (!isAbleToSave) {
      return;
    }
    setIsLoading(true);
    await serviceVehicles.addComment(vehicleId, value.trim());
    const vehicle = await serviceVehicles.get(vehicleId);
    onUpdate(vehicle.activity);
    toast.success("Comment saved", {
      position: "bottom-right",
      autoClose: 2000,
      closeOnClick: true,
      pauseOnHover: true,
    });
    setIsLoading(false);
    setValue("");
  };

  return (
    <div className={s["comment-wrapper"]}>
      <img
        className={s["comment-avatar"]}
        src={avatarUrl ? avatarUrl.link : iconAvatar}
        alt="Profile avatar"
      />
      <div className={s["comment"]}>
        <textarea
          className={s["comment__textarea"]}
          placeholder="Your comment"
          value={value}
          onChange={handleChange}
          rows="1"
          ref={textareaRef}
        ></textarea>
        <img
          className={
            s["comment__icon"] +
            (isAbleToSave ? "" : ` ${s["comment__icon--disabled"]}`)
          }
          src={isAbleToSave ? iconSave : iconSaveDisabled}
          alt="Save notes"
          onClick={handleClickSave}
        />
      </div>
    </div>
  );
};

export default AddComment;
