import s from "./index.module.scss";

const AdditionalAttributes = ({ attributes = {} }) => {
  const keys = Object.keys(attributes || {});

  if (!keys.length) {
    return <></>;
  }

  return (
    <div className={s["additional-attributes"]}>
      {keys.map((key) => (
        <div className={s["additional-attributes__row"]} key={key}>
          <p className={s["additional-attributes__text"]}>{key}</p>
          <p
            className={
              s["additional-attributes__text"] +
              " " +
              s["additional-attributes__text--bold"]
            }
          >
            {attributes[key]}
          </p>
        </div>
      ))}
    </div>
  );
};

export default AdditionalAttributes;
