import serviceApi from "services/api";
import serviceAuth from "services/auth";
import store from "store";
import {
  setFirstName,
  setLastName,
  setId,
  setAvatar,
  setTwilioPhoneNumber,
  setZipCode,
  setRole,
  setEmployeeId,
} from "slicers/user";
import { setIsLoading, setUsers, setPageCount } from "slicers/users";
import { setCustomers, setPageCountCustomer } from "slicers/customers";

const baseURL = "users";

const getMe = async () =>
  serviceApi.get(baseURL + "/me").then((user) => {
    store.dispatch(setFirstName(user.profile.first_name));
    store.dispatch(setLastName(user.profile.last_name));
    store.dispatch(setId(user.id));
    store.dispatch(setAvatar(user.profile.avatar));
    store.dispatch(
      setTwilioPhoneNumber(user.profile?.twilio_phone_number || "")
    );
    store.dispatch(setZipCode(user.profile.zip_code || ""));
    store.dispatch(setRole(user.role));
    store.dispatch(setEmployeeId(user.profile?.employee_id));
    return user;
  });

const getEmployeesPage = async () => {
  const { getState, dispatch } = store;
  const state = getState().users;

  dispatch(setIsLoading(true));
  return serviceApi
    .get(baseURL, {
      page: state.page,
      page_size: state.pageSize,
      role: "employee",
    })
    .then(({ items, page_count: pageCount }) => {
      dispatch(setUsers(items));
      dispatch(setPageCount(pageCount));
    })
    .catch(() => dispatch(setUsers([])))
    .finally(() => dispatch(setIsLoading(false)));
};

const getCustomersPage = async () => {
  const { getState, dispatch } = store;
  const state = getState().customers;

  dispatch(setIsLoading(true));
  return serviceApi
    .get(baseURL, {
      page: state.pageCustomer,
      page_size: state.pageSize,
      role: "customer",
    })
    .then(({ items, page_count: pageCountCustomer }) => {
      dispatch(setCustomers(items));
      dispatch(setPageCountCustomer(pageCountCustomer));
    })
    .catch(() => dispatch(setCustomers([])))
    .finally(() => dispatch(setIsLoading(false)));
};

const createOrUpdateEmployee = async (
  firstName,
  lastName,
  email,
  twilioPhoneNumber,
  password,
  avatar,
  userId
) => {
  const profile = {
    first_name: firstName,
    last_name: lastName,
    twilio_phone_number: twilioPhoneNumber ? twilioPhoneNumber : null,
  };

  if (avatar && avatar instanceof File) {
    avatar = await uploadFile(avatar);
  }
  if (avatar) {
    profile["avatar"] = avatar;
  }

  const payload = {
    email,
    profile,
  };

  if (password) {
    let passwordKey = "password";
    if (userId) {
      passwordKey = "new_password";
    }
    payload[passwordKey] = password;
  }

  if (userId) {
    return await serviceApi
      .put(baseURL + `/${userId}`, payload)
      .then(getEmployeesPage);
  } else {
    return await serviceApi
      .post(baseURL + "/employees", payload)
      .then(getEmployeesPage);
  }
};

const signUp = async (
  email,
  password,
  firstName,
  lastName,
  phoneNumber,
  zipCode
) => {
  const profile = {
    first_name: firstName,
    last_name: lastName,
    phone_number: phoneNumber,
    zip_code: zipCode,
  };
  return serviceApi
    .post(baseURL + "/signup", { email, password, profile })
    .then(() => serviceAuth.login(email, password));
};

const updateCustomer = async (
  email,
  new_password,
  old_password,
  firstName,
  lastName,
  zipcode,
  avatar,
  userId,
  phoneNumber = "",
  salesRepId = ""
) => {
  const profile = {
    first_name: firstName,
    last_name: lastName,
    zip_code: zipcode,
  };

  if (avatar && avatar instanceof File) {
    avatar = await uploadFile(avatar);
  }
  if (avatar) {
    profile["avatar"] = avatar;
  }
  if (phoneNumber) {
    profile["phone_number"] = phoneNumber;
  }
  if (salesRepId) {
    profile["employee_id"] = salesRepId;
  }

  const result = await serviceApi.put(baseURL + `/${userId}`, {
    email,
    new_password,
    old_password,
    profile,
  });
  return result;
};

const uploadFile = async (file) => {
  const formData = new FormData();
  formData.append("file", file);
  return serviceApi.upload(baseURL + "/files", formData);
};

const deleteSalesRep = (userId) =>
  serviceApi.delete(baseURL + `/${userId}`).then(getEmployeesPage);

const deleteCustomer = (userId) =>
  serviceApi.delete(baseURL + `/${userId}`).then(getCustomersPage);

const getSearch = async (query, role, page, pageSize) =>
  serviceApi.get(
    baseURL + `/search?query=${query}&role=${role}&page_size=${pageSize}`
  );

const getAssignedCustomers = (userId) =>
  serviceApi.get(`${baseURL}/${userId}/assigned_customers`);

const getAssignedCustomerDetails = (userId, customerId) =>
  serviceApi.get(`${baseURL}/${userId}/assigned_customers/${customerId}`);

const unassignCustomer = (userId) =>
  serviceApi.post(`${baseURL}/${userId}/unassign_employee`);

const getUnassignedCustomersPage = (page, pageSize) =>
  serviceApi.get(`${baseURL}/unassigned_customers`, {
    page,
    page_size: pageSize,
  });

const assignCustomerToMe = (userId) =>
  serviceApi.post(`${baseURL}/${userId}/assign_employee`, {
    employee_id: serviceAuth.getUserId(),
  });

const service = {
  getMe,
  getEmployeesPage,
  getCustomersPage,
  createOrUpdateEmployee,
  updateCustomer,
  uploadFile,
  deleteSalesRep,
  deleteCustomer,
  signUp,
  getSearch,
  getAssignedCustomers,
  getAssignedCustomerDetails,
  unassignCustomer,
  getUnassignedCustomersPage,
  assignCustomerToMe,
};

export default service;
