import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "components/Loader";
import iconUnfavorite from "assets/icons/vehicle-unfavorite.svg";
import iconFavorite from "assets/icons/vehicle-favorite.svg";
import serviceWatchLists from "services/watch-lists";
import s from "./index.module.scss";

const VehicleFavorite = () => {
  const [watchListId, setWatchListId] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { id } = useParams();

  async function getWatchListAndSetDefaultValue() {
    setIsLoading(true);
    try {
      const watchLists = await serviceWatchLists.searchMe("");
      const watchListId = watchLists[0].id;

      const vehiclesInWatchList = await serviceWatchLists.vehicles(watchListId);

      const isExist = !!vehiclesInWatchList.find((item) => item.id === id);
      setIsActive(isExist);
      setWatchListId(watchListId);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getWatchListAndSetDefaultValue();
  }, []);

  const handleAddOrRemoveVehicle = () => {
    isActive ? handleUnFavorite() : handleFavorite();
  };

  const handleFavorite = async () => {
    setIsLoading(true);
    serviceWatchLists
      .addVehicleToWatchlist(watchListId, id)
      .then(() => {
        toast.success("This vehicle was added to your watchlist", {
          position: "bottom-right",
          autoClose: 2000,
          closeOnClick: true,
          pauseOnHover: true,
        });
        setIsActive(true);
      })
      .catch(() => {
        toast.error(
          "Your watchlist is full. Remove a vehicle to add a new one.",
          {
            position: "bottom-right",
            autoClose: 2000,
            closeOnClick: true,
            pauseOnHover: true,
          }
        );
      })
      .finally(() => setIsLoading(false));
  };

  const handleUnFavorite = async () => {
    setIsLoading(true);
    serviceWatchLists
      .deleteVehicle(watchListId, id)
      .then(() => {
        toast.success("This vehicle was removed from your watchlist", {
          position: "bottom-right",
          autoClose: 2000,
          closeOnClick: true,
          pauseOnHover: true,
        });
      })
      .finally(() => {
        setIsActive(false);
        setIsLoading(false);
      });
  };

  return (
    <>
      <img
        className={s["vehicle-favorite__icon"]}
        src={isActive ? iconFavorite : iconUnfavorite}
        alt="favorite icon"
        onClick={() => handleAddOrRemoveVehicle()}
      />
      <Loader isOpen={isLoading} />
    </>
  );
};

export default VehicleFavorite;
