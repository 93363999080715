import { useState } from "react";
import { Link } from "react-router-dom";
import Avatar from "components/Avatar";
import utilFormatter from "utils/formatter";
import s from "./index.module.scss";

const AssignedCustomerCard = ({
  assignedCustomer: {
    avatar = null,
    event_at: lastAction = "",
    vehicles_count: vehiclesCount = 0,
    first_name: firstName = "",
    last_name: lastName = "",
    id: customerId = "",
    watchlist_thumbnail: thumbnail = null,
  } = {},
}) => {
  const [isErrorImageLoading, setIsErrorImageLoading] = useState(false);

  const handleErrorThumbnail = () => setIsErrorImageLoading(true);

  return (
    <Link
      className={s["assigned-customer-card"]}
      to={`/employee/customers/my/${customerId}`}
    >
      {thumbnail?.link && !isErrorImageLoading ? (
        <img
          className={s["assigned-customer-card__thumbnail"]}
          src={thumbnail?.link || ""}
          alt="Customer watchlist thumbnail"
          onError={handleErrorThumbnail}
        />
      ) : (
        <div
          className={s["assigned-customer-card__thumbnail-placeholder"]}
        ></div>
      )}
      <div className={s["assigned-customer-card__wrapper"]}>
        <div className={s["assigned-customer-card__info"]}>
          <Avatar
            className={s["assigned-customer-card__avatar"]}
            avatar={avatar}
          />
          <div className={s["assigned-customer-card__text"]}>
            {firstName + " " + lastName}
          </div>
          <p
            className={
              s["assigned-customer-card__text"] +
              " " +
              s["assigned-customer-card__text--green"]
            }
          >
            Vehicles: {vehiclesCount}&nbsp;
            <span
              className={
                s["assigned-customer-card__text"] +
                " " +
                s["assigned-customer-card__text--date"]
              }
            >
              &nbsp;({utilFormatter.prettyDate(lastAction)})
            </span>
          </p>
        </div>
      </div>
    </Link>
  );
};

export default AssignedCustomerCard;
