import { useState, useEffect } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useScrollToTop } from "hooks/scroll-to-top-hook";
import OtherConversation from "components/OtherConversation";
import Loader from "components/Loader";
import OtherConversationModal from "components/modals/OtherConversationModal";
import iconAutoAgents from "assets/images/logo-gray.svg";
import iconScrollToTop from "assets/icons/scroll-to-top.svg";
import serviceVehicles from "services/vehicles";
import utilConstants from "utils/constants";
import s from "./index.module.scss";

const AllConvos = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [vehicle, setVehicle] = useState({});
  const [conversations, setConversations] = useState([]);

  const { id } = useParams();
  const location = useLocation();
  const history = useHistory();

  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get("user_id");

  const { isOpenScrollToTop, handleClickScrollToTop } = useScrollToTop();

  const handleCloseConversation = () => {
    queryParams.delete("user_id");
    history.replace({ search: queryParams.toString() });
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      const responseVehicle = await serviceVehicles.get(id);
      setVehicle(responseVehicle);

      const responseConversations = await serviceVehicles.getConversations(id);

      const formatConversation = (conversation) => {
        const sourceImgData =
          utilConstants.sources[conversation.vehicle.source_name]?.imgData ||
          {};

        const userImgData = {};
        if (conversation.user.avatar) {
          userImgData.src = conversation.user.avatar.link;
        }

        return {
          id: conversation.id,
          user: conversation.user,
          lastMessageText: conversation.text,
          media: conversation.media ? conversation.media : [],
          createdAt: conversation.created_at,
          imgData: conversation.sender === "user" ? userImgData : sourceImgData,
          isUserSender: conversation.sender === "user",
        };
      };

      setConversations(responseConversations.map(formatConversation));

      setIsLoading(false);
    };

    fetchData();
  }, []);

  const {
    build: { year = "", make = "", model = "", trim = "" } = {},
    source: vehicleSource = "",
  } = vehicle;

  if (isLoading) {
    return <Loader isOpen />;
  }

  return (
    <div className={s["all-convos"]}>
      <div className={s["all-convos__title"]}>
        {year} {make} {model} {trim}
      </div>
      {conversations.length ? (
        <div className={s["all-convos__table"]}>
          <div className={s["all-convos__labels"]}>
            <div className={s["all-convos__label"]}>Sales Rep</div>
            <div className={s["all-convos__label"]}>Last Message</div>
            <div
              className={
                s["all-convos__label"] + " " + s["all-convos__label--date"]
              }
            >
              Date
            </div>
          </div>
          {conversations.map((conversation) => (
            <OtherConversation
              conversation={conversation}
              key={conversation.id}
            />
          ))}
        </div>
      ) : (
        <>
          <div
            className={
              s["all-convos__title"] + " " + s["all-convos__title--empty"]
            }
          >
            There are no convos for this vehicle yet
          </div>
          <img className={s["all-convos__img"]} src={iconAutoAgents} alt="" />
        </>
      )}

      <img
        className={
          s["all-convos__scroll-to-top-arrow"] +
          (isOpenScrollToTop
            ? ` ${s["all-convos__scroll-to-top-arrow--open"]}`
            : "")
        }
        alt="Scroll to top"
        src={iconScrollToTop}
        onClick={handleClickScrollToTop}
      />

      <OtherConversationModal
        isOpen={!!userId}
        onClose={handleCloseConversation}
        vehicleId={id}
        vehicleSource={vehicleSource}
        userId={userId}
      />
    </div>
  );
};

export default AllConvos;
