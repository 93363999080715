import { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import WatchListsDetailsTitle from "components/WatchListsDetailsTitle";
import VehicleGrid from "components/VehicleGrid";
import WatchListDetailsVehicleCard from "components/VehicleCard/employee/WatchListDetails";
import Loader from "components/Loader";
import DeleteVehicleFromWatchListModal from "components/modals/DeleteVehicleFromWatchListModal";
import ConfirmModal from "components/modals/ConfirmModal";
import serviceWatchLists from "services/watch-lists";
import serviceAutoConvos from "services/autoConvos";
import toaster from "utils/toaster";
import s from "./index.module.scss";

const WatchListsDetails = () => {
  const [vehicles, setVehicles] = useState([]);
  const [vehicleIdToRemove, setVehicleIdToRemove] = useState("");
  const [isOpenStartAutoConvosModal, setIsOpenStartAutoConvosModal] =
    useState(false);
  const [isOpenStopAutoConvosModal, setIsOpenStopAutoConvosModal] =
    useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const userTwilioPhoneNumber = useSelector(
    (state) => state.user.twilioPhoneNumber
  );

  const vehicleToRemove = vehicleIdToRemove
    ? vehicles.filter((vehicle) => vehicle.id === vehicleIdToRemove)[0]
    : {};

  const { watchListId } = useParams();

  const vehiclesToStartAutoConvoCount = useMemo(() => {
    if (vehicles.length === 0) return 0;

    const filteredVehicles = vehicles.filter(
      (vehicle) =>
        !vehicle.isAutoConvo &&
        vehicle.phoneNumber &&
        vehicle.type !== "powersports"
    );

    return filteredVehicles.length;
  }, [vehicles]);

  const vehiclesWithStartedAutoConvoCount = useMemo(() => {
    if (vehicles.length === 0) return 0;

    const filteredVehicles = vehicles.filter((vehicle) => vehicle.isAutoConvo);

    return filteredVehicles.length;
  }, [vehicles]);

  const vehiclesWithStoppedAutoConvoCount = useMemo(() => {
    if (vehicles.length === 0) return 0;

    return vehicles.length - vehiclesWithStartedAutoConvoCount;
  }, [vehicles, vehiclesWithStartedAutoConvoCount]);

  const isStartAutoConvosButtonDisabled =
    isLoading || vehiclesWithStartedAutoConvoCount === vehicles.length;

  const isStopAutoConvosButtonsDisabled =
    isLoading || vehiclesWithStoppedAutoConvoCount === vehicles.length;

  const isAbleToManageAutoConvos = vehicles.length > 0 && userTwilioPhoneNumber;

  useEffect(() => {
    serviceWatchLists
      .vehicles(watchListId)
      .then((data) =>
        setVehicles(
          data.map((vehicle) => ({
            to: `/employee/watch-lists/${watchListId}/vehicles/${vehicle.id}`,
            id: vehicle.id,
            thumbnail: vehicle.thumbnail,
            year: vehicle.build.year,
            make: vehicle.build.make,
            model: vehicle.build.model,
            trim: vehicle.build.trim,
            mileage: vehicle.mileage,
            price: vehicle.price,
            delisted: vehicle.delisted,
            source: vehicle.source,
            reaction: vehicle.reaction,
            phoneNumber: vehicle?.dealer?.["phone_number"]
              ? vehicle.dealer["phone_number"]
              : null,
            type: vehicle.type,
            isAutoConvo: vehicle.is_autoconvo,
            onRemove: () => handleRemoveVehicle(vehicle.id),
          }))
        )
      )
      .finally(() => setIsLoading(false));
  }, [watchListId]);

  const handleRemoveVehicle = (vehicleId) => setVehicleIdToRemove(vehicleId);

  const handleCloseModal = (isDeleted = false) => {
    if (isDeleted) {
      setVehicles((vehicles) =>
        vehicles.filter((vehicle) => vehicle.id !== vehicleToRemove.id)
      );
    }
    setVehicleIdToRemove("");
  };

  const handleStartAutoConvos = () => {
    if (vehiclesToStartAutoConvoCount === 0) return Promise.resolve();
    const vehicleIds = vehicles.map((vehicle) => vehicle.id);
    setIsLoading(true);
    return serviceAutoConvos
      .bulkStart(vehicleIds)
      .then((vehicleIds) =>
        setVehicles((vehicles) =>
          vehicles.map((vehicle) => {
            if (vehicleIds.includes(vehicle.id)) {
              return {
                ...vehicle,
                isAutoConvo: true,
              };
            }
            return vehicle;
          })
        )
      )
      .catch(() =>
        toaster.displayErrorBottom(
          "Failed to start auto convos, please try again."
        )
      )
      .finally(() => setIsLoading(false));
  };

  const handleStopAutoConvos = () => {
    if (vehiclesWithStartedAutoConvoCount === 0) return Promise.resolve();
    const vehicleIds = vehicles.map((vehicle) => vehicle.id);
    setIsLoading(true);
    return serviceAutoConvos
      .bulkStop(vehicleIds)
      .then((vehicleIds) =>
        setVehicles((vehicles) =>
          vehicles.map((vehicle) => {
            if (vehicleIds.includes(vehicle.id)) {
              return {
                ...vehicle,
                isAutoConvo: false,
              };
            }
            return vehicle;
          })
        )
      )
      .catch(() =>
        toaster.displayErrorBottom(
          "Failed to stop auto convos, please try again."
        )
      )
      .finally(() => setIsLoading(false));
  };

  const handleCloseStartAutoConvosModal = () => {
    setIsOpenStartAutoConvosModal(false);
  };

  const handleCloseStopAutoConvosModal = () =>
    setIsOpenStopAutoConvosModal(false);

  const handleClickStartAutoConvos = () => setIsOpenStartAutoConvosModal(true);

  const handleClickStopAutoConvos = () => setIsOpenStopAutoConvosModal(true);

  return (
    <div className={s["watch-lists-details"]}>
      <WatchListsDetailsTitle
        isAbleToManageWatchList
        isAbleToManageAutoConvos={isAbleToManageAutoConvos}
        onClickStartAutoConvos={handleClickStartAutoConvos}
        onClickStopAutoConvos={handleClickStopAutoConvos}
        isStartAutoConvosButtonDisabled={isStartAutoConvosButtonDisabled}
        isStopAutoConvosButtonsDisabled={isStopAutoConvosButtonsDisabled}
      />
      <VehicleGrid
        vehicles={vehicles}
        cardComponent={WatchListDetailsVehicleCard}
      />
      <Loader isOpen={isLoading} />
      <DeleteVehicleFromWatchListModal
        isOpen={!!vehicleIdToRemove}
        onClose={handleCloseModal}
        text={`${vehicleToRemove?.make ? vehicleToRemove.make : ""} ${
          vehicleToRemove?.model ? ` ${vehicleToRemove.model}` : ""
        } ${vehicleToRemove?.trim ? ` ${vehicleToRemove.trim}` : ""}`}
        watchListId={watchListId}
        vehicleId={vehicleIdToRemove}
      />
      <ConfirmModal
        text={
          <>
            Are you sure you want to start automatic convos <br />
            for <b>{vehiclesToStartAutoConvoCount}</b> vehicles?
          </>
        }
        isOpen={isOpenStartAutoConvosModal}
        onClose={handleCloseStartAutoConvosModal}
        onConfirm={handleStartAutoConvos}
      />
      <ConfirmModal
        text={
          <>
            Are you sure you want to stop automatic convos <br />
            for <b>{vehiclesWithStartedAutoConvoCount}</b> vehicles?
          </>
        }
        isOpen={isOpenStopAutoConvosModal}
        onClose={handleCloseStopAutoConvosModal}
        onConfirm={handleStopAutoConvos}
      />
    </div>
  );
};

export default WatchListsDetails;
