import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import AssignedCustomerProfile from "components/AssignedCustomerProfile";
import VehicleCard from "components/VehicleCard";
import Loader from "components/Loader";
import serviceUsers from "services/users";
import iconAutoAgents from "assets/images/logo-gray.svg";
import vehicleRepository from "repositories/vehicleRepository";
import s from "./index.module.scss";

const MyCustomerWatchList = ({ baseUrl }) => {
  const userId = useSelector((state) => state.user.id);

  const { customerId } = useParams();

  const [customerDetails, setCustomerDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setCustomerDetails({});
    serviceUsers
      .getAssignedCustomerDetails(userId, customerId)
      .then((data) => setCustomerDetails(data))
      .finally(() => setIsLoading(false));
  }, [userId, customerId]);

  return (
    <div className={s["assigned-customer-watch-list"]}>
      {isLoading ? (
        <></>
      ) : (
        <>
          <AssignedCustomerProfile customerDetails={customerDetails} />
          {customerDetails.vehicles ? (
            <div className={s["assigned-customer-watch-list__grid"]}>
              {customerDetails.vehicles.map((vehicle) => (
                <VehicleCard
                  vehicle={{ ...vehicle, to: `${baseUrl}${vehicle.id}` }}
                  key={vehicle.id}
                  vehicleRepository={vehicleRepository}
                />
              ))}
            </div>
          ) : (
            <div className={s["assigned-customer-watch-list__stub"]}>
              <div className={s["assigned-customer-watch-list__title"]}>
                There are no any vehicles
                <br /> in this customer’s watchlist yet
              </div>
              <img
                className={s["assigned-customer-watch-list__icon-autoagents"]}
                src={iconAutoAgents}
                alt="Autoagents logo"
              />
            </div>
          )}
        </>
      )}

      <Loader isOpen={isLoading} />
    </div>
  );
};

export default MyCustomerWatchList;
