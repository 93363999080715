import { Route, Redirect, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import ProtectedRoute from "components/routes/ProtectedRoute";
import CustomerHeader from "components/headers/CustomerHeader";
import CustomerProfile from "pages/customer/profile";
import Vehicles from "pages/customer/vehicles";
import VehicleDetails from "pages/customer/vehicles/details";
import WatchList from "pages/customer/watch-list";
import s from "./index.module.scss";

const Customer = () => {
  const employeeId = useSelector((state) => state.user.employeeId);

  const protectedRouteProps = {
    rule: () => !!employeeId,
    redirectTo: "/customer/protected",
  };

  return (
    <div className={s["customer"]}>
      <CustomerHeader isHiddenProtected={!protectedRouteProps.rule()} />
      <div className={s["customer__page-wrapper"]}>
        <Switch>
          <Route
            path="/customer"
            render={() => <Redirect to="/customer/vehicles" />}
            exact
          />
          <ProtectedRoute
            path="/customer/vehicles"
            render={() => <Vehicles />}
            exact
            {...protectedRouteProps}
          />
          <Route
            path="/customer/profile"
            render={() => <CustomerProfile />}
            exact
          />
          <ProtectedRoute
            path="/customer/vehicles/:id"
            render={() => <VehicleDetails />}
            exact
            {...protectedRouteProps}
          />
          <ProtectedRoute
            path="/customer/watch-list"
            render={() => <WatchList />}
            exact
            {...protectedRouteProps}
          />
          <Route
            path="/customer/protected"
            render={() => <Redirect to="/customer/profile" />}
            exact
          />
        </Switch>
      </div>
    </div>
  );
};

export default Customer;
