import { toast } from "react-toastify";
import Modal from "components/Modal";
import Avatar from "components/Avatar";
import iconCrossBlackBold from "assets/icons/cross-black-bold.svg";
import iconCopy from "assets/icons/copy.svg";
import "react-toastify/dist/ReactToastify.css";
import s from "./index.module.scss";

const UserDetailsModal = ({
  isOpen = false,
  onClose = () => {},
  user: {
    profile: {
      first_name: firstName = "",
      last_name: lastName = "",
      twilio_phone_number: twilioPhoneNumber = null,
      avatar = null,
    } = {},
    email = "",
    id = "",
  } = {},
}) => {
  const referalLink = `${process.env.REACT_APP_EMPLOYEE_REFERAL_LINK}?referralId=${id}`;

  const handleClose = () => onClose();

  const handleClickCopyLink = () =>
    navigator.clipboard.writeText(referalLink).then(() =>
      toast.success("Copied to clipboard", {
        position: "top-right",
        autoClose: 2000,
        closeOnClick: true,
        pauseOnHover: true,
      })
    );
  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <div className={s["user-details-modal"]}>
        <div className={s["user-details-modal__title-wrapper"]}>
          <p className={s["user-details-modal__title"]}>Sales Rep's Details</p>
          <img
            className={s["user-details-modal__cross-icon"]}
            src={iconCrossBlackBold}
            alt="Close"
            onClick={handleClose}
          />
        </div>
        <div className={s["user-details-modal__form-wrapper"]}>
          <div className={s["user-details-modal__form-avatar"]}>
            <Avatar avatar={avatar} />
          </div>
          <p className={s["user-details-modal__form-text"]}>{firstName}</p>
          <p className={s["user-details-modal__form-text"]}>{lastName}</p>
          <p className={s["user-details-modal__form-text"]}>{email}</p>
          {twilioPhoneNumber ? (
            <p className={s["user-details-modal__form-text"]}>
              {twilioPhoneNumber}
            </p>
          ) : (
            <></>
          )}
          <div className={s["user-details-modal__form-link"]}>
            <p
              className={
                s["user-details-modal__form-text"] +
                " " +
                s["user-details-modal__form-text--title-link"]
              }
            >
              Price Evaluation Tool Referral Link:
            </p>
            <p
              className={
                s["user-details-modal__form-text"] +
                " " +
                s["user-details-modal__form-text--link"]
              }
            >
              {referalLink}
            </p>
            <img
              className={s["user-details-modal__copy-icon"]}
              src={iconCopy}
              alt="Copy"
              onClick={handleClickCopyLink}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UserDetailsModal;
