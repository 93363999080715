import { useState, useEffect } from "react";

import DeleteModal from "components/modals/DeleteModal";
import SaveSearchModal from "components/modals/SaveSearchModal";

import iconTrash from "assets/icons/trash-blue.svg";
import iconRename from "assets/icons/pen.svg";

import s from "./index.module.scss";

const SavedSearchDetailsTitle = ({
  onRemoveSavedSearch = () => {},
  onUpdateTitle = () => {},
  defaultTitle = "",
}) => {
  const [localTitle, setLocalTitle] = useState(defaultTitle);
  const [isOpenModalRemove, setIsOpenModalRemove] = useState(false);
  const [isOpenModalRename, setIsOpenModalRename] = useState(false);

  const handleCloseModalRemove = () => {
    setIsOpenModalRemove(false);
  };

  const handleConfirmModalRemove = () => {
    setIsOpenModalRemove(false);
    return onRemoveSavedSearch();
  };

  const handleCloseModalSaveSearch = () => {
    setIsOpenModalRename(false);
  };

  const handleDoneModalSaveSearch = (newTitle) => {
    setIsOpenModalRename(false);
    return onUpdateTitle(newTitle);
  };

  useEffect(() => {
    setLocalTitle(defaultTitle);
  }, [defaultTitle]);

  return (
    <div className={s["saved-search-details"]}>
      <div className={s["saved-search-details__text-wrapper"]}>
        <span className={s["saved-search-details__text"]}>
          Saved search
          <span className={s["saved-search-details__text--bold"]}>
            {` ${localTitle}`}
          </span>
        </span>

        <div className={s["saved-search-details__icons"]}>
          <img
            className={s["saved-search-details__rename-icon"]}
            src={iconRename}
            alt="Rename"
            onClick={() => setIsOpenModalRename(true)}
          />
          <img
            className={s["saved-search-details__trash-icon"]}
            src={iconTrash}
            alt="Remove"
            onClick={() => setIsOpenModalRemove(true)}
          />
        </div>
      </div>
      <SaveSearchModal
        isOpen={isOpenModalRename}
        onClose={handleCloseModalSaveSearch}
        onDone={handleDoneModalSaveSearch}
        title={localTitle}
      />
      <DeleteModal
        isOpen={isOpenModalRemove}
        onClose={handleCloseModalRemove}
        onConfirm={handleConfirmModalRemove}
      >
        <strong>{localTitle}</strong>
        <br />
        saved search
      </DeleteModal>
    </div>
  );
};

export default SavedSearchDetailsTitle;
