import logo from "assets/images/logo-autoagents-white.svg";

import s from "./index.module.scss";

const BaseFooter = () => {
  return (
    <>
      <footer className={s["footer"]}>
        <section className={s["footer__wrapper"]}>
          <section className={s["footer__info-wrapper"]}>
            <section className={s["footer__logo-wrapper"]}>
              <img src={logo} alt="AutoAgents Logo" />
              <label>Start to finish car buying experience</label>
            </section>
            <section className={s["footer__contacts-wrapper"]}>
              <a href="tel:+16139093884">(613) 909-3884</a>
              <a href="mailto:info@autoagents.io">info@autoagents.io</a>
              <label>Ottawa, ON, Canada</label>
            </section>
          </section>
          <section className={s["footer__useful-links-wrapper"]}>
            <section className={s["footer__links-title"]}>
              <label>Useful Links:</label>
            </section>
            <section className={s["footer__links-wrapper"]}>
              <nav>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.autoagents.io/vehicle-consultation"
                >
                  Get Started
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.autoagents.io/how-it-works"
                >
                  How it Works
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.autoagents.io/sell-your-car"
                >
                  Sell Your Car
                </a>
                <a 
                  target="_blank"
                  rel="noreferrer"
                  href="https://cars.autoagents.io/vehicles/">
                  Current Inventory
                </a>
              </nav>
              <nav>
                <a 
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.autoagents.io/faqs">
                  FAQs
                </a>
                <a 
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.autoagents.io/about">
                  About Us
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.autoagents.io/terms-conditions"
                >
                  Terms + Conditions
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.autoagents.io/privacy-policy"
                >
                  Privacy Policy
                </a>
              </nav>
            </section>
          </section>
        </section>
      </footer>
    </>
  );
};

export default BaseFooter;
