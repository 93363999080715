import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { useScrollToTop } from "hooks/scroll-to-top-hook";

import VehicleGrid from "components/VehicleGrid";
import VehicleListTable from "components/Tables/VehicleListTable";
import VehiclesNotFoundPlaceholder from "components/VehiclesNotFoundPlaceholder";
import FilledButton from "components/buttons/FilledButton";
import Loader from "components/Loader";

import s from "./index.module.scss";

const VehiclePager = ({
  vehicleService,
  vehicleRepository,
  baseUrl,
  cardComponent = null,
  tableComponent = null,
  rowComponent = null,
}) => {
  const vehicles = useSelector((state) => vehicleRepository.getVehicles(state));
  const page = useSelector((state) => vehicleRepository.getPageNumber(state));
  const isLoading = useSelector((state) =>
    vehicleRepository.getLoadingState(state)
  );
  const isGridView = useSelector((state) =>
    vehicleRepository.getIsGridView(state)
  );
  const pageCount = useSelector((state) =>
    vehicleRepository.getPageCount(state)
  );
  const zipCode = useSelector((state) => vehicleRepository.getZipCode(state));

  const TableComponent = tableComponent || VehicleListTable;

  const isLastPage = pageCount === 0 || page === pageCount;
  const isDisabledLoadMoreButton = isLoading || isLastPage;
  const isNothingWasFound = !isLoading && vehicles.length === 0;
  const isLoadMoreButtonVisible = !isLastPage && !isNothingWasFound;

  const { isOpenScrollToTop, handleClickScrollToTop } = useScrollToTop();
  const location = useLocation();

  const vehiclesToShow = vehicles.map((vehicle) => ({
    ...vehicle,
    to: `${baseUrl}${vehicle.id}${zipCode ? "?postal_code=" + zipCode : ""}`,
  }));

  useEffect(() => {
    if (page === 0) {
      vehicleRepository.updatePageNumber(1);
      vehicleService.getVehiclesPage().catch(() => {});
    }
  }, [page]);

  useEffect(() => {
    if (page === 1) {
      handleClickScrollToTop();
    }
  }, [page]);

  useEffect(() => {
    if (location.state?.refreshRequired) {
      vehicleRepository.resetState();
      vehicleService.getVehiclesPage().catch(() => {});
    }
  }, [location]);

  const handleClickLoadMore = () => {
    if (isDisabledLoadMoreButton) {
      return;
    }
    vehicleRepository.updatePageNumber(page + 1);
    vehicleRepository.updateLastAccessedVehicleId("");

    vehicleService.getVehiclesPage().catch(() => {});
  };

  return (
    <div className={s["vehicles-grid__wrapper"]}>
      <div className={s["vehicles-grid__page"]}>
        {isGridView ? (
          <VehicleGrid
            vehicles={vehiclesToShow}
            cardComponent={cardComponent}
            vehicleRepository={vehicleRepository}
          />
        ) : (
          <TableComponent
            vehicles={vehiclesToShow}
            rowComponent={rowComponent}
            vehicleRepository={vehicleRepository}
          />
        )}
        {isLoadMoreButtonVisible && (
          <FilledButton
            className={s["vehicles-grid__load-more-button"]}
            disabled={isDisabledLoadMoreButton}
            onClick={handleClickLoadMore}
          >
            Load more
          </FilledButton>
        )}
      </div>
      {isNothingWasFound && <VehiclesNotFoundPlaceholder />}
      <Loader isOpen={isLoading} />
      <div
        className={
          s["vehicles-grid__scroll-to-top-arrow"] +
          (isOpenScrollToTop
            ? ` ${s["vehicles-grid__scroll-to-top-arrow--open"]}`
            : "")
        }
        onClick={handleClickScrollToTop}
      ></div>
    </div>
  );
};

export default VehiclePager;
