import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    firstName: "",
    lastName: "",
    id: "",
    avatar: "",
    twilioPhoneNumber: "",
    zipCode: "",
    role: "",
    employeeId: "",
  },
  reducers: {
    resetUserState: (state) => {
      state.firstName = "";
      state.id = "";
      state.avatar = "";
      state.twilioPhoneNumber = "";
      state.zipCode = "";
      state.role = "";
      state.employeeId = "";
    },
    setFirstName: (state, action) => {
      state.firstName = action.payload;
    },
    setLastName: (state, action) => {
      state.lastName = action.payload;
    },
    setId: (state, action) => {
      state.id = action.payload;
    },
    setAvatar: (state, action) => {
      state.avatar = action.payload;
    },
    setTwilioPhoneNumber: (state, action) => {
      state.twilioPhoneNumber = action.payload;
    },
    setZipCode: (state, action) => {
      state.zipCode = action.payload;
    },
    setRole: (state, action) => {
      state.role = action.payload;
    },
    setEmployeeId: (state, action) => {
      state.employeeId = action.payload;
    },
  },
});

export const {
  resetUserState,
  setFirstName,
  setLastName,
  setId,
  setAvatar,
  setTwilioPhoneNumber,
  setZipCode,
  setRole,
  setEmployeeId,
} = userSlice.actions;

export default userSlice.reducer;
