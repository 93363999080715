import s from "./index.module.scss";

const UnfilledButton = ({
  children,
  className = "",
  disabled = false,
  onClick = () => {},
}) => (
  <button
    className={s["button"] + (className ? ` ${className}` : "")}
    disabled={disabled}
    onClick={onClick}
  >
    {children}
  </button>
);

export default UnfilledButton;
