import { useState } from "react";
import Modal from "components/Modal";
import FilledButton from "components/buttons/FilledButton";
import Checkbox from "components/inputs/Checkbox";
import iconCrossBlack from "assets/icons/cross-black.svg";
import s from "./index.module.scss";

const StartSavedSearchAutoConvosModal = ({
  isOpen = false,
  onClose = () => {},
  onConfirm = () => {},
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isForcedStart, setIsForcedStart] = useState(false);

  const handleClose = (isConfirmed = false) => {
    setIsForcedStart(false);
    onClose(isConfirmed);
  };

  const handleClickConfirmButton = () => {
    setIsLoading(true);
    onConfirm(isForcedStart)
      .then(() => handleClose(true))
      .finally(() => setIsLoading(false));
  };

  return (
    <Modal isOpen={isOpen} onClose={() => handleClose(false)}>
      <div className={s["confirm-modal"]}>
        <img
          className={s["confirm-modal__cross"]}
          src={iconCrossBlack}
          alt="Close"
          onClick={() => handleClose(false)}
        />
        <div className={s["confirm-modal__content"]}>
          <p className={s["confirm-modal__text"]}>
            {" "}
            Are you sure you want to{" "}
            <strong>
              {" "}
              turn <br />
              auto convos on{" "}
            </strong>{" "}
            for all new <br />
            vehicles in this saved search?
          </p>
          <div className={s["confirm-modal__checkbox-wrapper"]}>
            <Checkbox
              className={s["confirm-modal__checkbox"]}
              isChecked={isForcedStart}
              onChange={() => setIsForcedStart((prev) => !prev)}
            />
            <p className={s["confirm-modal__checkbox-text"]}>
              Run auto convos for all current vehicles in this saved search
            </p>
          </div>
          <FilledButton
            className={s["confirm-modal__button"]}
            onClick={handleClickConfirmButton}
            disabled={isLoading}
          >
            Yes, I am sure
          </FilledButton>
        </div>
      </div>
    </Modal>
  );
};

export default StartSavedSearchAutoConvosModal;
