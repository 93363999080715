const block = () => {
  document.body.style.overflow = "hidden";
  document.body.style.touchAction = "none";
};

const unblock = () => {
  document.body.style.overflow = "auto";
  document.body.style.touchAction = "auto";
};

const backgroundBlocker = {
  block,
  unblock,
};

export default backgroundBlocker;
